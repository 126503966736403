import { useMsal } from "@azure/msal-react";
import msalFetch from "../../../api/MsalFetch.js";
import React, { useState, useEffect, useCallback, useRef } from "react";
import BloomFooter from "../../footer/BloomFooter";
import appSettings from "../../../appsettings";
import ConfigPageControls from "../parts/ConfigPageControls";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import EditFtpConfiguration from "../../modals/configurator/EditFtpConfiguration"
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";
import { confirm } from 'devextreme/ui/dialog';
import { Popup } from 'devextreme-react/popup';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextBox } from "devextreme-react";
import { Button as ToolbarButton } from 'devextreme-react/button';

import DataGrid, {
    Column,
    Selection,
    Toolbar,
    Button as DataGridButton,
    Item
} from "devextreme-react/data-grid";

const OrganisationSettings = () => {
    const msal = useMsal();
    const [activeTab, setActiveTab] = useState(0);
    const [ftpSettings, setFtpSettings] = useState();
    const [host, setHost] = useState('PR');
    const [changeInForm, setChangeInForm] = useState(false);
    const [formValue, setFormValue] = useState({});
    const [loading, setLoading] = useState(true);
    const [editFtpConfiguration, setEditFtpConfiguration] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [ftpEditRecord, setFtpEditRecord] = useState();
    const [throwNotification, setThrowNotification] = useState(false);
    const [showFtpConnectionTest, setShowFtpConnectionTest] = useState(false);
    const [ftpTestPath, setFtpTestPath] = useState();
    const [selectedFtpConfigurationId, setSelectedFtpConfigurationId] = useState();
    const dataGridFtpRef = useRef();

    const getData = useCallback(async () => {
        msalFetch(null, `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftpSettings/True`)
            .then((response) => { return response.json() })
            .then((data) => {
                setFormValue(data);
                setFtpSettings(data);
                setLoading(false);
                return;
            })
            .catch((e) => {
                setLoading(false);
                throw "[ERROR] FTP settings getData";
            });
    }, []);


    useEffect(() => {
        if (!editFtpConfiguration) {
            getData();
        }
    }, [editFtpConfiguration, getData]);

    const handleFtpTestPathValueChange = useCallback((e) => {
        setFtpTestPath(e.value);
    }, [])

    const onConnectionTestClick = useCallback(async (data) => {
        setSelectedFtpConfigurationId(data.row.data.id);
        setSelectedFtpConfigurationId(data.row.data.id);
        setShowFtpConnectionTest(true);
        setFtpTestPath(data.row.data.lastTestPath);
    }, []);

    const testConnection = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/testConnection/${selectedFtpConfigurationId}`;
        try {
            const res = await msalFetch(null, url, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                //body: JSON.stringify( { path: ftpTestPath}),
                body: JSON.stringify(ftpTestPath),
            });
            const data = await res.json();
            if (res.ok) {
                toast.success(data.testResult);
            }
            else {
                toast.error(data.exceptionMessage);
            }
            setShowFtpConnectionTest(false);
            getData();
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    }, [selectedFtpConfigurationId, ftpTestPath, getData]);

    const handleSaveClick = async () => {
        const url = isCreate ? `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/insert` : `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/update`

        try {
            const res = await msalFetch(msal,
                url,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formValue),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                toast.success("De gegevens zijn successvol opgeslagen...");
                getData();
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }

        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    };



    const handleDeleteClick = useCallback(async (data) => {
        let result = await confirm("Weet u zeker dat u dit item wilt verwijderen?", "Item verwijderen?");
        if (result) {
            const url = `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/delete/${data.row.data.id}`;
            await msalFetch(null, url, {
                method: "DELETE",
                headers: { "Content-type": "application/json" }
            });
            getData();
        }
    }, []);

    const handleEditFtp = useCallback((data) => {
        setIsCreate(false);
        setEditFtpConfiguration(true);
        setFtpEditRecord(data.row.data);
    }, [setEditFtpConfiguration, setFtpEditRecord]);

    const clearForm = () => {
        setFtpEditRecord(null);
    }

    function handleSaveAndContinue() {
        handleSaveClick();
        setThrowNotification(false);
    }

    function handleDiscardAndContinue() {
        setThrowNotification(false);
        setEditFtpConfiguration(false);
    }

    const onAddClick = useCallback(async () => {
        // setIsCreate(true);
        // setEditFtpConfiguration(true);
        // clearForm();
        const params = {
            key: "key",
            exportType: "exportType",
            ReportName: "reportName",
            Id: 11
        };
        const res = await msalFetch(null, `${appSettings.api.endpoint}/api/report/generate`, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            //body: JSON.stringify( { path: ftpTestPath}),
            body: JSON.stringify(params),
        });
    }, []);

    return (
        <main className="bl-content bl-config-menu-page">
            <header className="bl-config-page-header">
                <h2>Bedrijfsinstellingen</h2>
                <ConfigPageControls
                    saveFunction={handleSaveClick}
                    changeInForm={changeInForm}
                    hasExitFunction={true}
                    exitPageFunction={() => { /*history.push("configurator/menu")*/ }}
                />
            </header>
            <header className="bl-tab-control" id="tabTarget">
                <nav className="bl-tab-nav">
                    <div className="bl-tab-wrapper">
                        <ul className="bl-tab-list">
                            <li
                                className={`bl-tab ${activeTab === 0 && "bl-active-tab"}`}
                                onClick={(e) => setActiveTab(0)}
                            >
                                FTP
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <section>
                {activeTab === 0 && (
                    <div>
                        <DataGrid
                            dataSource={ftpSettings}
                            showBorders={true}
                            showRowLines={false}
                            rowAlternationEnabled={true}
                            showColumnLines={false}
                            ref={dataGridFtpRef}
                        >
                            <Toolbar>
                                <Item location="after">
                                    <ToolbarButton
                                        icon="fas fa-plus"
                                        onClick={onAddClick}
                                    />
                                </Item>
                            </Toolbar>
                            <Selection mode="single" />
                            <Column
                                dataField="name"
                                dataType="string"
                                caption="Omschrijving"
                            ></Column>
                            <Column
                                dataField="server"
                                dataType="string"
                                caption="Host"
                            ></Column>
                            <Column
                                dataField="login"
                                dataType="string"
                                caption="Login"
                            ></Column>
                            <Column
                                dataField="port"
                                dataType="string"
                                caption="Poort"
                            ></Column>
                            <Column
                                dataField="lastTestRun"
                                dataType="datetime"
                                caption="Laatste test"
                            ></Column>
                            <Column
                                dataType="string"
                                dataField="lastTestPath"
                                caption="Testpad"
                            ></Column>
                            <Column
                                dataType="boolean"
                                dataField="connectionSucceeded"
                                caption="Connectie"
                            ></Column>
                            <Column
                                dataType="boolean"
                                dataField="credentialsValid"
                                caption="Credentials"
                            ></Column>
                            <Column
                                dataType="boolean"
                                dataField="testPathWriteOk"
                                caption="Testpad schrijven"
                            ></Column>
                            <Column
                                dataType="string"
                                dataField="testResultMessage"
                                caption="Testresultaat"
                            ></Column>
                            <Column type="buttons" width="auto" caption="Acties">
                                <DataGridButton hint="Bewerken" icon="fas fas fa-pen-to-square" onClick={handleEditFtp} />
                                <DataGridButton hint="Verwijder dit record" icon="trash" cssClass="trashButton" onClick={handleDeleteClick} />
                                <DataGridButton hint="Test connectie" icon="fas fa-plug-circle-bolt" onClick={onConnectionTestClick} />
                            </Column>
                        </DataGrid>
                    </div>
                )}
                {editFtpConfiguration && (
                    <EditFtpConfiguration
                        isEditing={editFtpConfiguration}
                        setIsEditing={setEditFtpConfiguration}
                        isCreate={isCreate}
                        data={ftpEditRecord}
                        throwNotification={throwNotification}
                        setThrowNotification={setThrowNotification}
                    />
                )}
                {throwNotification && (
                    <UserLeave
                        handleCancel={() => setThrowNotification(false)}
                        handleSaveAndContinue={handleSaveAndContinue}
                        handleDiscardAndContinue={handleDiscardAndContinue}
                    />
                )}
                <Popup
                    visible={showFtpConnectionTest}
                    onHiding={() => { setShowFtpConnectionTest(false); }}
                    resizeEnabled={true}
                    dragEnabled={true}
                    animation={false}
                    hideOnOutsideClick
                    showCloseButton
                    showTitle
                    title="Test connectie"
                    toolbarItems={
                        [
                            {
                                toolbar: "bottom",
                                widget: "dxButton",
                                location: "center",
                                options:
                                {
                                    text: 'Test',
                                    onClick: (e) => {
                                        //e.cancel = true;
                                        testConnection();
                                    }
                                }
                            }

                        ]
                    }
                >
                    <Form.Group>
                        <Row>
                            <Col md="4">
                                <Form.Label>
                                    Pad voor het wegschrijven van het testbestand
                                </Form.Label>
                            </Col>
                            <Col md="4">
                                <TextBox
                                    placeholder="Pad voor testbestand"
                                    value={ftpTestPath}
                                    showClearButton={true}
                                    onValueChanged={handleFtpTestPathValueChange}
                                    valueChangeEvent="input"
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Popup>


            </section>
            <BloomFooter />
        </main>
    )
};




export default OrganisationSettings
