import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";

import DataGrid, {
    Column,
    Lookup,
    FilterRow,
    HeaderFilter,
    ColumnFixing,
    Grouping,
    GroupPanel,
    Sorting,
    LoadPanel,
    Paging,
    Pager,
    Scrolling,
} from "devextreme-react/data-grid";
import EditSet from "../../../modals/configurator/EditSet";
import DeleteItem from "../../../notifications/userLeaveNotifications/notifications/DeleteItem";
import Loading from "../../../loading/Loading";

const SetGrid = ({ columns }) => {
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id: datasetId, activeSet } = useParams();
    const isCreate = activeSet === '0';
    const [setData, setSetData] = useState([]);
    const singleItem = setData?.find(({ id }) => id === Number(activeSet));
    const [loading, setLoading] = useState(true);
    const [deleteNotification, setDeleteNotification] = useState(false);

    const [tabRelations, setTabRelations] = useState([]);
    const [deleteTarget, setDeleteTarget] = useState();
    const [hover, setHover] = useState(false);

    const setTypeOptions = [
        { type: "Veldenset" },
        { type: "Afbeeldingen" },
        { type: "Gerelateerd" },
        { type: "Tabbladen" },
    ];

    const allowedPageSizes = [10, 20, 50, "all"];

    const setIsEditing = useCallback(() => {
        push('../../');
    }, [push]);

    const pushToSingle = useCallback((id) => {
        push(`${pathname}${pathname.endsWith('/') ? '' : '/'}set/${id}/`);
    }, [push, pathname]);

    const handleAddNewClick = () => {
        pushToSingle(0);
    };

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={handleAddNewClick}
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }

    const handleEditColumn = (columnData) => {
        if (hover) {
            return;
        }
        pushToSingle(columnData.data.id);
    };

    const handleDeleteFunction = async () => {
        let url;
        switch (deleteTarget.fieldType) {
            case "Veldenset": {
                url = `${appSettings.api.endpoint}/api/configurator/dataset/${deleteTarget.tableId}/control/property/${deleteTarget.id}`
                break;
            }
            case "Gerelateerd": {
                url = `${appSettings.api.endpoint}/api/configurator/dataset/${deleteTarget.tableId}/control/relation/${deleteTarget.id}`;
                break;
            }
            case "Afbeeldingen": {
                url = `${appSettings.api.endpoint}/api/configurator/dataset/${deleteTarget.tableId}/control/image/${deleteTarget.id}`;
                break;
            }
            case "Tabbladen": {
                url = `${appSettings.api.endpoint}/api/configurator/dataset/${deleteTarget.tableId}/control/tab/${deleteTarget.id}`;
                break;
            }
        }

        try {
            const res = await msalFetch(null, url, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });

            if (res.ok) {
                toast.success("Het item is successvol verwijderd...");

                setDeleteNotification(false);
                getImages();
            } else {
                toast.error(
                    "De kolom kan niet worden verwijderd. Zorg ervoor dat de gerelateerde sets, pagina's en lijsten zijn verwijderd, of selecteer een andere kolom."
                );
                setDeleteNotification(false);
            }
            return;
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");

            setDeleteNotification(false);
        }
    };

    const handleDeleteClick = (columnData) => {
        setDeleteTarget(columnData);
        setDeleteNotification(true);
    };

    const handleDuplicateClick = async (columnData) => {
        const sourceSet = columnData.key;
        let type;
        let fieldType;
        if (sourceSet.fieldType === "Veldenset") {
            type = 2;
            fieldType = "property";
        } else if (sourceSet.fieldType === "Tabbladen") {
            type = 4;
            fieldType = "tab";
        } else if (sourceSet.fieldType === "Gerelateerd") {
            type = 1;
            fieldType = "relation";
        }
        if (sourceSet.fieldType === "Afbeeldingen") {
            type = 3;
            fieldType = "image";
        }
        const newSet = {
            ...sourceSet,
            fieldType,
            type,
            id: undefined,
            tableId: undefined,
            canBeDeleted: undefined,
            name: `kopie: ${sourceSet.name}`,
            columns: sourceSet.columns.filter(({ sort }) => sort !== 0).map(({
                columnId,
                sort,
                id,
                controlRelationColumnFunction,
                name,
            }) => ({
                columnId,
                sort,
                id: id || sort,
                name,
                ...(controlRelationColumnFunction ? {
                    controlRelationColumnFunction: {
                        ...controlRelationColumnFunction,
                        controlRelationId: undefined,
                    },
                } : {})
            })),
        };
        const postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/control/${fieldType}`;
        try {
            const res = await msalFetch(null, postUrl, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(newSet),
            });

            if (res.ok) {
                const resObject = await res.json();
                toast.success("De gegevens zijn successvol opgeslagen...");
                let targetId = resObject.id;
                // refetch all the data first
                await getImages();
                pushToSingle(targetId);
            } else {
                toast.error('Oeps er ging iets mis...');
            }
        } catch (error) {
            toast.error("Er ging iets mis bij het kopieren.");
        }
    };

    const columnEditControl = (columnData) => {
        if (columnData.data.name.includes("bl_") || columnData.data.name === "Id") {
            return null;
        } else {
            return (
                <div className="bl-column-controls">
                    <i
                        className="fas fa-pencil-alt bl-column-control-btn bl-column-edit-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditColumn(columnData);
                        }}
                    />
                    {['Veldenset', 'Afbeeldingen'].includes(columnData.key.fieldType) && (
                        <i
                            onMouseEnter={() => {
                                setHover(true);
                            }}
                            onMouseLeave={() => {
                                setHover(false);
                            }}
                            className="fas fa-clone bl-column-control-btn bl-column-edit-btn bl-column-duplicate-btn"
                            onClick={() => handleDuplicateClick(columnData)}
                        />
                    )}
                    <i
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        className={`fas fa-trash bl-column-control-btn bl-column-delete-btn ${!columnData.data.canBeDeleted ? "bl-disabled-btn" : ""}`}
                        onClick={() => handleDeleteClick(columnData.key)}
                    />
                </div>
            );
        }
    };

    const getTabs = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/control/tab`
            );
            const data = await response.json();
            data.map((item) => {
                if (data.length < 1) return null;
                let tempItem = item;
                item["fieldType"] = "Tabbladen";
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });
            setSetData(tempFieldValue);
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    const getRelations = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/control/relation`
            );
            const data = await response.json();
            setTabRelations(data);

            data.map((item) => {
                let tempItem = item;
                if (data.length < 1) return null;
                tempItem["fieldType"] = "Gerelateerd";

                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });

            getTabs(tempFieldValue);

            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    const getProperties = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/control/property`
            );
            const data = await response.json();

            data.map((item) => {
                let tempItem = item;
                if (data.length < 1) return null;
                tempItem["fieldType"] = "Veldenset";
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });

            getRelations(tempFieldValue);

            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    const getImages = async () => {
        setLoading(true);

        try {
            let tempFieldValue = [];
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/control/image`
            );
            const data = await response.json();

            data.map((item) => {
                let tempItem = item;
                if (data.length < 1) return null;
                tempItem["fieldType"] = "Afbeeldingen";
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });

            getProperties(tempFieldValue);

            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    useEffect(() => {
        getImages();
    }, []);

    return (
        <div>
            {loading && <Loading />}
            {!loading && (
                <DataGrid
                    dataSource={setData}
                    showBorders={true}
                    showColumnLines={true}
                    rowAlternationEnabled={true}
                    onRowClick={handleEditColumn}
                >
                    <Paging defaultPageSize={25} />
                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                    />

                    <FilterRow visible={true} />
                    <ColumnFixing enabled={true} />
                    <LoadPanel enabled={true} />
                    <Sorting mode="multiple" />
                    <GroupPanel visible={true} />
                    <Grouping contextMenuEnabled={true} autoExpandAll={false} expandMode="rowClick" />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Scrolling mode="standard" />
                    <FilterRow showAllText="Beide" />
                    <Column
                        dataField="name"
                        dataType="string"
                        caption="Naam"
                        sortIndex={0}
                        sortOrder="asc"
                    // defaultSortOrder="asc"
                    />
                    <Column
                        dataField="fieldType"
                        dataType="string"
                        // groupIndex={0}
                        caption="Type"
                        width="40%"
                    >
                        <Lookup
                            dataSource={setTypeOptions}
                            valueExpr="type"
                            displayExpr="type"
                            showAllText="Beide"
                        />
                    </Column>

                    <Column
                        width={150}
                        fixed={true}

                        fixedPosition="right"
                        cellRender={columnEditControl}
                        headerCellRender={addNewButton}
                        alignment="right"
                    />

                    <FilterRow visible={true} showAllText="Alle" />
                </DataGrid>
            )}
            {activeSet && (isCreate || singleItem) && (
                <EditSet
                    isEditing={activeSet}
                    datasetId={datasetId}
                    setIsEditing={setIsEditing}
                    isCreate={isCreate}
                    data={singleItem}
                    columns={columns}
                    refreshFunction={getImages}
                    tabRelations={tabRelations}
                />
            )}

            {deleteNotification && (
                <DeleteItem
                    setOpendDeleteNotification={setDeleteNotification}
                    handleDeleteFunction={handleDeleteFunction}
                />
            )}
        </div>
    );
};

export default SetGrid;
