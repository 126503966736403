import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { CheckBox, RadioGroup, SelectBox, TextBox } from "devextreme-react";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import { DataGrid } from "devextreme-react";
import { RowDragging, Column, Scrolling } from "devextreme-react/data-grid";
import Loading from "../../../loading/Loading";

const RelatedSet = ({
    formValue,
    setFormValue,
    setColumns,
    setSetColumns,
    setgridRef,
    checkChanges,
    checkMandatoryField,
    handleReorder,
    deleteButton,
    isCreate,
    datasetId,
}) => {
    const msal = useMsal();
    const [relatedTables, setRelatedTables] = useState();
    const [relatedColumns, setRelatedColumns] = useState();
    const [relatedGridColumns, setRelatedGridColumns] = useState(
        formValue.columns ? formValue.columns.filter((t) => t.width !== 0) : []
    );
console.log("relatedGridColumns", relatedGridColumns);
    const [relatedLoading, setRelatedLoading] = useState(true);
    const [relatedColumnLoading, setRelatedColumnLoading] = useState(true);
    const [relatedPages, setRelatedPages] = useState([]);
    const [manualSortColumns, setManualSortColumns] = useState([]);
    const [columnLoading, setColumnLoading] = useState(true);
    const [fieldColumns, setFieldColumns] = useState([]);
    const [preferredDetailViewColumns, setPreferredDetailViewColumns] = useState([]);
    const [detailviewsFromSameTable, setDetailviewsFromSameTable] = useState([]);

    const widthOptions = [
        { v: 1, d: "1/12" },
        { v: 2, d: "2/12" },
        { v: 3, d: "3/12" },
        { v: 4, d: "4/12" },
        { v: 5, d: "5/12" },
        { v: 6, d: "6/12" },
        { v: 7, d: "7/12" },
        { v: 8, d: "8/12" },
        { v: 9, d: "9/12" },
        { v: 10, d: "10/12" },
        { v: 11, d: "11/12" },
        { v: 12, d: "12/12" },
    ];

    const columnFunctions = [
        //{ v: null, d: "..." },
        { v: "SUM", d: "Som" },
        { v: "MIN", d: "Min" },
        { v: "MAX", d: "Max" },
        { v: "AVG", d: "Gemiddeld" },
        { v: "COUNT", d: "Aantal" },
    ];

    const joinOptions = [
        { v: "LEFT JOIN", d: "Left join" },
        { v: "INNER JOIN", d: "Inner join" },
    ];

    const sortIndices = [
        { v: null, d: null },
        { v: 1, d: '1' },
        { v: 2, d: '2' },
        { v: 3, d: '3' },
        { v: 4, d: '4' },
    ];

    const sortDirections = [
        { v: null, d: null },
        { v: 'asc', d: 'Oplopend' },
        { v: 'desc', d: 'Aflopend' },
    ]

    const groupIndices = [
        { v: null, d: null },
        { v: 1, d: '1' },
        { v: 2, d: '2' },
        { v: 3, d: '3' },
        { v: 4, d: '4' },
    ];

    const addColumn = () => {
        let tempForm = formValue;
        tempForm.columns.push({
            columnId: null,
            controlRelationColumnFunction: null,
            controlRelationId: formValue.id,
            sort: tempForm.columns.length + (isCreate ? 1 : 0), //isCreate has no id yet, ensure the sort starts at 1
            width: 6,
        });

        setFormValue(tempForm);
        setRelatedGridColumns(tempForm.columns.filter((t) => t.width !== 0));

        checkChanges();
        setgridRef.current.instance().refresh(false);
    };

    const addButton = () => {
        return (
            <div
                className={`bl-btn bl-add-btn bl-config-header-btn`}
                onClick={addColumn}
            >
                <i className="fas fa-plus" />
            </div>
        );
    };

    const deleteColumn = (column) => {
        let tempForm = formValue;
        let remainingColumns = tempForm.columns.filter(
            (t) => t.sort !== column.data.sort
        );
        remainingColumns.map((t, index) => (t.sort = index));

        tempForm.columns = remainingColumns;
        setRelatedGridColumns(remainingColumns.filter((t) => t.width !== 0));
        setgridRef.current.instance().refresh(true);

        checkChanges();
    };

    const deleteRelatedCollumnButton = (column) => {
        return (
            <div className="bl-column-controls">
                <i
                    className="fas fa-trash bl-column-control-btn bl-column-delete-btn"
                    onClick={() => deleteColumn(column)}
                />
            </div>
        );
    };

    const handleHeightChange = useCallback((e) => {
        let tempForm = formValue;
        tempForm.verticalHeight = e.value == null ? null : Number(e.value);
        setFormValue(tempForm);
        checkChanges();

    }, [formValue, setFormValue, checkChanges])

    const columnSelectBox = (data) => {
        const items = [
            {
                key: 'Uit deze tabel',
                items: fieldColumns.filter(({ primairyKey }) => !primairyKey),
            },
        ].concat(preferredDetailViewColumns.length === 0 ? [] : [
            {
                key: 'Uit de voorkeurspagina',
                items: preferredDetailViewColumns.filter(({ primairyKey }) => !primairyKey),
            }
        ]);
        return (
            <SelectBox
                searchEnabled
                grouped={true}
                items={items}
                valueExpr="id"
                displayExpr="alias"
                defaultValue={data.key.columnId}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.columns.find((t) => t.sort === data.key.sort)["columnId"] = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const widthSelectBox = (data) => {
        return (
            <SelectBox
                searchEnabled
                items={widthOptions}
                valueExpr="v"
                displayExpr="d"
                defaultValue={data.key.width}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.columns.find((t) => t.sort === data.key.sort)["width"] =
                        e.value;
                    setFormValue(tempForm);

                    checkChanges();
                }}
            />
        );
    };

    const functionSelectBox = (data) => {
        return (
            <SelectBox
                showClearButton={true}
                searchEnabled
                items={columnFunctions}
                valueExpr="v"
                displayExpr="d"
                /*defaultValue={
                    data.key.controlRelationColumnFunction
                        ? data.key.controlRelationColumnFunction.function
                        : ""
                }*/
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    if (e.value == null) {
                        tempForm.columns.find((t) => t.sort === data.key.sort)["controlRelationColumnFunction"] = null;
                    } else {
                        let label = columnFunctions.find((t) => t.v === e.value).d;
                        tempForm.columns.find((t) => t.sort === data.key.sort)["controlRelationColumnFunction"] = {
                            controlRelationColumnId: data.key.columnId,
                            controlRelationId: data.key.controlRelationId,
                            function: e.value,
                            label: label,
                        };
                    }
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const sortIndexSelectBox = (data) => {
        return (
            <SelectBox
                showClearButton={true}
                items={sortIndices}
                valueExpr="v"
                displayExpr="d"
                defaultValue={data.key.sortIndex}
                disabled={formValue.manualChangeOrderByColumnId != null}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.columns.find((t) => t.sort === data.key.sort)["sortIndex"] = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const sortDirectionSelectBox = (data) => {
        console.log("data", data, "formValue.columns", formValue.columns, "val", formValue.columns.find(col => col.columnId === data.key.columnId));
        return (
            <SelectBox
                showClearButton={true}
                items={sortDirections}
                valueExpr="v"
                displayExpr="d"
                defaultValue={formValue.columns.find(col => col.columnId === data.key.columnId)?.sortDirection /*the question mark operator is just a patch to prevent crashes*/ }
                //defaultValue={data.sortDirection}
                disabled={formValue.manualChangeOrderByColumnId != null}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    console.log("tempForm.columns", tempForm.columns, "columnId", data.key.columnId);
                    tempForm.columns.find(col => col.columnId === data.key.columnId).sortDirection = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const groupIndexSelectBox = (data) => {
        return (
            <SelectBox
                showClearButton={true}
                items={groupIndices}
                valueExpr="v"
                displayExpr="d"
                defaultValue={data.key.groupIndex}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.columns.find((t) => t.sort === data.key.sort)["groupIndex"] = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const listItemRender = (data) => {
        return <div> {data.name}</div>;
    };

    const getPages = async (columnName, inputTableId) => {
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset/${inputTableId}/page`
            );
            const data = await response.json();
            if (data.length > 0) {
                return {
                    inputTableId,
                    key: columnName,
                    items: data,
                };
            }
            return null;
        } catch (error) {
            throw error;
        }
    };

    const {
        preferredDetailViewId,
        preferredDetailViewIdAdd,
        preferredDetailViewIdEdit,
        relationTableId,
    } = formValue;
    useEffect(() => {
        if (!preferredDetailViewId) {
            return;
        }

        const page = relatedPages.find(({ items }) => items.some(({ id }) => id === preferredDetailViewId));
        const tableId = page?.inputTableId;

        if (relationTableId === tableId) {
            setPreferredDetailViewColumns([]);
            return;
        }

        if (!tableId) {
            return;
        }

        const fetchedAdditionPages = async () => {
            try {
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column`);
                const data = await response.json();
                setPreferredDetailViewColumns(data);
            } catch (error) {
                throw error;
            }
        }
        fetchedAdditionPages();

    }, [relatedPages, relationTableId, preferredDetailViewId]);

    const getColumn = async (tableId) => {
        setColumnLoading(true);
        const fromTheTablePage = await getPages('Uit deze tabel', tableId);

        try {
            const response = await msalFetch(msal, `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column`);
            const data = await response.json();

            setFieldColumns(data);
            setRelatedColumns(data.filter((t) => t.inputTableId === parseFloat(datasetId)));
            setRelatedColumnLoading(false);
            setManualSortColumns(data.filter((item) => item.type === "int" && (item.inputTableId === 0 || item.inputTableId == null) && !item.primairyKey));

            const promises = data.filter(({ inputTableId }) => !isNaN(inputTableId) && inputTableId > 0).map(async (item) => {
                return await getPages(`Uit het veld ${item.name}`, item.inputTableId);
            });

            const pages = await Promise.all(promises);
            setRelatedPages([fromTheTablePage, ...pages.filter(Boolean)]);
            setDetailviewsFromSameTable([fromTheTablePage]);
            setColumnLoading(false);
        } catch (error) {
            throw error;
        }
    };

    const getRelatedTables = async () => {
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId}/afhankelijk-datasets`
            );
            const data = await response.json();
            if (formValue.relationTableId) {
                await getColumn(formValue.relationTableId);
            }
            setRelatedTables(data);
            setRelatedLoading(false);
        } catch (error) {
            setRelatedLoading(false);
            throw error;
        }
    };

    useEffect(() => {
        getRelatedTables();
    }, []);

    if (relatedLoading) {
        return <Loading />;
    } else {
        return (
            <div>
                <header className="bl-config-section-header">
                    <h3>Waaruit komt de gerelateerde informatie</h3>
                </header>

                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Tabel</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={relatedTables}
                                readOnly={!isCreate || (relatedLoading ? true : false)}
                                valueExpr="id"
                                displayExpr="tableName"
                                onValueChanged={(e) => {
                                    getColumn(e.value);
                                    setFormValue({
                                        ...formValue,
                                        relationTableId: e.value,
                                        keyColumnId: null,
                                    });
                                    checkChanges();
                                }}
                                defaultValue={formValue.relationTableId}
                                className={
                                    formValue.relationTableId && formValue.relationTableId !== ""
                                        ? ""
                                        : "bl-mandatory-field"
                                }
                            />
                        </Col>
                        <Col lg="3">
                            <Form.Label>Voorkeurspagina</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={relatedPages}
                                readOnly={columnLoading}
                                valueExpr="id"
                                displayExpr="name"
                                itemRender={listItemRender}
                                grouped={true}
                                onValueChanged={(e) => {
                                    setFormValue((state) => ({
                                        ...state,
                                        preferredDetailViewId: e.value,
                                    }));
                                    checkChanges();
                                }}
                                defaultValue={preferredDetailViewId}
                                showClearButton={true}
                            />

                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Kolom</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={relatedColumns}
                                readOnly={relatedColumnLoading ? true : false}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["keyColumnId"] = e.value;
                                    e.element.classList.remove("bl-mandatory-field");
                                    // checkMandatoryField(e.value, e.element);
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                                defaultValue={formValue.keyColumnId}
                                className={
                                    formValue.keyColumnId && formValue.keyColumnId !== ""
                                        ? ""
                                        : "bl-mandatory-field"
                                }
                            />
                        </Col>
                        <Col lg="3">
                            <Form.Label>Voorkeurspagina (toevoegen)</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={detailviewsFromSameTable}
                                readOnly={columnLoading}
                                valueExpr="id"
                                displayExpr="name"
                                itemRender={listItemRender}
                                grouped={true}
                                onValueChanged={(e) => {
                                    setFormValue((state) => ({
                                        ...state,
                                        preferredDetailViewIdAdd: e.value,
                                    }));
                                    checkChanges();
                                }}
                                defaultValue={preferredDetailViewIdAdd}
                                showClearButton={true}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Handmatig sorteren</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={[{ id: null, alias: "..." }, ...manualSortColumns]}
                                readOnly={
                                    formValue.inputTableId === "" && relatedLoading ? true : false
                                }
                                valueExpr="id"
                                displayExpr="alias"
                                onValueChanged={(e) => {
                                    const tempFormValue = formValue;
                                    setFormValue({
                                        ...formValue,
                                        manualChangeOrderByColumnId: e.value,
                                        queryOrderBy: "",

                                    });
                                    console.log("tempFormValue", tempFormValue);
                                    tempFormValue.columns.forEach(listColumn => {
                                        listColumn.sortDirection = null;
                                        listColumn.sortIndex = null;
                                    });
                                    checkChanges();
                                }}
                                defaultValue={
                                    formValue.manualChangeOrderByColumnId &&
                                    formValue.manualChangeOrderByColumnId
                                }
                            />
                        </Col>
                        <Col lg="3">
                            <Form.Label>Voorkeurspagina (bewerken)</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={detailviewsFromSameTable}
                                readOnly={columnLoading}
                                valueExpr="id"
                                displayExpr="name"
                                itemRender={listItemRender}
                                grouped={true}
                                onValueChanged={(e) => {
                                    setFormValue((state) => ({
                                        ...state,
                                        preferredDetailViewIdEdit: e.value,
                                    }));
                                    checkChanges();
                                }}
                                defaultValue={preferredDetailViewIdEdit}
                                showClearButton={true}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Uitklapbare pagina in rij</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                grouped={false}
                                items={relatedPages.find(({ inputTableId }) => inputTableId === formValue.relationTableId)?.items}
                                readOnly={columnLoading}
                                displayExpr="name"
                                valueExpr="id"
                                itemRender={listItemRender}
                                defaultValue={
                                    formValue.expandDetailviewId
                                        ? formValue.expandDetailviewId
                                        : null
                                }
                                onValueChanged={(e) => {
                                    let tempFormValue = formValue;
                                    tempFormValue["expandDetailviewId"] = e.value;
                                    setFormValue(tempFormValue);
                                    checkChanges();
                                }}
                                showClearButton={true}
                            />
                        </Col>

                        <Col lg="3">
                            <Form.Label>Hoogte</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                items={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                defaultValue={
                                    formValue['verticalHeight']
                                }
                                onValueChanged={handleHeightChange}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Filteren op ingelogde gebruiker</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={
                                    formValue.filterOnSignedUserColumnId
                                        ? formValue.filterOnSignedUserColumnId
                                        : false
                                }
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["filterOnSignedUserColumnId"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                        <Col lg="4">
                            <Form.Label>Alleen lezen</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={formValue.newButton ? false : true}
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    if (e.value === true) {
                                        tempForm["newButton"] = false;
                                    } else {
                                        tempForm["newButton"] = true;
                                    }
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Export naar Excel</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={
                                    formValue.allowExportToExcel
                                        ? formValue.allowExportToExcel
                                        : false
                                }
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["allowExportToExcel"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                        <Col lg="4">
                            <Form.Label>Inline bewerken</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={
                                    formValue.inlineEditing ? true : false
                                }
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["inlineEditing"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Kopiëren</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={formValue.allowCopy ? formValue.allowCopy : false}
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["allowCopy"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                        <Col lg="4">
                            <Form.Label>Horizontaal scrollen</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={
                                    formValue.scrollHorizontal
                                        ? formValue.scrollHorizontal
                                        : false
                                }
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["scrollHorizontal"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Automatisch herladen</Form.Label>
                        </Col>
                        <Col lg="2">
                            <CheckBox
                                defaultValue={
                                    formValue.autoRefresh ? formValue.autoRefresh : false
                                }
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["autoRefresh"] = e.value;

                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>
                                <h3>Kolommen</h3>
                            </Form.Label>
                        </Col>
                        <Col lg="12">
                            <DataGrid
                                ref={setgridRef}
                                dataSource={relatedGridColumns}
                                // className="edit-set-column-grid"

                                showColumnLines={false}
                                rowAlternationEnabled={true}
                                showRowLines={true}
                            >
                                <Scrolling mode="virtual" />
                                <RowDragging
                                    allowReordering={true}
                                    onReorder={handleReorder}
                                    showDragIcons={true}
                                />
                                <Column
                                    caption="Naam"
                                    allowSorting={false}
                                    alignment="left"
                                    dataField="columnId"
                                    cellRender={columnSelectBox}
                                />
                                <Column
                                    dataField="sort"
                                    sortOrder="asc"
                                    width={1}
                                    className="hidden-column"
                                />
                                <Column
                                    caption="Breedte"
                                    allowSorting={false}
                                    alignment="left"
                                    dataField="width"
                                    cellRender={widthSelectBox}
                                    width={120}
                                />
                                <Column
                                    caption="Functie"
                                    allowSorting={false}
                                    alignment="left"
                                    dataField="controlRelationColumnFunction.function"
                                    cellRender={functionSelectBox}
                                    width={135}
                                />
                                <Column
                                    caption="Sorteerindex"
                                    allowSorting={false}
                                    alignment="left"
                                    cellRender={sortIndexSelectBox}
                                    width={150}
                                />
                                <Column
                                    caption="Sorteerrichting"
                                    allowSorting={false}
                                    alignment="left"
                                    cellRender={sortDirectionSelectBox}
                                    width={150}
                                />

                                <Column
                                    caption="Groepindex"
                                    allowSorting={false}
                                    alignment="left"
                                    cellRender={groupIndexSelectBox}
                                    width={150}
                                />

                                <Column
                                    alignment="center"
                                    width={100}
                                    headerCellRender={addButton}
                                    cellRender={deleteRelatedCollumnButton}
                                />
                            </DataGrid>
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Join</Form.Label>
                        </Col>
                        <Col lg="8">
                            <RadioGroup
                                items={joinOptions}
                                defaultValue={formValue.join ? formValue.join : "LEFT JOIN"}
                                layout="horizontal"
                                displayExpr="d"
                                valueExpr="v"
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["join"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Voldoet aan</Form.Label>
                        </Col>
                        <Col lg="8">
                            <TextBox
                                valueChangeEvent="keyup"
                                defaultValue={formValue.queryWhere && formValue.queryWhere}
                                placeHolder="Voldoet aan"
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["queryWhere"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Sorteren op</Form.Label>
                        </Col>
                        <Col lg="8">
                            <TextBox
                                valueChangeEvent="keyup"
                                defaultValue={formValue.queryOrderBy && formValue.queryOrderBy}
                                placeHolder="Voldoet aan"
                                disabled={
                                    formValue.manualChangeOrderByColumnId !== null ? true : false
                                }
                                onValueChanged={(e) => {
                                    let tempForm = formValue;
                                    tempForm["queryOrderBy"] = e.value;
                                    setFormValue(tempForm);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </div>
        );
    }
};

export default RelatedSet;
