import { DataGrid } from "devextreme-react";
import {
    Column,
    Lookup,
    FilterRow,
    Paging,
    Pager,
    HeaderFilter,
    LoadPanel,
    ColumnFixing,
    Scrolling,
    Editing
} from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react";
import React, { useCallback, useState, useEffect, useRef } from "react";
import Loading from "../../../loading/Loading";

const ListGrid = ({
    checkChanges,
    form,
    setForm,
    overviews,
    overviewLoading,
}) => {
    const dataGridRef = useRef();
    const [loading, setLoading] = useState(true);
    const [defaultPageId, setDefaultPageId] = useState(form.defaultOverviewId);
    const [invalidOverviews, setInvalidOverviews] = useState(false);

    useEffect(() => {
        let tempLists = [];
        let tempForm = form;

        if (overviewLoading) {
            return;
        } else {
            if (overviews.length > 0) {
                overviews.map((item) => {
                    if (form.overview.find((t) => t.id === item.id)) {
                        let target = form.overview.find((t) => t.id === item.id);
                        target["tableName"] = item.table.tableName;
                        target["overviewName"] = item.name;
                        tempLists.push(target);
                    } else {
                        tempLists.push({
                            id: item.id,
                            visible: 0,
                            defaultOverview: false,
                            tableName: item.table.tableName,
                            overviewName: item.name,
                        });
                    }

                    return tempLists;
                });

                tempForm["overview"] = tempLists;
                setForm(tempForm);
                setLoading(false);
            } else {
                setLoading(false);
                setInvalidOverviews(true);
            }
        }
    }, [overviewLoading]);

    const listNameRender = (data) => {
        return overviews.find((overview) => overview.id === data.data.id).name;
    };

    const inlineCheckBoxDefaultOverview = (data) => {
        /*
                let value = false;
        
                if (form.defaultOverviewId && defaultPageId === data.key.id) {
                    value = true;
                } else {
                    value = false;
                }*/
        const value = data.key.id === form["defaultOverviewId"];
        return (
            // <div key={defaultPageId}>
            <CheckBox
                defaultValue={value}
                readOnly={data.key.visible === 0}
                onValueChanged={(e) => {
                    let tempForm = form;

                    if (e.value) {
                        tempForm["defaultOverviewId"] = data.key.id;
                        //setDefaultPageId(data.key.id);
                    } else {
                        tempForm["defaultOverviewId"] = null;
                    }
                    checkChanges();
                    setForm(tempForm);
                }}
            />
            // </div>
        );
    };

    const inlineCheckBox = (data) => {
        let value = data.value;

        if (value === 1) {
            value = true;
        } else {
            value = false;
        }

        return (
            <CheckBox
                defaultValue={value}
                onValueChanged={(e) => {
                    let binair;
                    if (e.value) {
                        binair = 1;
                    } else {
                        binair = 0;
                    }
                    let tempForm = form;
                    tempForm.overview.find((t) => t.id === data.data.id)[
                        data.column.dataField
                    ] = binair;
                    checkChanges();
                    setForm(tempForm);
                }}
            />
        );
    };

    const visibleCellHeader = () => {
        return (
            <div className="bl-grid-select-all">
                <span>
                    <CheckBox
                        defaultValue={false}
                        onValueChanged={(e) => {
                            checkAllChange(e.value, "visible");
                        }}
                    />
                    <p>Alles selecteren</p>
                </span>
            </div>
        );
    };

    const checkAllChange = (value, target) => {
        let binair;
        if (value) {
            binair = 1;
        } else {
            binair = 0;
        }
        let tempForm = form;
        let tempRoleTable = [];
        tempForm.overview.map((item) => {
            let tempItem = item;

            tempItem[target] = binair;
            return (tempRoleTable = [...tempRoleTable, tempItem]);
        });
        tempForm["overview"] = tempRoleTable;
        setForm(tempForm);
        dataGridRef.current.instance().refresh();
        checkChanges();
    };

    const onRowUpdated = useCallback((e) => {

    }, []);

    if (loading) {
        return <Loading />;
    } else if (!invalidOverviews) {
        return (
            <DataGrid
                dataSource={form.overview}
                ref={dataGridRef}
                columnAutoWidth={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                showRowLines={true}
                wordWrapEnabled={true}
                onRowUpdated={onRowUpdated}
            >
                <Paging defaultPageSize={50} />
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />
                <ColumnFixing enabled={true} />
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Scrolling mode="standard" />
                <FilterRow visible={true} showAllText="Alles" />
                {/* <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={false}
                /> */}
                <Column
                    caption="Tabel"
                    dataField="tableName"
                    dataType="string"
                    sortOrder="asc"
                    alignment="left"
                    allowSorting={true}
                    width={300}
                    allowEditing={false}
                />
                <Column
                    caption="Lijst"
                    dataField="overviewName"
                    dataType="string"
                    alignment="left"
                    cellRender={listNameRender}
                    allowSorting={true}
                    allowEditing={false}
                />
                <Column caption="Zichtbaar" alignment="center" allowSorting={false}>
                    <Column
                        dataField="visible"
                        dataType="boolean"
                        alignment="center"
                        cellRender={inlineCheckBox}

                        width={140}
                        headerCellRender={visibleCellHeader}
                    />
                </Column>

                <Column
                    // dataField="defaultOverviewId"
                    // dataType="boolean"
                    alignment="center"
                    width={140}
                    cellRender={inlineCheckBoxDefaultOverview}
                    caption="Startpagina"
                />
            </DataGrid>
        );
    } else {
        return (
            <div className="bl-card">
                <i className="fas fa-info" />
                <span> Geen lijsten gevonden </span>
            </div>
        );
    }
};

export default ListGrid;
