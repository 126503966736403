import React, { useEffect, useCallback, useState } from "react";
import { Popup } from 'devextreme-react/popup';
import saveAs from "file-saver";
import Msg from './msg';
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings";

const isImage = str => [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'webp',
    'avif',
].some(ext => str.toLowerCase().endsWith(`.${ext}`));



const RenderUrl = ({ url, title, downloadFunction }) => {
    if (!title || !url) {
        return null;
    }
    if (isImage(title)) {
        return (
            <img className="bloom-popup-image" src={url} alt={title} />
        );
    }
    if (title.toLowerCase().endsWith('.pdf')) {
        return (
            <embed
                src={`${url}#toolbar=0`}
                title={title}
                style={{
                    display: 'block', // fix for scroll bar not showing up. That is because a inline element has a space after itself, so a scroll bar shows
                    width: '100%',
                    height: '100%',
                }}
            />
        );
    }
    if (title.toLowerCase().endsWith('.msg')) {
        return (
            <Msg url={url} />
        );
    }
    return (
        <button className="bl-btn" onClick={downloadFunction}>
            Download
        </button>
    )
}


const PreviewImage = ({
    url,
    title,
    closeFunction,
    allowDownload = true,
    ftp
}) => {
    const value = title;
    useEffect(() => {
        const downHandler = (e) => {
            if (e.key === "Escape" && closeFunction) {
                closeFunction();
            }
        };
        window.addEventListener("keydown", downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
        };
    }, [closeFunction]);

    const [fullScreen, setFullScreen] = useState(false);
    const fullScreenIcon = (!fullScreen ? 'fas fa-expand-arrows-alt' : 'fas fa-window-minimize');
    const popupTitle = title;
    const isPDF = title.toLowerCase().endsWith('.pdf');


    const strEndsWith = str => ending => (str || "").toLowerCase().endsWith(ending);
    const getFileType = (str) => {
        const endsWith = strEndsWith(str);
        switch (true) {
            case endsWith('.jpg'):
            case endsWith('.jpeg'):
            case endsWith('.png'):
            case endsWith('.gif'):
            case endsWith('.bmp'):
            case endsWith('.webp'):
            case endsWith('.tiff'):
                return 'image';
            case endsWith('.xls'):
            case endsWith('.csv'):
                return 'excel';
            case endsWith('.pdf'):
                return 'pdf';
            case endsWith('.ppt'):
                return 'powerpoint';
            case endsWith('.doc'):
            case endsWith('.docx'):
            case endsWith('.odt'):
                return 'word';
            case endsWith('.html'):
            case endsWith('.htm'):
                return 'code';
            case endsWith('.msg'):
                return 'lines';
            default:
                return null;
        }
    }

    const fileType = getFileType(value);
    const getDownload = (fileType) => {
        switch (fileType) {
            case 'pdf':
                return false;
            case 'code':
                return false;
            case 'image':
                return true;
            default:
                return true;
        }
    }

    const getPreview = useCallback(async (downloadPass) => {
        const download = downloadPass ? true : getDownload(fileType);
        //setPreviewLoading(true);
        let imageObjectURL;

        try {
            if (ftp) {
                return title;
            }
            else {
                const res = await msalFetch(null,
                    `${appSettings.api.endpoint}/api/table/downloadfile/${value.replace('&', '[AMPERSAND]')}/?download=${download}` // I dont know what this download boolean is for. It does not seem to change the output, Laurens Kling 2022
                );

                const resData = await res.blob();
                imageObjectURL = URL.createObjectURL(resData);
                //setPreviewLoading(false);
                return imageObjectURL;
            }
        } catch (error) {
            //setPreviewLoading(false);
            console.error(error);
            throw error;
        }


    }, [value, fileType, ftp, title]);

    const downloadFunction = useCallback(async (e) => {
        // use the image we have or fetch a new one
        // but we would always have on probably, so we never do the `true` forced thing
        // I dont know what its for, so i don't know if we should enforce it, Laurens Kling 2022
        const imageObjectURL = /*imageUrl || */ await getPreview(true);

        const link = document.createElement("a");
        link.style.display = 'none';
        link.href = imageObjectURL;
        link.setAttribute("download", `${value}`);
        if (typeof link.download === 'undefined') {
            link.setAttribute('target', '_blank');
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        //setFileDownloadLoading(false);
    }, [/*title, url*/getPreview, value]);

    return (
        <Popup
            visible={true}
            resizeEnabled={true}
            enableBodyScroll={false}
            className="bloom-popup"
            title={popupTitle}
            dragEnabled={true}
            animation={false}
            fullScreen={fullScreen}
            onHiding={closeFunction}
            width={isPDF ? "80vw" : "auto"}
            height={isPDF ? "80vw" : "auto"}
            minHeight={"10vh"}
            maxHeight="80vh"
            minWidth={"20vw"}
            maxWidth="80vw"
            toolbarItems={[{
                toolbar: "top",
                icon: "email",

                stylingMode: 'text',
                widget: "dxButton",
                location: "after",
                options:
                {
                    icon: fullScreenIcon,
                    stylingMode: 'text',
                    type: 'default',
                    hint: 'Volledig scherm',
                    onClick: (e) => {

                        setFullScreen(!fullScreen);
                    }
                }


            }].concat(allowDownload ? {
                toolbar: "bottom",
                widget: "dxButton",
                location: "after",
                className: "Button",
                options:
                {
                    icon: 'download',
                    onClick: (e) => {
                        downloadFunction(e.event);
                    }
                }
            } : null).filter(Boolean)}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                }}
            >
                <RenderUrl
                    url={url}
                    title={title}
                    downloadFunction={downloadFunction}
                />
            </div>
        </Popup>
    );
};

export default PreviewImage;
