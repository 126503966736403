import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import React, { useState, useCallback, useMemo, useEffect } from "react";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import PowerReportWrapper from './PowerReportWrapper.js';
import { useParams } from "react-router-dom";
import { getFormatter } from '../formats.js';
import { PowerReportDataType, PowerReportRepresentationType } from '../../enums/powerReport.js'
import { TotalItem } from 'devextreme-react/cjs/data-grid.js';
//import DataSource from 'devextreme/data/data_source.js';

const PowerReportDataFetcher = ({
    tableItemId,
    overrideId,
}) => {
    // console.log("[PowerReport] PowerReportDataFetcher");
    const [renderGrid, setRenderGrid] = useState(false);
    const [fields, setFields] = useState();
    const [showPivotGrid, setShowPivotGrid] = useState();
    const [showChart, setShowChart] = useState();
    const [horizontalScrolling, setHorizontalScrolling] = useState();
    const [showFieldChooser, setShowFieldChooser] = useState();
    const [title, setTitle] = useState();
    const [powerReportRepresentationTypeId, setPowerReportRepresentationTypeId] = useState();
    const { powerReportId } = useParams();

    const id = overrideId ?? powerReportId;

    const getPivotGrid = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/power-report/get/${id}`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        const parsedFields = json.columns.map(item => {
            let format = null;
            let dataType = null;
            let alignment = 'left';
            let groupInterval = null;
            switch (item.columnDataTypeId) {
                case PowerReportDataType.Currency:
                    format = getFormatter('EURO0');
                    dataType = 'number';
                    alignment = 'right';

                    break;
                case PowerReportDataType.Dec1:
                    format = getFormatter('1');
                    dataType = 'number'
                    break;
                case PowerReportDataType.Dec2:
                    format = getFormatter('2');
                    dataType = 'number';
                    break;
                case PowerReportDataType.Date:
                    dataType = 'date';
                    format = 'dd-MM-yyyy';
                    // if(item.expanded) {
                    //     groupInterval = 'day';

                    // }
                    break;
                case PowerReportDataType.Number:
                    dataType = 'number';
                    format = '#,##0';
                    alignment = 'right';
                    break;
                case PowerReportDataType.String:
                    dataType = 'string';
                    //format = '#,##0';
                    break;
                default:
            }
            if (item.keyPropertyName != null)
                // console.log("keyPropertyName", item.keyPropertyName == null ? null : (item.keyPropertyName.charAt(0).toLowerCase() + item.keyPropertyName.slice(1)));
                console.log("type view", item)
            return {
                dataField: item.datafield?.toLowerCase(),
                caption: item.caption ?? item.datafield?.toLowerCase(),
                expanded: item.expanded,
                groupInterval: groupInterval,
                sortOrder: item.sortDirection,
                width: item.width,
                seqNr: item.seqNr,
                area: item.columnArea?.toLowerCase(),
                areaId: item.columnAreaId,
                summaryType: item.columnSummaryType?.toLowerCase(),
                dataType: dataType,
                dataTypeId: item.columnDataTypeId,
                detailviewId: item.detailviewId,
                detailviewName: item.detailviewName,
                //str.charAt(0).toLowerCase() + str.slice(1);
                keyPropertyName: item.keyPropertyName == null ? null : (item.keyPropertyName.charAt(0).toLowerCase() + item.keyPropertyName.slice(1)),
                format: format,
                alignment: alignment
            }
        });
        const sortedFields = parsedFields.sort((a, b) => {
            if (a.areaId < b.AreaId) { return -1; }
            if (a.areaId > b.AreaId) { return 1; }
            if (a.areaId === b.areaId) {
                if (a.seqNr < b.seqNr) { return -1 }
                if (a.seqNr > b.seqNr) { return 1 }
            }
            return 0;
        });
        //setFields(parsedFields);

        setFields(sortedFields);
        console.log("sortedFields", sortedFields);
        setShowPivotGrid(json.showPivotGrid);
        setShowChart(json.showChart);
        setHorizontalScrolling(json.horizontalScrolling);
        setTitle(json.title);
        setShowFieldChooser(json.showFieldChooser);
        setRenderGrid(true);
        setPowerReportRepresentationTypeId(json.powerReportRepresentationTypeId);
    }, [id]);

    const refreshPowerReport = useCallback(async (e) => {
        //setRefreshKey((prevKey) => prevKey + 1);
        await getPivotGrid();
    }, [getPivotGrid]);

    const loadDataSource = useCallback(async (loadOptions) => {
        // console.log("[PIVOTGRID] - loadDataSource", loadOptions);
        const url = `${appSettings.api.endpoint}/api/power-report/${id}/data/${tableItemId ?? ''}`;

        const options = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(loadOptions)
        }
        const response = await msalFetch(null, url/*, options*/);
        const json = await response.json();
        return json;
    }, [id, tableItemId])

    useEffect(() => { getPivotGrid() }, [getPivotGrid])

    const dataSourcePivotGrid = useMemo(() => {
        return new PivotGridDataSource({
            fields: fields,
            store: new CustomStore({
                key: 'id',
                load: loadDataSource
            })

        })
    }, [loadDataSource, fields]);

    const dataSourceTable = useMemo(() => {
        return new DataSource({
            //fields: fields,
            store: new CustomStore({
                //key: 'id',
                load: loadDataSource
            })

        })
    }, [loadDataSource]);


    if (!renderGrid) {
        return <div />;
    }



    return (<PowerReportWrapper
        tableItemId={tableItemId}
        refreshPowerReport={refreshPowerReport}
        powerReportId={id}
        dataSource={powerReportRepresentationTypeId === PowerReportRepresentationType.PivotGrid ? dataSourcePivotGrid : dataSourceTable}
        showChart={showChart}
        showPivotGrid={showPivotGrid}
        showFieldChooser={showFieldChooser}
        horizontalScrolling={horizontalScrolling}
        title={title}
        isDetailviewObject={tableItemId != null}
        powerReportRepresentationTypeId={powerReportRepresentationTypeId}
        fields={fields}
    />)
}

export default (PowerReportDataFetcher)