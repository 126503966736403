import React, { memo, useCallback } from "react";
import { TextBox } from "devextreme-react/text-box";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FieldLabel from "../detailview/FieldLabel";


const TEXT = ({
    label,
    value,
    matchesTheRegex,
    readonly,
    mandatory,
    columnId,
    registerLoaded,
    onControlUpdate,
    memoField,
    regex,
}) => {

    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const handleValueChange = useCallback((e) => {
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={label}
                        regex={regex}
                        matchesTheRegex={matchesTheRegex}
                        memoField={memoField}
                        mandatory={mandatory}
                        columnId={columnId}
                    />
                </Col>
                <Col
                    md="8"
                >
                    <TextBox
                        placeholder={label}
                        value={value}
                        onValueChanged={handleValueChange}
                        showClearButton={true}
                        readOnly={readonly}
                        stylingMode={readonly ? "filled" : "outlined"}
                        validationMessagePosition="bottom"
                        mandatory={true}
                        valueChangeEvent="input"
                        maxLength={250}
                        onInitialized={onInitialized}
                    >

                    </TextBox>
                </Col>
            </Row>

        </Form.Group>
    );
};

export default memo(TEXT);
