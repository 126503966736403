
import React, { memo, useState, useEffect, useCallback } from "react";
import Gallery from 'devextreme-react/gallery';
import appSettings from "../../appsettings";
import { Col } from "react-bootstrap";
import msalFetch from "../../api/MsalFetch.js";
import { ReactComponent as LogoGrey } from "../../assets/images/logo_grey.svg";
import Loading from "../../components/loading/Loading";
import PreviewImage from "../modals/Previews/PreviewImage";
import getQueryString from './getQueryString';
import { ColumnType } from "../../enums/columnType.js";

const ImageBlock = ({
  width,
  name,
  controlImageId,
  columnNumber,
  detailviewId,
  tableitemId,
  addColumnsToState,
  getColumnByColumnId,
  forceReloadTimestamp,
}) => {
  const [blockColumnIds, setBlockColumnIds] = useState([]);
  const [title, setTitle] = useState(null);

  const [fileData, setFileData] = useState();
  console.log("fileData", fileData);
  const [openPreview, setOpenPreview] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  //fetches the imageblock data
  const getBlockData = useCallback(async () => {
    try {
      const params = getQueryString({ timestamp: forceReloadTimestamp });
      const url = `${appSettings.api.endpoint}/api/${detailviewId}/image/${controlImageId}/${tableitemId}${params ? `?${params}` : ''}`;

      const res = await msalFetch(null, url);
      const data = await res.json();
      setTitle(data.name);

      if (!res.ok) {
        throw new Error(`[ImageBlock] getBlockData response was not ok: ${data.message}`);
      }

      const columns = data.columns.filter((column) => {
        if (column.value === "" || column.primairyKey) {
          return false;
        }
        return true;
      });
      setBlockColumnIds(columns.map(({ id }) => id));
      // this allows columns that are not in a property still being displayed, because we load them into the state
      addColumnsToState(columns, {
        newEntry: false,
        forced: Boolean(forceReloadTimestamp),
      });

    } catch (error) {
      throw error;
    }

  }, [detailviewId, controlImageId, tableitemId, addColumnsToState, forceReloadTimestamp]);

  useEffect(() => {
    const innerUseEffect = async () => {
      await getBlockData(); //async
    };
    innerUseEffect();
  }, [getBlockData]);

  useEffect(() => {
    // console.log("blockColumnIds", blockColumnIds);
    const stateColumns = blockColumnIds
      .map((id) => getColumnByColumnId(id))
      .filter(Boolean);

    // console.log("stateColumns", stateColumns);
    const newFileData = stateColumns
      .filter(({ value, thumbnailLarge }) => thumbnailLarge != null || (value ?? '').toLowerCase().startsWith('http'))
      .map(({ id, columnTypeId, value, thumbnailLarge }) => {
        let imageSrc;
        if (thumbnailLarge != null) {
          if (thumbnailLarge.startsWith('data')) {
            imageSrc = thumbnailLarge;
          } else {
            imageSrc = `data:image/png;base64, ${thumbnailLarge}`
          }
        } else {
          imageSrc = value;
        }
        return imageSrc;
      });
    setFileData(newFileData);
  }, [blockColumnIds, getColumnByColumnId]);

  const closePreview = useCallback(async () => {
    setOpenPreview(false);
    setPreviewTitle(null);
    setPreviewUrl(null);
  }, []);

  const onItemClick = useCallback(async (e) => {
    if (e.itemData.columnTypeId !== ColumnType.FILE && e.itemData.columnTypeId !== ColumnType.FTP)
      return;
    setPreviewTitle(e.itemData.value);
    setOpenPreview(true);
    const thumbnailLarge = e.itemData.imageSrc;
    const url = thumbnailLarge ? (thumbnailLarge.startsWith('data') ? `${thumbnailLarge}` : (`data:image/png;base64, ${thumbnailLarge}`)) : null
    console.log("onItemClick", e, url);
    setPreviewUrl(url);
  }, []);

  return (<>
    <Col lg={width} md={12} className={`bl-col-padding`}>
      <section className="bl-detail-column bl-card">
        <header className="bl-card-header">
          <h3>{title}</h3>
        </header>

        {fileData && <div /*className="bl-carousel-wrapper"*/>

          <Gallery
            id="gallery"
            dataSource={fileData}
            height={"40vh"}
            loop={false}
            noDataText="Geen afbeelding om te tonen"
            showNavButtons={true}
            showIndicator={true}
            swipeEnabled={false}
            onItemClick={onItemClick}
          />
        </div>
        }
      </section>
    </Col>
    {openPreview && <PreviewImage
      url={previewUrl}
      title={previewTitle}
      closeFunction={closePreview}
      ftp={false}
    />}
    {!fileData && <Loading />}
  </>)


};

export default (ImageBlock);
