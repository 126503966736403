export const PowerReportColumnArea = {
    Row: 1,
    Column: 2,
    Data: 3
}

export const PowerReportColumnSummaryType = {
    Sum: 1,
    Average: 2,
    Min: 3,
    Max: 4,
    Count: 5
}

export const PowerReportDataType = {
    Date: 1,
    Number: 2,
    String: 3,
    Currency: 4,
    Dec1: 5,
    Dec2: 6
}

export const PowerReportRepresentationType = {
    Table: 1,
    PivotGrid: 2
}