import React from "react";

const Loading = () => {
  return (
    <div className="form">
      <div className="dx-fieldset">
        <i className="fas fa-pulse fa-spinner" />
      </div>
    </div>
  );
};

export default Loading;
