import { useMsal } from "@azure/msal-react";
import React, { useRef } from "react";
import msalFetch from "../../api/MsalFetch.js";
import { useModalContext } from "../../context/modalContext";
import toast, { Toaster } from "react-hot-toast";

const DeleteProceedNotification = () => {
    const msal = useMsal();
    const { setDeleteProceedNotification, notificationData } = useModalContext();
    const cancelRef = useRef();

    const confirmedDelete = async () => {
        try {
            const res = await msalFetch(msal, notificationData.deleteUrl, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });
            if (res.ok) {
                setDeleteProceedNotification(false);
                toast.success("De gegevens zijn successvol opgeslagen...");
                if (notificationData.event) {
                    const evt = new Event(notificationData.event);
                    document.dispatchEvent(evt);
                }
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    return (
        <div className={`bl-notify-modal`}>
            <div className="bl-notify-modal-inner">
                <header className="bl-notify-modal-header">
                    <div
                        className="header-action"
                        onClick={() => {
                            setDeleteProceedNotification(false);
                            //   notificationData[0].scrollIntoView({ behavior: "smooth" });
                        }}
                    >
                        <i className="fas fa-times" />
                    </div>
                </header>
                <article>
                    <h3>Wilt u dit item verwijderen?</h3>
                    <footer className="bl-notify-controls">
                        <div
                            className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
                            onClick={confirmedDelete}
                        >
                            Verwijderen
                        </div>
                        <button
                            autoFocus
                            ref={cancelRef}
                            className="bl-notify-modal-btn bl-btn bl-notify-succes-btn"
                            onClick={() => {
                                setDeleteProceedNotification(false);
                            }}
                        >
                            Annuleren
                        </button>
                    </footer>
                </article>
            </div>
        </div>
    );
};

export default DeleteProceedNotification;
