import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import Loading from "../../loading/Loading.js";
import appSettings from "../../../appsettings.js";
import msalFetch from "../../../api/MsalFetch.js";
import toast from "react-hot-toast";
//import DataGridSort from "../../overview/DataGridSort";
import ListDataFetcher from "../../list/ListDataFetcher.js";
import { Popup } from 'devextreme-react/popup';

const SelectSearchModal = ({
    activeModal,
    setActiveModal,
    label,
    column,
    detailviewId,
    propertyId,
    handleOptionClick,
    inputListId,
    getColumnByColumnId,
    columnId,
}) => {
    const msal = useMsal();
    const [loading, setLoading] = useState(true);
    const [overviewLoading, setOverviewLoading] = useState(true);
    const [columnData, setColumnData] = useState();
    const [currentOverview, setCurrentOverview] = useState({});
    const [dataSource, setDataSource] = useState();

    const handleExitClick = useCallback(() => {
        setActiveModal(false);
    }, [setActiveModal]);

    const getOption = async (queryObject) => {
        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/${detailviewId}/property/${propertyId}/option`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(queryObject),
                }
            );
            const data = await res.json();
            return data;
        } catch (error) {
        }
    };

    const handleSelectClick = async (rowData) => {
        if (rowData.key == null) {
            return;
        }

        const optionData = await getOption({ columnId: columnId, value: rowData.key });

        if (optionData) {
            handleOptionClick(optionData, true);
        } else {
            toast.error("Geen resultaat...");
        }
        setActiveModal(false);
    };



    useEffect(() => {
        getOverviewData();
        getDataSource();
    }, [inputListId]);

    const getDataSource = async (noLoading) => {
        try {
            if (!noLoading) {
                setLoading(true);
            }
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/overview/${inputListId}/data/`
            );

            const data = await res.json();
            if (res.ok) {
                setDataSource(data.data);
            }
        } catch (error) {
            throw error;
        } finally {
            if (!noLoading) {
                setLoading(false);
            }
        }
    };

    const getOverviewData = async () => {
        try {
            setOverviewLoading(true);
            const response = await msalFetch(msal, `${appSettings.api.endpoint}/api/overview/${inputListId}/`);
            const data = await response.json();
            setColumnData(data);
            if (data && data.columns) {
                setCurrentOverview(data);
            }
            return;
        } catch (error) {
            throw error;
        } finally {
            setOverviewLoading(false);
        }
    };

    return (
        <Popup
            className="bl-popup"
            showTitle={true}
            title={`${label} opzoeken`}
            resizeEnabled={true}
            visible={true}
            height={"95vh"}
            width={"90vw"}
            showCloseButton={true}
            onHiding={(e) => { setActiveModal(false); e.cancel = true; }}
            fullScreen={false}
        >
            <div >
                {(loading || overviewLoading) && <Loading />}
                {!loading && !overviewLoading && (
                    <ListDataFetcher
                        inputListId={inputListId}
                        externalSelectFunction={handleSelectClick}
                        isModal={true}
                    />
                )}
            </div>

        </Popup>
    );
};

export default SelectSearchModal;
