import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

const Logout = () => {
    const { instance } = useMsal();
    console.log("Logout.js", instance);

    // @TODO after logging out, there is a page load. I don't know why. It's not needed either..
    useEffect(() => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => !BrowserUtils.isInIframe(),
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
        })
    }, [instance]);

    return (
        <div>Aan het uitloggen...</div>
    );
};

export default Logout;
