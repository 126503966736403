import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';

import "./fallbackComponent.scss";

const FallbackComponent = (e) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <main className="bl-error-wrapper">
            <section className="bl-card">
                <div className="content-wrapper">
                    <h3>Er is iets mis gegaan.</h3>
                    {/* <em>Je bent ingelogd als: {`${currentUser.email}`}</em> */}
                    <div className="bl-error-buttons-wrapper">
                        <Link to="/">
                            <div className="bl-btn bl-action-btn">
                                <i className="fas fa-tachometer-alt" />
                                <span className="btn-label">Ga naar het dashboard</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            {/* <Button
                text="Toon error"
                onClick={() => setShowModal(true)}
            />
            <Popup
                visible={showModal}
                onHiding={() => setShowModal(false)}
                dragEnabled={false}
                hideOnOutsideClick
                showCloseButton
                showTitle
                title="Error stack trace"
            >
                {e.error.stack}
            </Popup> */}
        </main>
    );
};

export default FallbackComponent;
