import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, TextArea, SelectBox } from "devextreme-react";
import { Popup } from 'devextreme-react/popup';
import React, { useState, useCallback, useEffect, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch";
import appSettings from "../../../appsettings";
import DataGrid, {
    Column,
    Editing,
    Lookup as DatagridLookup,
    RowDragging,
    ColumnFixing,
    Button
} from "devextreme-react/data-grid";
import { useGraphContext } from "../../graph/context";
import { format } from 'sql-formatter';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { Button as QueryButton } from 'devextreme-react/button';
import { PowerReportRepresentationType } from '../../../enums/powerReport.js';

const UpsertQueryVisual = ({
    editQueryVisualId,
    showPopup,
    refresh,
}) => {
    const [formData, setFormData] = useState(null);
    const [detailviewArray, setDetailviewArray] = useState(null);
    // const [enableSubmit, setEnableSubmit] = useState(true);
    const [columnSummaryTypeArray, setColumnSummaryTypeArray] = useState(null);
    const [columnDataTypeArray, setColumnDataTypeArray] = useState(null);
    const [columnAreaTypeArray, setColumnAreaTypeArray] = useState();
    const [columnNamesWithoutId, setColumnNamesWithoutId] = useState([]);
    const [columnNamesWithId, setColumnNamesWithId] = useState([]);
    const [powerReportRepresentationTypeArray, setPowerReportRepresentationTypeArray] = useState();

    const { currentUser } = useGraphContext();
    const isInsert = editQueryVisualId == null;
    // console.log("UpsertQueryVisual - enableSubmit", enableSubmit);

    const columnWidths = [
        { width: 1, displayWidth: "1/12" },
        { width: 2, displayWidth: "2/12" },
        { width: 3, displayWidth: "3/12" },
        { width: 4, displayWidth: "4/12" },
        { width: 5, displayWidth: "5/12" },
        { width: 6, displayWidth: "6/12" },
        { width: 7, displayWidth: "7/12" },
        { width: 8, displayWidth: "8/12" },
        { width: 9, displayWidth: "9/12" },
        { width: 10, displayWidth: "10/12" },
        { width: 11, displayWidth: "11/12" },
        { width: 12, displayWidth: "12/12" },
    ];

    const sortDirectionArray = [
        { name: 'Oplopend', value: 'asc' },
        { name: 'Aflopend', value: 'desc' }
    ];

    const defaultFormData = useMemo(() => {
        return {
            title: '',
            query: '',
            showChart: false,
            showPivotGrid: true,
            showFieldChooser: true,
            horizontalScrolling: false,
            powerReportRepresentationTypeId: PowerReportRepresentationType.Table,
            columns: [],
        }
    }, []);

    const insert = useCallback(async (e) => {
        formData.columns.forEach(cd => cd.id = null);
        try {
            await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/create`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formData),
                }
            );
            toast.success(`Power report '${formData.title}' aangemaakt`);
            if (refresh) {
                await refresh();
            }
            showPopup(false);
        } catch (error) {
            throw error;
        }
    }, [formData, showPopup, refresh]);

    const edit = useCallback(async (e) => {
        formData.columns.forEach(cd => cd.id = null);
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/edit`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formData),
                }
            );
            toast.success(`Power report '${formData.title}' bijgewerkt`);
            if (refresh) {
                await refresh();
            }
            console.log("showPopup(false)", showPopup);
            showPopup(false);
        } catch (error) {
            throw error;
        }
    }, [formData, showPopup, refresh]);

    const handleSave = useCallback(async (e) => {
        if (isInsert) {
            await insert(e);
        } else {
            await edit(e);
        }

    }, [isInsert, insert, edit]);

    const handleInputChange = useCallback((name) => (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: e.value,
        }));
    }, []);

    const handleQueryChange = useCallback((e) => {
        setFormData((prevData) => ({
            ...prevData,
            query: e.value,
        }));
    }, []);

    // const handleValueChange = useCallback((e) => {
    //     onControlUpdate(columnId, e.value || null);
    // }, [onControlUpdate, columnId]);


    const onHiding = useCallback(() => { showPopup(false); }, [showPopup]);

    useEffect(() => {
        const initializeQueryVisual = async () => {
            if (isInsert) {
                setFormData(defaultFormData);
            } else {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get/${editQueryVisualId}`);
                const json = await res.json();
                setFormData(json);
            }
        }
        initializeQueryVisual();

    }, [defaultFormData, isInsert, editQueryVisualId]);

    useEffect(() => {
        const loadDetailviews = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/detailview/list/${currentUser?.organisation.id}`);
            const json = await res.json();
            const detailViews = json.map(item => { return { name: item.table.tableName + ' - ' + item.name, id: item.id, tableName: item.table.tableName } });
            setDetailviewArray(detailViews);
        };
        loadDetailviews();
    }, [currentUser?.organisation.id]);

    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-column-summary-types`);
            const json = await res.json();
            setColumnSummaryTypeArray(json);
        };
        internalUseEffect();
    }, [])

    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-data-types`);
            const json = await res.json();
            setColumnDataTypeArray(json);
        };
        internalUseEffect();
    }, [])


    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-area-types`);
            const json = await res.json();
            setColumnAreaTypeArray(json);
        };
        internalUseEffect();
    }, [])

    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-representation-types`);
            const json = await res.json();
            setPowerReportRepresentationTypeArray(json);
        };
        internalUseEffect();
    }, []);

    const onEditingStart = useCallback(() => {
        //setEnableSubmit(false);
    }, [])

    const onEditCanceled = useCallback(() => {
        // setEnableSubmit(true);
    }, []);

    const onRowUpdated = useCallback(() => {
        // setEnableSubmit(true);
    }, [])

    const updateGridOrder = useCallback((area) => (e) => {
        const newIndex = e.toIndex;
        const oldIndex = e.fromIndex;
        const array = [...formData.columns];

        // Move the record in formData array based on newIndex
        const movedRecord = array.splice(oldIndex, 1)[0]; // Remove the record from the old position
        array.splice(newIndex, 0, movedRecord);
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        const result = { ...formData, columns: array }

        setFormData(result);
    }, [formData]);


    const onInitNewRow = useCallback(async () => {
        console.log("onInitNewRow");
        //setTimeout(() => setEnableSubmit((previousValue) => !previousValue), 1000);
        //setEnableSubmit((previousValue) => !previousValue);
    }, []);

    const onRowInserted = useCallback((area) => (e) => {
        let result = { ...formData };
        let array = formData.columns;

        array.forEach((item, index) => { item.seqNr = index; /*item.columnAreaId = columnAreaId*/ }); // Insert it at the new position
        result.columns = array;
        //setEnableSubmit(true);
    }, [formData])

    const onRowRemoved = useCallback((area) => (e) => {
        let result = { ...formData };
        let array = formData.columns;
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        result.columns = array;
    }, [formData]);

    const onFormatQueryClick = useCallback(() => {
        setFormData((previousFormData) => {
            const formattedQuery = format(previousFormData.query, { language: 'transactsql' });
            return {
                ...previousFormData,
                query: formattedQuery.replace(/\n/g, '\n '),
            };
        });
        toast.success("De query is opgeschoond");
    }, []);

    const fetchColumnsFromQuery = useCallback(async () => {
        if (formData?.query == null || formData?.query === '') {
            return;
        }
        const toastId = toast.loading('Kolommen ophalen...');
        const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-column-names-from-query/`,
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({ query: formData?.query }),
            }
        );
        if (!res.ok) {
            toast.error("Er is een fout opgetreden. Controleer de query.", {
                id: toastId,
            });
            return;
        }
        const json = await res.json();
        const columnNamesWithId = json.filter(column => column.toLowerCase().endsWith('_id'));
        const columnNamesWithoutId = json.filter(column => !column.toLowerCase().endsWith('_id'))

        setColumnNamesWithId(columnNamesWithId);
        setColumnNamesWithoutId(columnNamesWithoutId);
        setFormData((previousFormData) => {
            const columns = previousFormData.columns.map(column => {
                const fixedColumn = {
                    ...column,
                    datafield: columnNamesWithoutId.includes(column.datafield) ? column.datafield : null,
                    keyPropertyName: columnNamesWithId.includes(column.keyPropertyName) ? column.keyPropertyName : null
                };
                return fixedColumn;
            });
            //const newFormData = { ...formData, columns };
            return { ...previousFormData, columns };
        });
        toast.success("Velden zijn bijgewerkt voor selectie", {
            id: toastId,
        });
        //setFormData(newFormData);
    }, [formData?.query]);

    const onGetColumnsFromQueryClick = useCallback(async () => {
        await fetchColumnsFromQuery();
    }, [fetchColumnsFromQuery]);

    return (<Popup
        className="bl-popup"
        showTitle={true}
        animation={true}
        hideOnOutsideClick={true}
        title={`${isInsert ? 'Toevoegen' : 'Bijwerken'} Power Report`}
        resizeEnabled={true}
        visible={true}
        width={"95vw"}
        height={"95vh"}
        showCloseButton={true}
        onHiding={onHiding}
        // fullScreen={true}
        toolbarItems={[
            {
                toolbar: "bottom",
                widget: "dxButton",
                location: "after",
                options:
                {
                    icon: 'check',
                    //disabled: !enableSubmit,
                    text: 'Opslaan en sluiten',
                    useSubmitBehavior: true,

                    onClick: handleSave
                }
            }]}
    >
        {formData != null && <>
            <Row>
                <Col lg="3">
                    <h3 className="configheader">Titel</h3>
                </Col>
                <Col lg="9">
                    <TextBox
                        className="configheader"
                        type="input"
                        value={formData.title}
                        valueChangeEvent="keyup"
                        onValueChanged={handleInputChange('title')}
                    />
                </Col>
            </Row>
            <Row>
                <TabPanel
                    // className="bloom-tabpanel"
                    animationEnabled={true}
                    swipeEnabled={true}
                    tabsPosition={"top"}
                    iconPosition={"left"}
                    width="100%"
                    stylingMode={'primary'}
                >
                    <TabPanelItem title="Query" icon="fa-solid fa-database">
                        <div className="bl-card">
                            <Row>
                                <Col lg="12">
                                    <h3 className="configheader">SQL-query</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="2">
                                    <QueryButton
                                        className="configheader configbutton"
                                        stylingMode='contained'
                                        type="default"
                                        hint='Query opschonen'
                                        icon={'fa-solid fa-line-height'}
                                        onClick={onFormatQueryClick}
                                    />
                                    <QueryButton
                                        className="configheader configbutton"
                                        stylingMode='contained'
                                        type="default"
                                        hint='Kolommen ophalen en controle query'
                                        icon={'fa-solid fa-database'}
                                        onClick={onGetColumnsFromQueryClick}
                                    />
                                </Col>
                                <Col lg="7" className="configurator infolabel">
                                    <i class="fa-solid fa-lightbulb"></i>
                                    <ul>
                                        <li>Om dit report ook <strong>op een pagina</strong> te tonen voeg @tableItemId aan de WHERE toe. <em>' AND (@tableItemId is null OR Relaties.Id = @tableItemId)'</em></li>
                                        <li>Om naar een <strong>pagina te linken</strong>, neem je een id mee in de query en gebruik <strong>*_id</strong> aan het einde. <em>(allemaal lowercase, bijv. relatie_id, offerte_id, etc)</em></li>
                                    </ul>
                                </Col>
                            </Row>
                            <Col lg="12">
                                <TextArea
                                    value={formData.query}
                                    valueChangeEvent="input"
                                    minHeight={"20vh"}
                                    maxHeight={"55vh"}
                                    autoResizeEnabled={true}
                                    onValueChanged={handleQueryChange}
                                    spellcheck={false}
                                />
                            </Col>
                        </div>
                    </TabPanelItem>
                    <TabPanelItem title="Weergave" icon="fas fa-table config">
                        <div className="bl-card">
                            <Row>
                                <Col lg="3">
                                    <h3 className="configheader">Type weergave</h3>
                                </Col>
                            </Row >
                            <Row >
                                <Col lg="2">

                                    <SelectBox
                                        searchEnabled
                                        items={powerReportRepresentationTypeArray}
                                        valueExpr="id"
                                        displayExpr="name"
                                        showClearButton={false}
                                        onValueChanged={handleInputChange('powerReportRepresentationTypeId')}
                                        defaultValue={1}
                                        value={formData.powerReportRepresentationTypeId}
                                    />
                                </Col>
                            </Row>
                            <Row>

                                <Col lg="12">
                                    <h3 className="configheader">Inrichting</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    {/* <CheckBox
                                        className="configlabel"
                                        text="Toon draaitabel"
                                        value={formData.showPivotGrid}
                                        onValueChanged={handleInputChange('showPivotGrid')}
                                        readOnly={true}
                                    /> */}

                                    <CheckBox
                                        className="configlabel"
                                        text="Toon grafiek"
                                        value={formData.showChart}
                                        onValueChanged={handleInputChange('showChart')}
                                    />

                                    <CheckBox
                                        className="configlabel"
                                        text="Toon kolomkiezer"
                                        value={formData.showFieldChooser}
                                        onValueChanged={handleInputChange('showFieldChooser')}
                                    />
                                    <CheckBox
                                        className="configlabel"
                                        text="Horizontaal scrollen"
                                        value={formData.horizontalScrolling}
                                        onValueChanged={handleInputChange('horizontalScrolling')}
                                    />

                                    <QueryButton
                                        className="configheader configbutton"
                                        stylingMode='contained'
                                        type="default"
                                        hint='Kolommen ophalen en controle query'
                                        icon={'fa-solid fa-database'}
                                        onClick={onGetColumnsFromQueryClick}
                                    />

                                </Col>
                                <Col lg="5" className="configurator infolabel">
                                    <i class="fa-solid fa-lightbulb"></i>
                                    <ul>
                                        <li>Voor het type <strong>tabel</strong>, kun je enkel het <strong>gebied kolom</strong> gebruiken</li>
                                        <li>Een <strong>grafiek</strong> kun je enkel bij een draaitabel laten tonen</li>
                                    </ul>
                                </Col>
                                <Col lg="12">
                                    <DataGrid
                                        dataSource={formData.columns}
                                        showBorders={true}
                                        hoverStateEnabled={true}
                                        showRowLines={false}
                                        showColumnLines={true}
                                        allowColumnResizing
                                        allow
                                        columnResizingMode="widget"
                                        rowAlternationEnabled={true}
                                        keyExpr="id"
                                        width="auto"
                                        onRowInserted={onRowInserted('row')}
                                        onRowRemoved={onRowRemoved('row')}
                                        onEditingStart={onEditingStart}
                                        onEditCanceled={onEditCanceled}
                                        onRowUpdated={onRowUpdated}
                                        onInitNewRow={onInitNewRow}
                                    >
                                        <ColumnFixing
                                            enabled
                                        >
                                        </ColumnFixing>
                                        <Column caption="Veld" dataField="datafield">
                                            <DatagridLookup
                                                dataSource={columnNamesWithoutId}
                                            />
                                        </Column>
                                        <Column caption="Weergavenaam" dataField="caption" minWidth="150" />
                                        <Column caption="Gebied" dataField="columnAreaId" >
                                            <DatagridLookup
                                                dataSource={columnAreaTypeArray}
                                                valueExpr="id"
                                                displayExpr="name"

                                            />
                                        </Column>
                                        <Column caption="Aggregatie" dataField="columnSummaryTypeId" >
                                            <DatagridLookup
                                                dataSource={columnSummaryTypeArray}
                                                valueExpr="id"
                                                displayExpr="name"
                                                allowClearing={true}
                                            />
                                        </Column>
                                        <Column caption="Weergave" dataField="columnDataTypeId" >
                                            <DatagridLookup
                                                dataSource={columnDataTypeArray}
                                                valueExpr="id"
                                                displayExpr="name"
                                            />
                                        </Column>
                                        <Column caption="Sorteerrichting" dataField="sortDirection" >
                                            <DatagridLookup
                                                dataSource={sortDirectionArray}
                                                valueExpr="value"
                                                displayExpr="name"
                                            />
                                        </Column>
                                        <Column
                                            dataField='expanded'
                                            caption='Uitgeklapt'
                                            dataType="boolean"
                                            falseText="Nee"
                                            trueText="Ja"
                                        />
                                        <Column caption="Paginalink" dataField="detailviewId">
                                            <DatagridLookup
                                                allowClearing={true}
                                                dataSource={detailviewArray}
                                                valueExpr="id"
                                                displayExpr="name"
                                                group="tableName"

                                            />
                                        </Column>
                                        <Column caption="PaginaID" dataField="keyPropertyName">
                                            <DatagridLookup
                                                allowClearing={true}
                                                dataSource={columnNamesWithId}
                                            />
                                        </Column>
                                        <Column
                                            caption="Breedte"
                                            dataType="number"
                                            defaultValue={2}
                                            dataField="width"
                                        >
                                            <DatagridLookup
                                                dataSource={columnWidths}
                                                valueExpr="width"
                                                displayExpr="displayWidth"
                                            />
                                        </Column>
                                        <Column
                                            type="buttons"
                                            caption="Acties"
                                            width={60}
                                            fixedColumn
                                            fixed
                                            fixedPosition="right"
                                        >
                                            <Button name="edit" />
                                            <Button name="delete" />
                                        </Column>
                                        <Editing
                                            mode="row"
                                            allowUpdating={true}
                                            allowAdding={true}
                                            allowDeleting={true}
                                            useIcons={true}
                                        />
                                        <RowDragging
                                            allowReordering={true}
                                            onReorder={updateGridOrder('row')}
                                            showDragIcons={true}
                                            autoScroll={true} />
                                    </DataGrid>
                                </Col>
                            </Row>
                        </div>
                    </TabPanelItem>
                </TabPanel>
            </Row>
        </>}
        {!formData && <div>Laden..</div>}
    </Popup >)
}

export default UpsertQueryVisual;
