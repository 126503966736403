import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { CheckBox, NumberBox, SelectBox } from "devextreme-react";
import { columnDefaultValueDate } from "../../../../enums/columnDefaultValue"

const Datum = ({
    fieldValue,
    setFieldValue,
    checkChanges,
    isCreate,
    setData,
}) => {
    const [dateSpecification, setDateSpecification] = useState(false);
    const [specification, setSpecification] = useState();
    const [refresh, setRefresh] = useState(false);

    const [validateAgainstColumns, setValidateAgainstColumns] = useState([
        { id: 0, name: "..." },
    ]);
    const [indication, setIndication] = useState();
    const dateSpecificationOptions = [{ v: "D", d: "Dagen" }];
    const _dateDefaultValueNoneId = columnDefaultValueDate.None;
    const _dateDefaultValueTodayId = columnDefaultValueDate.Today;

    useEffect(() => {
        let tempsetData =
            setData &&
            setData.filter((t) => t.type === "date" && t.id !== fieldValue.id)
                .length > 0 &&
            setData.filter((t) => t.type === "date" && t.id !== fieldValue.id);

        let validationArray = validateAgainstColumns;
        if (tempsetData.length > 0) {
            tempsetData.map((t) => validationArray.push(t));
        }
        setValidateAgainstColumns(validationArray);
        setRefresh(!refresh);
    }, []);

    useEffect(() => {
        let tempFieldValue = fieldValue;
        let columnDefaultValueObject = {
            id: columnDefaultValueDate.None,
            specification: null,
            indication: null,
        };

        if (
            fieldValue.columnDefaultValue &&
            fieldValue.defaultValue &&
            fieldValue.columnDefaultValue.name !== "Geen"
        ) {
            let indication = fieldValue.defaultValue.slice(-1);
            let defaultString = fieldValue.defaultValue;
            let result = defaultString.split("+");
            defaultString =
                result[1] && result[1].includes("D") ? result[1].replace("D", "") : "";

            columnDefaultValueObject = {
                id: fieldValue.columnDefaultValue.id,
                name: fieldValue.columnDefaultValue.name,
                specification: defaultString,
                specificationIndicator: indication,
            };

            setIndication(indication);
            setSpecification(defaultString);

            setDateSpecification(true);
        } else {
            setDateSpecification(false);
        }

        tempFieldValue["columnDefaultValue"] = columnDefaultValueObject;
        setFieldValue(tempFieldValue);
    }, [fieldValue, setFieldValue]);

    const checkDateSpecification = (value) => {
        const target = fieldValue.columnType.columnDefaultValues.filter(
            (item) => value === item.id
        );
        if (target[0].name === "Geen") {
            let tempFieldValue = fieldValue;
            tempFieldValue.columnDefaultValue = {
                id: value,
                name: "Geen",
                specification: 0,
                specificationIndicator: null,
            };
            setIndication(null);
            setSpecification(null);
            tempFieldValue["defaultValue"] = "";

            setDateSpecification(false);
        } else {
            setDateSpecification(true);
        }
    };
    return (
        <>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Alleen lezen</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={
                                !isCreate && fieldValue.readonly ? fieldValue.readonly : false
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["readonly"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Verplicht</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={
                                !isCreate && fieldValue.mandatory ? fieldValue.mandatory : false
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["mandatory"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Standaard waarde</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            //className={isCreate && "bl-mandatory-field"}
                            defaultValue={isCreate ? _dateDefaultValueNoneId : (fieldValue.columnDefaultValue == null || fieldValue.columnDefaultValue.id == null ? _dateDefaultValueNoneId : _dateDefaultValueTodayId)}
                            items={fieldValue.columnType.columnDefaultValues}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue.columnDefaultValue["id"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                                checkDateSpecification(e.value);
                                e.element.classList.remove("bl-mandatory-field");
                            }}
                        />

                        {dateSpecification && (
                            <section className="date-specification-section">

                                <div className="date-specification-column">

                                    <Form.Label>Plus</Form.Label>
                                    <NumberBox
                                        defaultValue={fieldValue.columnDefaultValue.specification}
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            tempFieldValue.columnDefaultValue["specification"] =
                                                e.value;

                                            tempFieldValue.defaultValue = `GETDATELOCAL()+${e.value}${indication}`;
                                            setSpecification(e.value);
                                            setFieldValue(tempFieldValue);
                                            checkChanges();
                                        }}
                                    />
                                </div>

                                <div className="date-specification-column">
                                    <Form.Label>Aantal</Form.Label>
                                    <SelectBox
                                        searchEnabled
                                        defaultValue={
                                            fieldValue.columnDefaultValue.specificationIndicator
                                        }
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            tempFieldValue.columnDefaultValue[
                                                "specificationIndicator"
                                            ] = e.value;
                                            tempFieldValue.defaultValue = `GETDATELOCAL()+${specification}${e.value}`;
                                            setIndication(e.value);
                                            setFieldValue(tempFieldValue);
                                            checkChanges();
                                        }}
                                        items={dateSpecificationOptions}
                                        valueExpr="v"
                                        displayExpr="d"
                                    />
                                </div>
                            </section>
                        )}
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Weergave</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            className={isCreate && "bl-mandatory-field"}
                            defaultValue={
                                !isCreate &&
                                fieldValue.columnDisplayValue.id &&
                                fieldValue.columnDisplayValue.id
                            }
                            items={fieldValue.columnType.columnDisplayValues}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;

                                if (isCreate) {
                                    tempFieldValue.columnDisplayValue = { id: "" };
                                    tempFieldValue.columnDisplayValue.id = e.value;
                                } else {
                                    tempFieldValue["columnDisplayValue"] =
                                        fieldValue.columnType.columnDisplayValues.filter(
                                            (item) => e.value === item.id
                                        )[0];
                                }

                                setFieldValue(tempFieldValue);
                                checkChanges();
                                e.element.classList.remove("bl-mandatory-field");
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Groter of gelijk aan waarde van kolom</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            key={refresh}
                            dataSource={validateAgainstColumns}
                            displayExpr="name"
                            valueExpr="id"
                            defaultValue={
                                fieldValue.validateAgainstColumnId &&
                                fieldValue.validateAgainstColumnId
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["validateAgainstColumnId"] = e.value;
                                if (e.value === 0) {
                                    tempFieldValue["validateAgainstColumnId"] = null;
                                }
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
};

export default Datum;
