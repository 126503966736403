import { CheckBox, DataGrid } from "devextreme-react";
import {
  Column,
  Lookup,
  FilterRow,
  Paging,
  Pager,
  HeaderFilter,
  LoadPanel,
  ColumnFixing,
  Scrolling,
  Editing
} from "devextreme-react/data-grid";
import React, { useState, useEffect, useRef } from "react";

import Loading from "../../../loading/Loading";

const DetailviewsGrid = ({ checkChanges, form, setForm, detailviews }) => {
  const [loading, setLoading] = useState(true);
  const dataGridRef = useRef();
  const [detailviewGrid, setDetailviewGrid] = useState([]);

  useEffect(() => {
    setLoading(true);

    let tempDetailViews = form.detailview;
    let richDetailviews = [];
    let tempForm = form;

    detailviews.map((item) => {
      if (tempDetailViews.find((detailview) => detailview.id === item.id)) {
        let target = tempDetailViews.find(
          (detailview) => detailview.id === item.id
        );
        target["tableName"] = item.table.tableName;
        target["detailviewName"] = item.name;
        richDetailviews.push(target);
      } else {
        richDetailviews.push({
          id: item.id,
          visible: 0,
          tableName: item.table.tableName,
          detailviewName: item.name,
          defaultOverview: 0,
        });
      }
      return richDetailviews;
    });

    setDetailviewGrid(richDetailviews);

    tempForm["detailview"] = richDetailviews;

    setForm(tempForm);

    setLoading(false);
  }, []);

  const inlineCheckBox = (data) => {
    let value = data.value;

    if (value === 1) {
      value = true;
    } else {
      value = false;
    }

    let readOnlyCheck;
    if (data.data.readonly === 1 && data.column.dataField !== "read") {
      readOnlyCheck = true;
    } else {
      readOnlyCheck = false;
    }

    return (
      <CheckBox
        defaultValue={value}
        readOnly={readOnlyCheck}
        onValueChanged={(e) => {
          let binair;
          if (e.value) {
            binair = 1;
          } else {
            binair = 0;
          }
          let tempForm = form;
          tempForm.detailview.find((t) => t.id === data.data.id)[
            data.column.dataField
          ] = binair;
          checkChanges();
          setForm(tempForm);
        }}
      />
    );
  };

  const checkAllChange = (value, target) => {
    let binair;
    if (value) {
      binair = 1;
    } else {
      binair = 0;
    }
    let tempForm = form;
    let tempDetailviews = [];
    tempForm.detailview.map((item) => {
      let tempItem = item;

      tempItem[target] = binair;
      return tempDetailviews.push(tempItem);
    });

    tempForm["detailview"] = tempDetailviews;
    setForm(tempForm);
    dataGridRef.current.instance().refresh();
    checkChanges();
  };
  const readCellHeader = () => {
    return (
      <div className="bl-grid-select-all">
        <span>
          <CheckBox
            defaultValue={false}
            onValueChanged={(e) => {
              checkAllChange(e.value, "visible");
            }}
          />
          <p>Alles selecteren</p>
        </span>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <DataGrid
        ref={dataGridRef}
        dataSource={detailviewGrid}
        columnAutoWidth={true}
        showColumnLines={true}
        rowAlternationEnabled={true}
        showRowLines={true}

      >
        <Paging defaultPageSize={50} />
        <Pager
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <ColumnFixing enabled={true} />
        <LoadPanel enabled={true} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Scrolling mode="standard" />
        <FilterRow visible={true} showAllText="Alles" />
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={false}
        />
        <Column
          alignment="left"
          caption="Tabel"
          dataField="tableName"
          dataType="string"
          sortOrder="asc"
          allowSorting={true}
        />

        <Column
          alignment="left"
          caption="Pagina"
          dataField="detailviewName"
          dataType="string"
          allowSorting={true}
        />

        <Column caption="Lezen" alignment="center" allowSorting={false}>
          <Column
            dataField="visible"
            headerCellRender={readCellHeader}
            dataType="boolean"
            cellRender={inlineCheckBox}
            allowSorting={false}
          />
        </Column>
      </DataGrid>
    );
  }
};

export default DetailviewsGrid;
