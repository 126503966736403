import { DataGrid } from "devextreme-react";
import appSettings from "../../../appsettings";
import CustomStore from "devextreme/data/custom_store"
import DataSource from 'devextreme/data/data_source';
import {
  Column,
  FilterRow,
  Scrolling,
  Toolbar,
  Editing,
  Item,
  Texts,
  Button
} from "devextreme-react/data-grid";
import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import Loading from "../../loading/Loading";
import { useModalContext } from "../../../context/modalContext";
import BloomFooter from "../../footer/BloomFooter";
import TabPanel from 'devextreme-react/tab-panel';
import RoleTable from "./RoleTable.js"
import RoleDetailview from "./RoleDetailview.js";
import RoleOverview from "./RoleOverview.js";
import RoleUser from "./RoleUser.js";
import RoleScheduler from "./RoleScheduler.js";
import RolePowerReport from "./RolePowerReport.js";
import { Button as ToolbarButton } from 'devextreme-react/button';
import toast from "react-hot-toast";
import { Container, Col, Row } from "react-bootstrap";


const Roles = () => {
  const [loading, setLoading] = useState(true);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(null);


  const tabs2 = [
    {
      icon: 'fas fa-users config',
      title: 'Gebruikers',
    },
    {
      icon: 'fas fa-table config',
      title: 'Tabellen',
    },
    {
      icon: 'fas fa-file config',
      title: 'Pagina\'s',
    },
    {
      icon: 'fas fa-list config',
      title: 'Lijsten',
    },
    {
      icon: 'fas fa-calendar config',
      title: 'Agenda\'s',
    },
    {
      icon: 'fas fa-table-columns config',
      title: 'Power Reports',
    },
  ];

  const refRoles = useRef();

  const {
    setIsEditing,
    setModal,
    isEditing,
    deleteProceedNotification,
  } = useModalContext();

  const getRoles = useCallback(async () => {
    try {
      const response = await msalFetch(null,
        `${appSettings.api.endpoint}/api/configurator/role`
      );
      const data = await response.json();
      data.forEach(role => role.nrOfUsers = role.user == null ? 0 : role.user.filter(user => user.active).length);
      //setRoles(data);
      setLoading(false);

      return data;

    } catch (error) {
      setLoading(false);

    }
  }, []);

  const handleAddClick = () => {
    setIsEditing(true);
    setModal("ADDROLE");
  };

  useEffect(() => {
    if (!deleteProceedNotification) {
      getRoles();
    }
  }, [deleteProceedNotification, getRoles]);

  const handleRowClick = (rowData) => {
    if (rowData.row.key === selectedRoleId) {
        setSelectedRoleId(null);
        refRoles.current.instance().option('focusedRowKey', null);
        return null;
    }
    setSelectedRoleId(rowData.row.key);
  };

  // DO NOT DO THIS::
  // This gets unmounted and re-mounted every render
  //  - Laurens
  const itemComponent = ({ value }) => {
    return (
      <div>
        {selectedTabIndex === 0 && (<RoleUser refresh={refresh} roleId={selectedRoleId} />)}
        {selectedTabIndex === 1 && (<RoleTable roleId={selectedRoleId} />)}
        {selectedTabIndex === 2 && (<RoleDetailview roleId={selectedRoleId} />)}
        {selectedTabIndex === 3 && (<RoleOverview roleId={selectedRoleId} />)}
        {selectedTabIndex === 4 && (<RoleScheduler roleId={selectedRoleId} />)}
        {selectedTabIndex === 5 && (<RolePowerReport roleId={selectedRoleId} />)}
      </div>
    );
  };

  useEffect(() => {
    getRoles();
  }, [isEditing, getRoles]);

  useEffect(() => {
    if (!deleteProceedNotification) {
      getRoles();
    }
  }, [deleteProceedNotification, getRoles]);

  const refresh = useCallback(() => {
    //getRoles();
    refRoles.current.instance().getDataSource().reload();
  }, []);

  const updateRole = useCallback(async (id, valuePair) => {
    try {
      const res = await msalFetch(null,
        `${appSettings.api.endpoint}/api/configurator/role/update-role-name/${id}`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({ roleName: valuePair.name }),
        }
      );
    } catch (error) {
      toast.error("Er is iets misgegaan");
      throw error;
    }
  }, []);

  const onRowRemoving = useCallback((e) => {

    if (e.data.nrOfUsers > 0) {
      e.cancel = true;
      toast.error("Rol heeft gebruikers en kan niet verwijderd worden")
    }

  }, []);

  const deleteRole = useCallback(async (id) => {

    try {
      const res = await msalFetch(null,
        `${appSettings.api.endpoint}/api/configurator/role/${id}`,
        {
          method: "DELETE",
          headers: { "Content-type": "application/json" }
        }
      );
    } catch (error) {
      toast.error("Er is iets misgegaan");
      throw error;
    }
  }, []);

  const dataSource = useMemo(() => new DataSource({
    store: new CustomStore({
      key: 'id',
      load: getRoles,
      update: updateRole,
      remove: deleteRole,
    })
  }), [getRoles, updateRole, deleteRole]);

  return (
    <main className="bl-content">
      <header>
        <h2>Rollen & rechten</h2>
      </header>
      {loading && <Loading />}
      {!loading && (
        <Container fluid
          className={"bl-detailview-content"}>
          <section >
            <Row className="bl-detail-row">
              <Col
                lg={3}
                className={`bl-col-padding`}
              >
                <section className="bl-detail-column bl-card">
                  <h3 className="bl-set-title" >
                    Rol
                  </h3>
                  <section className="propertyWrapper" >
                    <DataGrid
                      dataSource={dataSource}
                      rowAlternationEnabled={true}
                      showColumnLines={false}
                      hoverStateEnabled={true}
                      showBorders
                      showRowLines={true}
                      focusedRowEnabled={true}
                      className="sorting-disabled"
                      keyExpr="id"
                      ref={refRoles}
                      onRowRemoving={onRowRemoving}
                      onCellClick={handleRowClick}
                    >
                      <FilterRow visible={true} />
                      <Scrolling mode="virtual" />
                      <Toolbar>
                        <Item location="after">
                          <ToolbarButton
                            icon="fas fa-plus"
                            onClick={handleAddClick}
                          />
                        </Item>
                      </Toolbar>
                      <Column
                        caption="Rolnaam"
                        dataField="name"
                        dataType="string"
                        defaultSortOrder="asc"
                        allowSorting={true}

                      />
                      <Column
                        allowSearch={false}
                        filter
                        headerFilter={false}
                        caption="Users"
                        dataField="nrOfUsers"
                        allowEditing={false}
                        allowSorting={true}
                      />
                      <Column
                        type="buttons"
                        caption={"Acties"}>
                        {/* <Button hint="Ga naar pagina" name="select" cssClass="primaryAccent" icon="fas fa-arrow-right" onClick={handleRowClick} /> */}
                        <Button
                          hint="Verwijder dit record"
                          name="delete"
                          icon="trash"
                          cssClass="alert"
                        />
                      </Column>
                      <Editing
                        mode="cell"
                        startEditAction="dblClick"
                        confirmDelete={true}
                        allowDeleting={true}
                        allowUpdating={true}
                        useIcons={true}
                      >
                        <Texts
                          confirmDeleteTitle="Item verwijderen"
                          confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
                        />
                      </Editing>
                    </DataGrid>
                    <br></br>
                    <section className="configurator infolabel">
                      <i class="fa-solid fa-lightbulb bl-tip-icon"></i>
                      Klik op een rol om de rechten in te te stellen
                    </section>
                  </section>
                </section>
              </Col>
              <Col lg={9}
                className={`bl-col-padding`}>
                <section className="bl-detail-column bl-card">
                  <h3 className="bl-set-title" >
                    Gebruikers & toegang
                  </h3>
                  <section className="propertyWrapper" >
                    <TabPanel
                      className="bloom-tabpanel"
                      dataSource={tabs2}
                      animationEnabled={true}
                      swipeEnabled={true}
                      defaultSelectedIndex={0}
                      tabsPosition={"top"}
                      iconPosition={"left"}
                      selectedIndex={selectedTabIndex}
                      onOptionChanged={(e) => {
                        if (e.name === 'selectedIndex') {

                          setSelectedTabIndex(e.value);
                        }
                      }}
                      width="100%"

                      stylingMode={'primary'}

                      itemComponent={itemComponent}
                    >
                    </TabPanel>
                  </section>
                </section>
              </Col>
            </Row>
          </section>
        </Container>
      )
      }

      <BloomFooter />

    </main >
  );
};

export default Roles;
