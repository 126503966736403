import { DataGrid } from "devextreme-react";
import { Helmet } from "react-helmet";
import {
    Column,
    Lookup,
    FilterRow,
    Paging,
    Pager,
    HeaderFilter,
    LoadPanel,
    ColumnFixing,
    Scrolling,
    Editing
} from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react";
import React, { useCallback, useState, useEffect, useRef } from "react";
import Loading from "../../../loading/Loading";

const SchedulerGrid = (/* {
    checkChanges,
    form,
    setForm,
    overviews,
    overviewLoading,
} */
    { checkChanges,
        form,
        setForm,
        schedulers,
        schedulerLoading,
        tables
    }
) => {
    const schedulerGridRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let tempLists = [];
        let tempForm = form;

        if (schedulerLoading) {
            return;
        } else if (!form || !schedulers) {
            return;
        } else {
            if (schedulers.length > 0) {
                schedulers.map(item => {
                    let target = { title: item.title };
                    const hasRights = form.scheduler.find(scheduler => scheduler.id === item.id) != null;
                    target.id = item.id;
                    target.hasRights = /* true */  hasRights;
                    tempLists.push(target);
                });


                tempForm["scheduler"] = tempLists;
                setForm(tempForm);
                setLoading(false);
            } else {
                // setLoading(false);
                // setInvalidOverviews(true);
            }
        }
    }, [schedulerLoading, form, schedulers, setForm]);

    const onRowUpdated = useCallback((e) => {
        //const scheduler = form.scheduler.find(item => item.title === e.key.title);
        // scheduler.hasRights = e.newData.hasRights;
        const schedulerId = schedulers.find(s => s.title == e.key.title).id;
        let tempForm = form;
        tempForm.scheduler.find(item => item.title === e.key.title).hasRights = e.newData.hasRights;
        // tempForm.hasRights = e.newData.hasRights;
        setForm(tempForm);
        checkChanges();
    }, [form, setForm, checkChanges, schedulers]);

    if (loading) {
        return <Loading />;
    } else {
        return <>
            <Helmet>
                <title>
                    {"HC"}
                </title>
            </Helmet>
            <DataGrid
                dataSource={form.scheduler}
                ref={schedulerGridRef}
                columnAutoWidth={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                showRowLines={true}
                wordWrapEnabled={true}
                onRowUpdating={onRowUpdated}
            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                />
                <Column
                    caption="Naam"
                    dataType="string"
                    dataField="title"
                />
                <Column
                    // key={key}
                    // dataField={name.toLowerCase()}
                    caption="Zichtbaar"
                    // fixed={true}
                    allowSearch={false}
                    dataType="boolean"
                    allowEditing={true}
                    dataField="hasRights"
                />

            </DataGrid>
        </>
    }
}

export default SchedulerGrid;
