import { useGraphContext } from "../graph/context.js";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import { useParams } from "react-router-dom";
import SchedulerDataStore from './SchedulerDataStore.js';

const SchedulerDataFetcher = (
) => {
    const { currentUser } = useGraphContext();
    let { schedulerId } = useParams();
    const [renderGrid, setRenderGrid] = useState();
    const [title, setTitle] = useState();
    const [users, setUsers] = useState();
    const [filterOnOnlineUser, setFilterOnOnlineUser] = useState();
    const [usersOfScheduler, setUsersOfScheduler] = useState();
    const [preferredDetailViewId, setPreferredDetailViewId] = useState();
    const [cellDuration, setCellDuration] = useState();
    const [startDayHour, setStartDayHour] = useState();
    const [endDayHour, setEndDayHour] = useState();
    const [tableId, setTableId] = useState();
    const [draggableSelectFieldId, setDraggableSelectFieldId] = useState(0);
    const [draggableSelectFieldAlias, setDraggableSelectFieldAlias] = useState("");
    const [draggableDetailViewId, setDraggableDetailViewId] = useState(0);
    const [defaultAppointmentLength, setDefaultAppointmentLength] = useState(0);
    const [draggableItems, setDraggableItems] = useState(null);
    useEffect(() => {
        // make sure we unmount the grid, when we switch to another one
        setRenderGrid(false);
        // reset the inline edit mode when switching to different screen
        //setInlineEditMode(false);
    }, []);

    // WARNING: this effect can run twice in develop because of StrictMode
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
                }
                //setRenderGrid(true);

                setTitle(data.title);
                setFilterOnOnlineUser(data.filterOnLoggedInUser);
                setUsersOfScheduler(data.schedulerUsers);
                setPreferredDetailViewId(data.preferredDetailViewId);
                setCellDuration(data.cellDuration);
                setStartDayHour(data.startDayHour);
                setEndDayHour(data.endDayHour);
                setTableId(data.tableId);
                setDraggableSelectFieldId(data.draggableSelectFieldId);
                setDraggableSelectFieldAlias(data.draggableSelectFieldAlias);
                setDraggableDetailViewId(data.draggableDetailViewId);
                setDefaultAppointmentLength(data.defaultAppointmentLength);
            } catch (error) {
                // @TODO: do something...
                throw error;
            }
        };
        getData();
        //getUsers();
    }, [schedulerId]);

    const getUsers = useCallback(async () => {
        const response = await msalFetch(null, `${appSettings.api.endpoint}/api/user/getclientusers/null`, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify({ searchValue: null }),
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`[SchedulerDataFetcher] getUsers response was not ok: ${data.message}`);
        }
        setUsers(data);
    }, []);

    const getDraggableItems = useCallback(async () => {
        if (schedulerId == null || draggableSelectFieldId === 0 || (Array.isArray(draggableItems) && draggableItems.length === 0))
            return;
        if (draggableSelectFieldId == null) {
            setDraggableItems([]);
            return;
        }
        const response = await msalFetch(null, `${appSettings.api.endpoint}/api/scheduler/get-draggable-items/${schedulerId}`);
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`[SchedulerDataFetcher] getDraggableItems response was not ok: ${data.message}`);
        }
        setDraggableItems(data)
    }, [schedulerId, draggableSelectFieldId]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        getDraggableItems();
    }, [getDraggableItems]);

    useEffect(() => {
        if (title != null && users != null && filterOnOnlineUser != null && usersOfScheduler != null && currentUser != null && preferredDetailViewId != null && tableId != null && draggableSelectFieldId !== 0 && draggableItems != null && draggableSelectFieldAlias !== "" && draggableDetailViewId !== 0 && defaultAppointmentLength !== 0) {
            setRenderGrid(true);
        } else {
        }
    }, [title, users, filterOnOnlineUser, usersOfScheduler, currentUser, preferredDetailViewId, cellDuration, startDayHour, endDayHour, tableId, draggableSelectFieldId, draggableItems, draggableSelectFieldAlias, draggableDetailViewId, defaultAppointmentLength]);

    if (!renderGrid) {
        return null;
    }
    return (
        <SchedulerDataStore
            schedulerId={schedulerId}
            title={title}
            users={users.filter(item => item.id === currentUser?.id || usersOfScheduler.includes(item.id))}
            usersOfScheduler={usersOfScheduler}
            filterOnOnlineUser={filterOnOnlineUser}
            preferredDetailViewId={preferredDetailViewId}
            cellDuration={cellDuration}
            startDayHour={startDayHour}
            endDayHour={endDayHour}
            tableId={tableId}
            draggableSelectFieldId={draggableSelectFieldId}
            draggableItems={draggableItems}
            draggableSelectFieldAlias={draggableSelectFieldAlias}
            getDraggableItems={getDraggableItems}
            draggableDetailViewId={draggableDetailViewId}
            defaultAppointmentLength={defaultAppointmentLength}
        />
    );
};

export default SchedulerDataFetcher;
