import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../../appsettings.js"
import msalFetch from "../../../../api/MsalFetch.js";
import PopupEditScheduler from "../../../../../src/components/modals/configurator/scheduler/PopupEditScheduler.js"
import { VisualType } from "../../../../enums/visuals.js"
import { Button as ToolbarButton } from 'devextreme-react/button';
import DataGrid, {
    Column,
    Button as DataGridButton,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import { confirm } from 'devextreme/ui/dialog';
import toast from "react-hot-toast";

const VisualsGrid = ({ tableId }) => {
    const [listDataSource, setListDataSource] = useState(null);
    const [showEditScheduler, setShowEditScheduler] = useState(false);
    const [showAddScheduler, setShowAddScheduler] = useState(false);
    const [selectedSchedulerId, setSelectedSchedulerId] = useState();

    const loadVisualsData = useCallback(async () => {
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/visuals/list/${tableId}`);
            //const res = await msalFetch(null, `${appSettings.api.endpoint}/api/visuals/list/`);
            const data = await res.json();
            setListDataSource(data);

        } catch (error) {
            throw error;
        }
    }, [tableId])

    useEffect(() => {
        loadVisualsData()
    }, [loadVisualsData]);

    const refresh = useCallback(() => {
        loadVisualsData();
    }, [loadVisualsData])

    const onAddSchedulerClick = useCallback((e) => {
        setShowAddScheduler(true);
    }, [])

    const handleEdit = useCallback((typeId, itemId) => {
        switch (typeId) {
            case VisualType.Scheduler: {
                setShowEditScheduler(true);
                setSelectedSchedulerId(itemId);
                break;
            }
            default:
                break;
        }
    }, [])

    const onDeleteScheduler = useCallback(async (e) => {
        let result = await confirm("Weet u zeker dat u deze agenda wilt verwijderen?", "Agenda verwijderen?");
        if (!result) {
            return;
        }
        const url = `${appSettings.api.endpoint}/api/scheduler/delete/${e.row.key.itemId}`;
        const res = await msalFetch(null,
            url,
            {
                method: "DELETE",
                headers: { "Content-type": "application/json" }
            }
        );
        const data = await res.json();
        if (data.success) {
            toast.success("De agenda is verwijderd");
            loadVisualsData()
        } else {
            toast.error("Er is iets misgegaan");
        }
    }, [loadVisualsData])

    const onEditClick = useCallback((e) => {
        handleEdit(e.row.key.typeId, e.row.data.itemId);
    }, [handleEdit]);

    const handleRowClick = useCallback((e) => {
        handleEdit(e.data.typeId, e.data.itemId);
    }, [handleEdit]);


    return (<div><DataGrid
        dataSource={listDataSource}
        showBorders={false}
        showRowLines={true}
        showColumnLines={false}
        rowAlternationEnabled={true}
        onRowClick={handleRowClick}
        width="100%"
    >
        {/* <Column dataType="string" caption="Type" dataField="Type" /> */}
        <Toolbar>
            <Item location="after" locateInMenu="auto">
                <ToolbarButton
                    icon="fas fa-calendar-days"
                    // stylingMode={inlineEditMode ? "contained" : "outlined"}
                    type="default"
                    hint={`Toevoegen agenda`}
                    onClick={onAddSchedulerClick}
                // accessKey="b"
                />
            </Item>
        </Toolbar>
        <Column dataType="string" caption="Naam" dataField="title" />
        <Column dataType="string" caption="Omschrijving" dataField="description" />
        <Column type="buttons">
            <DataGridButton hint="Bewerk dit record" name="update" cssClass="primaryAccent" icon="fas fa-pen-to-square" onClick={onEditClick} />
            <DataGridButton hint="Verwijder dit record" icon="trash" /* cssClass="alert" */ onClick={onDeleteScheduler} />
        </Column>
    </DataGrid>
        {showEditScheduler && <PopupEditScheduler
            setShowEditScheduler={setShowEditScheduler}
            refresh={refresh}
            tableId={tableId}
            schedulerId={selectedSchedulerId}
        />}
        {showAddScheduler && <PopupEditScheduler
            setShowEditScheduler={setShowAddScheduler}
            refresh={refresh}
            tableId={tableId}
            schedulerId={null}
        />

        }
    </div>)
}
export default VisualsGrid;
