import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox, TextArea } from "devextreme-react";
import Loading from "../../../loading/Loading";
import toast from "react-hot-toast";

const Keuzelijst = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  multiple,
  isCreate,
}) => {
  const msal = useMsal();
  const [loading, setLoading] = useState(true);
  const [tablesData, setTablesData] = useState();
  const [hasWeergave, setHasWeergave] = useState(false);
  const [weergaveCheckLoading, setWeergaveCheckLoading] = useState(false);
  const [list, setList] = useState();
  const [columns, setColumns] = useState();
  const [hasValues, setHasValues] = useState(false);
  const [waardes, setWaardes] = useState();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [kleurColumns, setKleurColumns] = useState([]);
  const [hasColours, setHasColours] = useState(false);


  const checkValues = (weergaveId) => {
    setHasValues(false);

    let tempValues = [];

    columns.map((item) => {
      list[0].listColumns.map((listItem) => {
        if (listItem.columnId === item.id && listItem.columnId !== weergaveId) {
          tempValues = [...tempValues, item];
          return tempValues;
        } else {
          return;
        }
      });
      return tempValues;
    });
    setHasValues(true);

    setWaardes(tempValues);
  };

  const getSetList = async (tableId) => {
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/list/`
      );
      const data = await response.json();
      setList(data);

      return data;
    } catch (error) {
      throw "Data Loading Error";
    }
  };

  const getKleurColumns = (data) => {

    let tempKleurColumns = data;

    let finalKleurColumns = [];
    tempKleurColumns.map((item) => {
      if (item.columnType && item.columnType.id === 20) {
        finalKleurColumns.push(item);
      } else {
        return;
      }
    });
    if (finalKleurColumns.length > 0) {
      setHasColours(true);
      setKleurColumns(finalKleurColumns);
    }
  };

  const getColumns = async (tableId) => {
    setWeergaveCheckLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column`
      );
      const data = await response.json();

      setColumns(data);
      setWeergaveCheckLoading(false);
      getKleurColumns(data);

      let tempValueColumns = data.filter((t) => t.type === "int");

      setWaardes(tempValueColumns);
      return data;
    } catch (error) {
      setWeergaveCheckLoading(false);
      throw "Data Loading Error";
    }
  };

  const getTables = async () => {
    setLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/`
      );
      const data = await response.json();

      setTablesData(data);
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      throw "Data Loading Error";
    }
  };

  const checkWeergave = async (tableId) => {
    setWeergaveCheckLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column/`
      );
      const data = await response.json();

      setHasWeergave(true);
      getSetList(tableId);

      let tempColumns = data.filter(
        (t) => (t.columnType && t.columnType.id !== 20) || t.name !== "Id"
      );

      let tempValueColumns = data.filter((t) => t.type === "int");

      setWaardes(tempValueColumns);
      getKleurColumns(data);
      setColumns(tempColumns);

      return data;
    } catch (error) {
      throw "Data Loading Error";
    } finally {
      setWeergaveCheckLoading(false);
    }
  };

  useEffect(() => {
    getTables();
    //getCurrentColuymn(fieldValue.tableId, fieldValue.id);
    if (fieldValue.inputTableId) {
      checkWeergave(fieldValue.inputTableId);
    }

    if (fieldValue.inputTableQueryWhere) {
      setAccordionExpanded(true);
    }
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Alleen lezen</Form.Label>
            </Col>
            <Col lg="3">
              <CheckBox
                defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["readonly"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>
            <Col lg="3">
              <Form.Label>Verplicht</Form.Label>
            </Col>
            <Col lg="3">
              <CheckBox
                defaultValue={
                  fieldValue.mandatory ? fieldValue.mandatory : false
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["mandatory"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>



        {!multiple && (
          <Form.Group>
            <Row>
              <Col lg="3">
                <Form.Label>Standaard waarde</Form.Label>
              </Col>
              <Col lg="3">
                <TextBox
                  type="input"
                  placeholder="Standaard waarde"
                  defaultValue={
                    fieldValue.defaultValue ? fieldValue.defaultValue : ""
                  }
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => {
                    let tempFieldValue = fieldValue;
                    tempFieldValue["defaultValue"] = e.value;
                    setFieldValue(tempFieldValue);
                    checkChanges();
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        )}

        <header className="bl-config-section-header">
          <h2> Selecteer een tabel</h2>
        </header>
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Standaard waarde</Form.Label>
            </Col>
            <Col lg="9">
              <TextBox
                type="input"
                defaultValue={fieldValue.defaultValue}
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["defaultValue"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Tabel</Form.Label>
            </Col>
            <Col lg="9">
              <SelectBox
                searchEnabled
                readOnly={isCreate ? false : true}
                items={tablesData}
                valueExpr="id"
                displayExpr="tableName"
                className={
                  fieldValue.inputTableId === "" || isCreate
                    ? "bl-mandatory-field"
                    : ""
                }
                defaultValue={
                  fieldValue.inputTableId && fieldValue.inputTableId
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputTableId"] = e.value;
                  tempFieldValue["inputDisplayColumnId"] = null;
                  tempFieldValue["inputValueColumnId"] = null;

                  setFieldValue(tempFieldValue);
                  setHasValues(false);

                  checkChanges();

                  checkWeergave(e.value);

                  e.element.classList.remove("bl-mandatory-field");
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Weergave</Form.Label>
            </Col>
            <Col lg="3">
              <SelectBox
                searchEnabled
                key={hasWeergave}
                items={columns}

                valueExpr="id"
                readOnly={hasWeergave ? false : true}
                displayExpr="alias"
                className={
                  hasWeergave && fieldValue.inputDisplayColumnId
                    ? ""
                    : "bl-mandatory-field"
                }
                defaultValue={
                  fieldValue.inputDisplayColumnId && hasWeergave
                    ? fieldValue.inputDisplayColumnId
                    : ""
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputDisplayColumnId"] = e.value;
                  setFieldValue(tempFieldValue);
                  //   checkValues(e.value);
                  setHasValues(true);
                  checkChanges();

                  e.element.classList.remove("bl-mandatory-field");
                }}
              />
            </Col>
            <Col lg="3">
              <Form.Label>Waarde</Form.Label>
            </Col>
            <Col lg="3">
              <SelectBox
                searchEnabled
                key={waardes}
                items={waardes}
                valueExpr="id"
                readOnly={isCreate ? false : true}
                displayExpr="alias"
                defaultValue={
                  fieldValue.inputValueColumnId
                    ? fieldValue.inputValueColumnId
                    : ""
                }
                className={
                  fieldValue.inputValueColumnId && !isCreate
                    ? ""
                    : "bl-mandatory-field"
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputValueColumnId"] = e.value;
                  setFieldValue(tempFieldValue);

                  checkChanges();

                  e.element.classList.remove("bl-mandatory-field");
                }}
              />
            </Col>
          </Row>
        </Form.Group>



        <Form.Group>
          <Row>

            <Col lg="3">
              <Form.Label>Kleur</Form.Label>
            </Col>
            <Col lg="3">
              <SelectBox
                searchEnabled
                key={hasWeergave}
                showClearButton={true}
                items={kleurColumns}
                valueExpr="id"
                readOnly={hasWeergave ? false : true}
                displayExpr="alias"
                className={!hasColours && "bl-disabled-field"}

                defaultValue={
                  fieldValue.inputColorColumnId && hasWeergave
                    ? fieldValue.inputColorColumnId
                    : ""
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputColorColumnId"] = e.value;
                  setFieldValue(tempFieldValue);

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Label</Form.Label>
            </Col>
            <Col lg="9">
              <TextArea
                height={100}
                placeholder="Bijvoorbeeld: [Naam] <br />[AdressLine1]"
                defaultValue={fieldValue.inputLabel && fieldValue.inputLabel}
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputLabel"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>

          </Row>

        </Form.Group>


        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Voldoet aan</Form.Label>
            </Col>
            <Col lg="9">
              <TextBox
                type="input"
                placeholder="Voldoet aan (SQL WHERE)"
                defaultValue={
                  fieldValue.inputTableQueryWhere &&
                  fieldValue.inputTableQueryWhere
                }
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputTableQueryWhere"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Sorteren op</Form.Label>
            </Col>
            <Col lg="9">
              <TextBox
                type="input"
                placeholder="Sorteren op (SQL ORDER)"
                defaultValue={
                  fieldValue.inputTableQueryOrderBy &&
                  fieldValue.inputTableQueryOrderBy
                }
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputTableQueryOrderBy"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
      </>
    );
  }
};

export default Keuzelijst;
