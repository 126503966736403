import React, { memo, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PowerReportDataFetcher from "../powerReport/PowerReportDataFetcher.js";
import { useGraphContext } from "../graph/context";

const PowerReportBlock = ({
    width,
    controlRelationId,
    detailviewId,
    tableItemId,
    powerReportId
}) => {
    console.log("PowerReportBlock", tableItemId)
    const { currentUser } = useGraphContext();
    const [inlineEditMode, setInlineEditMode] = useState(false);

    return (
        <Col lg={width} md={12} className={`bl-col-padding`}>
            <section className={`bl-detail-column bl-card ${inlineEditMode && "inlineEditMode"}`}>

                <PowerReportDataFetcher
                    overrideId={powerReportId}
                    tableItemId={tableItemId}
                >

                </PowerReportDataFetcher>
                {currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dashboard`}
                    >
                        <div className="control-property-configurator-icon"><i className={"fa-regular fa-cog"} /></div>

                    </Link>
                )}

            </section>
        </Col>);
};

export default memo(PowerReportBlock);
