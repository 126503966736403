import { useMsal } from "@azure/msal-react";
import React from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";

import { useModalContext } from "../../context/modalContext";
import "./notifications.scss";
import toast from "react-hot-toast";

const DeleteNotification = () => {
    const msal = useMsal();
    const {
        notificationData,
        setDeleteNotification,
        actionTargetKey,
        setSuccessful,
    } = useModalContext();
    let queryObject = { columns: [notificationData.columns] };
    queryObject.columns[0].value = actionTargetKey;

    const handleDeleteButtonClick = async () => {
        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/table/${notificationData.columns.table.id}/delete`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(queryObject),
                }
            );
            if (res.ok) {
                setDeleteNotification(false);
                setSuccessful(true);
                toast.success("Het item is successvol verwijderd...");
                console.log(res);
                return res;
            } else {
                toast.error("Oeps er ging even iets mis...");
            }
        } catch (error) {
            setDeleteNotification(false);
            console.log(error);
            toast.error("Oeps er ging even iets mis...");
            throw error;
        }
    };

    return (
        <div className={`bl-notify-modal`}>
            <div className="bl-notify-modal-inner">
                <header className="bl-notify-modal-header">
                    <h2> Verwijderen</h2>
                    <div
                        className="header-action"
                        onClick={() => setDeleteNotification(false)}
                    >
                        <i className="fas fa-times" />
                    </div>
                </header>
                <article>
                    <h1>
                        U staat op het punt om dit item te verwijderen. Weet u het zeker?
                    </h1>
                    <footer className="bl-notify-controls">
                        <div
                            className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
                            onClick={() => {
                                setDeleteNotification(false);
                                setSuccessful(false);
                            }}
                        >
                            Annuleren
                        </div>
                        <div
                            className="bl-notify-modal-btn bl-btn bl-notify-succes-btn"
                            onClick={handleDeleteButtonClick}
                        >
                            Verwijderen
                        </div>
                    </footer>
                </article>
            </div>
        </div>
    );
};

export default DeleteNotification;
