import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import {
    Switch,
    Route,
} from "react-router-dom";
import AnonymousDashboard from "./pages/dashboard/AnonymousDashboard";
import Forbidden from "./components/notifications/forbidden/Forbidden";
import Register from "./pages/Register";
import Routes from "./Routes";
import { GraphProvider } from "./components/graph/context";

const Auth = () => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    if (inProgress === InteractionStatus.Startup) {
        // @TODO: show some kind of startup
        return null;
    }
    if (inProgress === InteractionStatus.HandleRedirect) {
        // @TODO: show some kind of message
        return null;
    }
    if (inProgress === InteractionStatus.Login) {
        // @TODO: we could show some kind of login message..
    }
    if (inProgress === InteractionStatus.Logout) {
        // @TODO: we could show some kind of logout message..
    }



    if (!isAuthenticated) {
        return (
            <Switch>
                <Route path="/login" component={AnonymousDashboard} />
                <Route path="/registreer" component={Register} />
                <Route path="/forbidden" component={Forbidden} />
                <Route component={AnonymousDashboard} />
            </Switch>
        )
    }

    return (
        <GraphProvider>
            <Routes />
        </GraphProvider>
    );
};

export default Auth;
