import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { columnDefaultValueDateTime } from "../../../../enums/columnDefaultValue"

import { CheckBox, NumberBox, SelectBox } from "devextreme-react";

const DatumTijd = ({
    fieldValue,
    setFieldValue,
    checkChanges,
    isCreate,
    setData,
}) => {
    const [validateAgainstColumns, setValidateAgainstColumns] = useState([]);
    const [dateSpecification, setDateSpecification] = useState(false);
    const [specification, setSpecification] = useState();
    const [indication, setIndication] = useState();
    const [dateTimeColumns, setDateTimeColumns] = useState([]);
    const dateSpecificationOptions = [
        { v: "H", d: "Uren" },
        { v: "M", d: "Minuten" },
        { v: "D", d: "Dagen" },
    ];
    const _dateDefaultValueNoneId = columnDefaultValueDateTime.None; //switch to the Enum in EnumColumnDefaultValues.cs later
    const _dateDefaultValueTodayId = columnDefaultValueDateTime.Now; //switch to the Enum in EnumColumnDefaultValues.cs later

    useEffect(() => {
        let tempValdiationColumns = setData.filter(
            (item) =>
                (item.type === "date" || item.type === "datetime") &&
                item.id !== fieldValue.id
        );
        tempValdiationColumns.push({ alias: "...", id: null });

        setValidateAgainstColumns(tempValdiationColumns);
    }, []);

    useEffect(() => {
        let tempFieldValue = fieldValue;

        if (
            fieldValue.columnDefaultValue &&
            fieldValue.defaultValue &&
            fieldValue.columnDefaultValue.name !== "Geen"
        ) {
            let indication = fieldValue.defaultValue.slice(-1);

            let defaultString = fieldValue.defaultValue;
            let result = defaultString.split("+");
            if (result.length > 1) {
                defaultString = result[1].replace(indication, "");
            }

            tempFieldValue.columnDefaultValue["specification"] = defaultString;
            tempFieldValue.columnDefaultValue["specificationIndicator"] = indication;

            setIndication(indication);
            setSpecification(defaultString);

            setDateSpecification(true);
        } else {
            setDateSpecification(false);
        }

        setFieldValue(tempFieldValue);
    }, []);

    const checkDateSpecification = (value) => {
        const target = fieldValue.columnType.columnDefaultValues.filter(
            (item) => value === item.id
        );
        if (target[0].name === "Geen") {
            let tempFieldValue = fieldValue;
            tempFieldValue.columnDefaultValue = {
                id: value,
                name: "Geen",
                specification: 0,
                specificationIndicator: null,
            };
            setIndication(null);
            setSpecification(null);
            tempFieldValue["defaultValue"] = "";

            setDateSpecification(false);
        } else {
            setDateSpecification(true);
        }
    };
    return (
        <>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Alleen lezen</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={
                                !isCreate && fieldValue.readonly ? fieldValue.readonly : false
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["readonly"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Verplicht</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={
                                !isCreate && fieldValue.mandatory ? fieldValue.mandatory : false
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["mandatory"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Standaard waarde</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            //className={isCreate && "bl-mandatory-field"}
                            defaultValue={isCreate ? _dateDefaultValueNoneId : (fieldValue.columnDefaultValue == null ? _dateDefaultValueNoneId : _dateDefaultValueTodayId)}
                            items={fieldValue.columnType.columnDefaultValues}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                if (!tempFieldValue.columnDefaultValue) {
                                    tempFieldValue["columnDefaultValue"] = {};
                                }
                                tempFieldValue.columnDefaultValue["id"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                                checkDateSpecification(e.value);
                                e.element.classList.remove("bl-mandatory-field");
                            }}
                        />
                        {dateSpecification && (
                            <section className="date-specification-section">
                                <div className="date-specification-column">
                                    <Form.Label>Plus</Form.Label>
                                    <NumberBox
                                        defaultValue={
                                            fieldValue.columnDefaultValue &&
                                                fieldValue.columnDefaultValue.specification
                                                ? fieldValue.columnDefaultValue.specification
                                                : 0
                                        }
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            tempFieldValue.columnDefaultValue["specification"] =
                                                e.value;

                                            tempFieldValue.defaultValue = `GETDATELOCAL()+${e.value}${indication}`;
                                            setSpecification(e.value);
                                            setFieldValue(tempFieldValue);
                                            checkChanges();
                                        }}
                                    />
                                </div>

                                <div className="date-specification-column">
                                    <Form.Label>Aantal</Form.Label>
                                    <SelectBox
                                        searchEnabled
                                        defaultValue={
                                            fieldValue.columnDefaultValue &&
                                            fieldValue.columnDefaultValue.specificationIndicator &&
                                            fieldValue.columnDefaultValue.specificationIndicator
                                        }
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            tempFieldValue.columnDefaultValue[
                                                "specificationIndicator"
                                            ] = e.value;
                                            tempFieldValue.defaultValue = `GETDATELOCAL()+${specification}${e.value}`;
                                            setIndication(e.value);
                                            setFieldValue(tempFieldValue);
                                            checkChanges();
                                        }}
                                        items={dateSpecificationOptions}
                                        valueExpr="v"
                                        displayExpr="d"
                                    />
                                </div>
                            </section>
                        )}
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Weergave</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            className={isCreate && "bl-mandatory-field"}
                            defaultValue={
                                !isCreate &&
                                fieldValue.columnDisplayValue.id &&
                                fieldValue.columnDisplayValue.id
                            }
                            items={fieldValue.columnType.columnDisplayValues}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["columnDisplayValue"] =
                                    fieldValue.columnType.columnDisplayValues.filter(
                                        (item) => e.value === item.id
                                    )[0];

                                setFieldValue(tempFieldValue);
                                checkChanges();
                                e.element.classList.remove("bl-mandatory-field");
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Groter of gelijk aan waarde van kolom</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            items={validateAgainstColumns}
                            valueExpr="id"
                            defaultValue={
                                fieldValue.validateAgainstColumnId &&
                                fieldValue.validateAgainstColumnId
                            }
                            displayExpr="alias"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;

                                tempFieldValue["validateAgainstColumnId"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
};

export default DatumTijd;
