import React, { useState, useCallback, useMemo } from "react";
import List from './List';
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { confirm } from 'devextreme/ui/dialog';
import { useGlobalContext } from "../../context/context.js";
import { useGraphContext } from "../graph/context.js";
import { Link } from "react-router-dom";
import BloomFooter from "../footer/BloomFooter.js";

const ListWrapper = ({
    columns,
    overviewId,
    isOverview,
    controlRelationId,
    scrollHorizontal,
    tableId,
    columnFunctions,
    detailViewIdAdd,
    preferredDetailViewId,
    preferredDetailViewIdField,
    preferredDetailViewIdEdit,
    overviewOrControlRelationName,
    gridName,
    isControlRelation,
    manualSortColumnId,
    tableItemId,
    detailviewId,
    keyExpression,
    externalSelectFunction,
    autoRefresh,
    verticalHeight,
    allowInlineEditing,
    allowDelete,
    allowCreate,
    allowRead,
    allowUpdate,
    allowExportExcel,
    allowCopy,
    inlineEditMode,
    setInlineEditMode,
    isModal,
    ...props
}) => {
    const [duplicateData, setDuplicateData] = useState(null);
    const { isMobile } = useGlobalContext();
    const getColumnByProperty = useCallback((property) => {
        return columns.find(col => col.name.toLowerCase() === property);
    }, [columns]);

    const isFilterTriplet = useCallback((value) => {
        if (!Array.isArray(value))
            return false;
        if (value.length !== 3)
            return false;
        if (typeof value[0] != 'string')
            return false;
        const hasColumnWithSameName = columns.map(col => col.name.toLowerCase()).includes(value[0].toLowerCase());
        const hasColumnWithSameNameAndIdSuffix = columns.map(col => col.name.toLowerCase() + '_id').includes(value[0].toLowerCase())
        if (!hasColumnWithSameName && !hasColumnWithSameNameAndIdSuffix)
            return false;
        if (typeof value[1] != 'string')
            return false;
        if (!['=', '<=', '>=', '<', '>', 'contains', 'notcontains', 'startswith', 'endswith'].includes(value[1]))
            return false;
        return true;
    }, [columns]);

    const StripIdsOfUsers = useCallback((filters) => {
        const columnsOfTypeUser = columns.filter(col => col.columnTypeCode === 'USRS').map(col => col.name.toLowerCase());
        const columsOfTypeDateOrDateTime = columns.filter(col => col.columnTypeCode === 'D' || col.columnTypeCode === 'DT').map(col => col.name.toLowerCase());
        let returnValue = [];
        for (var c = 0; c < filters.length; c++) {
            if (isFilterTriplet(filters[c])) {
                const columnName = filters[c][0];
                if (columnsOfTypeUser.includes(columnName.slice(0, -3))) {
                    const columnNameWithoutIdSuffix = columnName.slice(0, -3).toLowerCase();
                    const userSearchString = filters[c][2];
                    const userNames = columns.find(col => col.name.toLowerCase() === columnNameWithoutIdSuffix).options;
                    const userId = userSearchString == null || userSearchString === '' ? null : userNames.find(option => option.label.toLowerCase() === userSearchString.toLowerCase()).primairyKeyValue;
                    returnValue[c] = [columnNameWithoutIdSuffix, filters[c][1], userId];
                } else if (columnName.endsWith('_id') && columns.map(col => col.name.toLowerCase()).includes(columnName.slice(0, -3))) {
                    returnValue[c] = [columnName.slice(0, -3), filters[c][1], filters[c][2]];
                } else {
                    returnValue[c] = [columnName, filters[c][1], filters[c][2]];
                }
                if (columsOfTypeDateOrDateTime.includes(columnName)) {
                    if (filters[c][2] instanceof Date) {
                        //taken from session state, convert to date string. Won't win a beauty contest, but it will work
                        const date = filters[c][2];
                        const parsedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
                        returnValue[c] = [columnName, filters[c][1], parsedDate]
                    }
                }
            } else if (typeof filters[c] === 'string') {
                returnValue[c] = filters[c];
            } else {
                returnValue[c] = StripIdsOfUsers(filters[c])
            }
        }
        return returnValue;
    }, [columns, isFilterTriplet])

    const stripGroupIdSuffixes = useCallback((group) => {
        const returnValue = [];
        for (var c = 0; c < group.length; c++) {
            let element = group[c];
            let columnName = element.selector;
            if (columnName.endsWith('_id') && columns.map(col => col.name.toLowerCase()).includes(columnName.slice(0, -3))) {
                columnName = columnName.slice(0, -3);
            }
            returnValue.push({ isExpanded: element.isExpanded, selector: columnName, desc: element.desc });
        }
        return returnValue;
    }, [columns]);

    const cloneRecord = useCallback(async (e) => {
        let result = await confirm("Weet u zeker dat u dit item wilt dupliceren?", "Item dupliceren?");
        if (!result) {
            return;
        }
        const currentTableitemId = e.row.key;
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/table/${tableId}/copy/${currentTableitemId}`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`cloneRecord duplicateColumnClick response was not ok: ${data.message}`);
            }
            if (data) {
                setDuplicateData({
                    duplicateId: data.id,
                    detailviewId: detailViewIdAdd,
                    tableId: tableId,
                });
            }
            return true;
        } catch (error) {
            toast.error("Er ging even iets mis...");
            throw error;
        }
    }, [tableId, detailViewIdAdd]);

    //#region CRUD
    const updateServerRecord = useCallback(async (tableItemId, valuePair) => {
        const field = Object.entries(valuePair)[0][0];
        const columnProperty = field.endsWith('_id') ? field.slice(0, -3) : field;
        //this needs to be improved a lot...
        const column = field.endsWith('_userId') ? columns.find(col => col.id === Number(field.slice(0, -7))) : getColumnByProperty(columnProperty);
        let params = { Id: column.id };
        let value = Object.entries(valuePair)[0][1];
        const replacedById = ['MULTIPLEKEUZELIJST'];
        //The ZKL sets the ID in the record. It gets updated automatically from the server and replaced with the text value. This workaround works, but needs to be taken care of.
        const columnTypesMultipleValues = ['MULTIPLEKEUZELIJST'];
        if (replacedById.includes(column.columnTypeCode)) {

        }
        params[columnTypesMultipleValues.includes(column.columnTypeCode) ? 'Values' : 'Value'] = value;
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/update/${tableId}/${tableItemId}/${overviewId}/${controlRelationId}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(params),
                }
            );
            const json = await res.json();
            if (!res.ok) {
                throw new Error(`[PRACTICEGRID] UpdateField response was not ok: ${json.message}`);
            } else {
                toast.success("Successvol opgeslagen");
            }
            return json;
        } catch (error) {
            toast.error("Er ging iets mis");
            return false;
        }
    }, [getColumnByProperty, tableId, overviewId, controlRelationId, columns]);

    const deleteServerRecord = useCallback(async (id) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/${tableId}/delete/${id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" }
                }
            );
            if (!res.ok) {
                const json = await res.json();
                throw new Error(`[PRACTICEGRID] UpdateField response was not ok: ${json.message}`);
            }
            if (res.ok) {
                toast.success("Het item is successvol verwijderd...");
            }
        } catch (error) {
            toast.error("Er ging even iets mis...");
            throw error;
        }
    }, [tableId]);
    //#endregion

    const loadDataSourceOverview = useCallback(async (loadOptions) => {

        let filter;
        let group;

        if (loadOptions.filter != null) {
            const hasOneFilter = !Array.isArray(loadOptions.filter[0])
            const wrappedFilters = hasOneFilter ? [loadOptions.filter] : loadOptions.filter;
            /*loadOptions.*/filter = StripIdsOfUsers(wrappedFilters);
        }
        if (loadOptions.group != null) {
            /*loadOptions.*/group = stripGroupIdSuffixes(loadOptions.group);
        }

        const url = isOverview ? `${appSettings.api.endpoint}/api/overview/${overviewId}/datajson` : `${appSettings.api.endpoint}/api/relation/${controlRelationId}/datajson/${tableItemId}`;
        const body = {
            dataField: loadOptions.dataField,
            take: loadOptions.take,
            skip: loadOptions.skip,
            group: /*loadOptions.*/group ?? "",
            sort: loadOptions.sort,
            filter: /*loadOptions.*/filter,
            totalSummary: loadOptions.totalSummary,
            groupSummary: loadOptions.groupSummary,
            requireTotalCount: true,
            requireGroupCount: loadOptions.requireGroupCount
        };

        const options = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(body)
        }
        const response = await msalFetch(null, url, options);
        const json = await response.json();
        return json;
    }, [isOverview, overviewId, StripIdsOfUsers, stripGroupIdSuffixes, controlRelationId, tableItemId]);

    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: loadDataSourceOverview,
            update: updateServerRecord,
            remove: deleteServerRecord,
        })
    }), [loadDataSourceOverview, deleteServerRecord, updateServerRecord]);

    const { currentUser } = useGraphContext();

    return (
        <div>
            {!isControlRelation && (<Helmet>
                <title>
                    {`${gridName}`}
                </title>
            </Helmet>)}


            <section className={`bl-overview   ${!isControlRelation ? "bl-card" : ""} ${isMobile ? "bl-mobile-card" : ""} ${inlineEditMode && isOverview && "inlineEditMode"}`} >
                <List
                    {...props}
                    overviewId={overviewId}
                    controlRelationId={controlRelationId}
                    dataSource={dataSource}
                    columns={columns}
                    gridName={gridName}
                    scrollHorizontal={scrollHorizontal}
                    columnFunctions={columnFunctions}
                    tableId={tableId}
                    isControlRelation={isControlRelation}
                    isOverview={overviewId != null}
                    detailViewIdAdd={detailViewIdAdd}
                    preferredDetailViewId={preferredDetailViewId}
                    preferredDetailViewIdField={preferredDetailViewIdField}
                    preferredDetailViewIdEdit={preferredDetailViewIdEdit}
                    tableItemId={tableItemId}
                    setInlineEditMode={setInlineEditMode}
                    inlineEditMode={inlineEditMode}
                    overviewOrControlRelationName={overviewOrControlRelationName}
                    cloneRecord={cloneRecord}
                    manualSortColumnId={manualSortColumnId}
                    keyExpression={keyExpression}
                    externalSelectFunction={externalSelectFunction}
                    autoRefresh={autoRefresh}
                    verticalHeight={verticalHeight}
                    allowInlineEditing={allowInlineEditing}
                    allowCopy={allowCopy}
                    allowDelete={allowDelete}
                    allowCreate={allowCreate}
                    allowRead={allowRead}
                    allowUpdate={allowUpdate}
                    allowExportExcel={allowExportExcel}
                    duplicateData={duplicateData}
                    setDuplicateData={setDuplicateData}
                />
            </section>
            <footer className="bl-footer">
                {!isControlRelation && currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/3/list/${overviewId}/`}
                    >
                        <div title="Bewerk lijst" className="control-configurator-icon"><i className={"fa-regular fa-list"} /></div>
                    </Link>
                )}
                {!isControlRelation && currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/`}
                    >
                        <div title="Bewerk tabel" className="control-configurator-icon"><i className={"fa-regular fa-table"} /></div>
                    </Link>
                )}
                {!isControlRelation &&
                    <BloomFooter />
                }
            </footer>
        </div >
    );
};

export default ListWrapper;
