import { Column, RequiredRule, HeaderFilter } from "devextreme-react/data-grid";


const renderDateTimeColumn = (name, alias, width, key, readonly, mandatory, inlineEditMode, includeTime, sortIndex, sortDirection, groupIndex) => {
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            dataType={includeTime ? "datetime" : "date"}
            allowEditing={!readonly}
            width={width}
            format={includeTime ? "dd-MM-yyyy HH:mm" : "dd-MM-yyyy"}
            allowHeaderFiltering={!includeTime}

            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={!includeTime}
            groupIndex={groupIndex}
        >
            {mandatory && <RequiredRule />}
            {/* <HeaderFilter groupInterval="month" /> */}
            <HeaderFilter
                groupInterval={"day"}
            />
        </Column >
    );
};

export default renderDateTimeColumn;
