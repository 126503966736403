import React, { useState, useEffect, useRef, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import DataSource from "devextreme/data/data_source";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox, Popup } from "devextreme-react";
import DataGrid, { Column, RowDragging } from "devextreme-react/data-grid";
import ModalControls from "../parts/modalControls/ModalControls";
import Loading from "../../loading/Loading";
import toast from "react-hot-toast";
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";
import { useGraphContext } from "../../graph/context";
import HtmlEditor, {
    Toolbar,
    MediaResizing,
    Item,
} from "devextreme-react/html-editor";


const headerValues = [false, 1, 2, 3, 4, 5];
const sizeValues = ["8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida"];

const EditPage = ({
    isCreate,
    isEditing,
    setIsEditing,
    refreshFunction,

    singlePageData,
    pages,
    dataSetId,
}) => {
    const { currentUser } = useGraphContext();
    const isDocumentarian = currentUser?.documentarian === 1;

    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({ visibleAsTab: true });
    const [columnData, setColumnData] = useState();
    const [throwNotification, setThrowNotification] = useState(false);
    const [rows, setRows] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [rawOrderdata, setRawOrderdata] = useState();

    const [changeInForm, setChangeInForm] = useState(false);
    const gridRef = useRef();

    const dataSource = new DataSource({
        store: orderData,
        key: "name",
        group: "fieldType",
    });

    console.log("render", rows);

    const widths = [
        { id: 1, value: '1/12' },
        { id: 2, value: '2/12' },
        { id: 3, value: '3/12' },
        { id: 4, value: '4/12' },
        { id: 5, value: '5/12' },
        { id: 6, value: '6/12' },
        { id: 7, value: '7/12' },
        { id: 8, value: '8/12' },
        { id: 9, value: '9/12' },
        { id: 10, value: '10/12' },
        { id: 11, value: '11/12' },
        { id: 12, value: '12/12' }
    ]

    const checkChanges = () => {
        const dirtyDocument = document.getElementsByClassName("bl-mandatory-field");

        if (!changeInForm) {
            setChangeInForm(true);
        }
    };

    const getColumnData = async () => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/column`
            );
            const data = await response.json();
            setColumnData(data);
            return data;
        } catch (error) {
            throw "Data Loading Error EditPage";
        }
    };

    //prepare de columns
    const getPageData = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/page/${singlePageData.id}`
            );
            const data = await response.json();
            setPageData(data);

            let tempRows = [];

            // check if there're empty rows
            data.html.map((i) => {
                if (tempRows.find((t) => t === i.rowNumber)) {
                    return;
                } else {
                    tempRows.push(i.rowNumber);
                }
            });
            console.log("1. tempRows", tempRows);
            //find out how many rows
            let numberOfRowsToAdd = Math.max(...tempRows);
            for (var i = 1; i <= numberOfRowsToAdd; i++) {
                addField(i);
            }
            console.log("2. tempRows", tempRows);
            //fill rows with data
            if (rows) {
                let tempRows = rows;
                data.html.map((column) => {
                    let expression;
                    let controlExpressionId;
                    if (column.controlImageId) {
                        expression = "controlImageId";
                        controlExpressionId = column.controlImageId;
                    } else if (column.controlPropertyId) {
                        expression = "controlPropertyId";
                        controlExpressionId = column.controlPropertyId;
                    } else if (column.controlRelationId) {
                        expression = "controlRelationId";
                        controlExpressionId = column.controlRelationId;
                    } else if (column.controlTabId) {
                        expression = "controlTabId";
                        controlExpressionId = column.controlTabId;
                    } else if (column.powerReport) {
                        expression = "powerReportId";
                        controlExpressionId = column.powerReportId;
                    } else {
                        return "Geen";
                    }
                    //debugger;
                    console.log("3. tempRows", tempRows);
                    if (tempRows.find((r) => r.row === column.rowNumber)) {
                        var tempRowData = tempRows
                            .find((r) => r.row === column.rowNumber)
                            .data;
                        tempRowData.find((c) => c.columnNumber === column.columnNumber)[expression] = controlExpressionId;
                        tempRowData.find((c) => c.columnNumber === column.columnNumber).relativeWidthId = column.width;
                    }
                    console.log("4. tempRows", tempRows);
                });
                console.log("5. tempRows", tempRows);
                setRows(tempRows);
            }
            setLoading(false);
            return data;
        } catch (error) {
            throw "Data Loading Error getPageData";
        }
    };

    // Get tab fields
    const getTabs = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/control/tab`
            );
            const data = await response.json();

            data.map((item) => {
                if (data.length < 1) return null;
                let tempItem = item;
                item["fieldType"] = "3. Tabbladen";
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });
            getPowerReports(tempFieldValue);
            return data;
        } catch (error) {
            throw "Data Loading Error getTabs";
        }
    };

    // get relation fields
    const getRelations = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/control/relation`
            );
            const data = await response.json();

            data.map((item) => {
                let tempItem = item;
                if (data.length < 1) return null;
                tempItem["fieldType"] = "2. Gerelateerd";

                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });

            getTabs(tempFieldValue);

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    };

    //get normal fields
    const getProperties = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/control/property`
            );
            const data = await response.json();

            data.map((item) => {
                let tempItem = item;
                if (data.length < 1) return null;
                tempItem["fieldType"] = "1. Veldenset";
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });

            getRelations(tempFieldValue);

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    };

    //get image fields
    const getImages = async () => {
        try {
            let tempFieldValue = [];
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/control/image`
            );
            const data = await response.json();

            data.map((item) => {
                let tempItem = item;
                if (data.length < 1) return null;
                tempItem["fieldType"] = "4. Afbeeldingen";
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });

            getProperties(tempFieldValue);
            getColumnData();

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    };

    const getPowerReports = async (tempFieldValue) => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/index`);
            const data = await response.json();

            data.map((item) => {
                if (data.length < 1) return null;
                let tempItem = item;
                item["fieldType"] = "5. Dashboards";
                item["name"] = item.title;
                return (tempFieldValue = [...tempFieldValue, tempItem]);
            });
            setRawOrderdata(tempFieldValue);
            setOrderData(tempFieldValue);
            return data;
        } catch (error) {
            throw "Data Loading Error getTabs";
        }
    }

    // get the value expression (control...Id)
    const valueExpresion = (data, columnIndex) => {
        if (data.data.data[columnIndex].controlImageId) {
            return "controlImageId";
        } else if (data.data.data[columnIndex].controlPropertyId) {
            return "controlPropertyId";
        } else if (data.data.data[columnIndex].controlRelationId) {
            return "controlRelationId";
        } else if (data.data.data[columnIndex].controlTabId) {
            return "controlTabId";
        } else if (data.data.data[columnIndex].powerReportId) {
            return "powerReportId";
        }
        else {
            return "Geen";
        }
    };

    const getDefaultValue = (propertyData, whichColumn) => {

        const columnIndex = propertyData.data.data.findIndex(
            (item) => item.columnNumber === whichColumn
        );

        const propertyType = valueExpresion(propertyData, columnIndex);

        if (propertyType === "controlRelationId") {
            return propertyData.data.data[columnIndex].controlRelationId;
        } else if (propertyType === "controlPropertyId") {
            return propertyData.data.data[columnIndex].controlPropertyId;
        } else if (propertyType === "controlImageId") {
            return propertyData.data.data[columnIndex].controlImageId;
        } else if (propertyType === "controlTabId") {
            return propertyData.data.data[columnIndex].controlTabId;
        } else if (propertyType === "powerReportId") {
            return propertyData.data.data[columnIndex].powerReportId;
        } else {
            return null;
        }
    };

    //Handles value change in columns
    const handleValueChange = (data, columnIndexPassed) => (e) => {
        const columnNumber = columnIndexPassed;
        const rowNumber = data.data.row;
        let tempRows = rows;

        //find target that has changed
        let destination = rawOrderdata.find((t) => t.id === e.value)
            ? rawOrderdata.find((t) => t.id === e.value)
            : { fieldType: "Geen" };

        let expression = "";

        //find out what type of property
        if (destination.fieldType === "1. Veldenset") {
            expression = "controlPropertyId";
        } else if (destination.fieldType === "4. Afbeeldingen") {
            expression = "controlImageId";
        } else if (destination.fieldType === "2. Gerelateerd") {
            expression = "controlRelationId";
        } else if (destination.fieldType === "3. Tabbladen") {
            expression = "controlTabId";
        } else if (destination.fieldType === "5. Dashboards") {
            expression = "powerReportId";
        } else if (destination.fieldType === "Geen") {
            // clean values
            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)["controlId"] = null;
            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)["controlImageId"] =
                null;
            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)[
                "controlPropertyId"
            ] = null;
            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)[
                "controlRelationId"
            ] = null;

            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)["controlTabId"] =
                null;

            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)["powerReportId"] =
                null;
        }

        //flush values

        tempRows
            .find((t) => t.row === rowNumber)
            .data.find((i) => i.columnNumber === columnNumber)["controlId"] = null;
        tempRows
            .find((t) => t.row === rowNumber)
            .data.find((i) => i.columnNumber === columnNumber)["controlImageId"] =
            null;
        tempRows
            .find((t) => t.row === rowNumber)
            .data.find((i) => i.columnNumber === columnNumber)["controlPropertyId"] =
            null;
        tempRows
            .find((t) => t.row === rowNumber)
            .data.find((i) => i.columnNumber === columnNumber)["controlRelationId"] =
            null;
        tempRows
            .find((t) => t.row === rowNumber)
            .data.find((i) => i.columnNumber === columnNumber)["powerReportId"] =
            null;

        tempRows
            .find((t) => t.row === rowNumber)
            .data.find((i) => i.columnNumber === columnNumber)["controlTabId"] = null;

        //fill with new value

        if (destination.fieldType !== "Geen") {
            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)[expression] =
                e.value;
        } else {
            tempRows
                .find((t) => t.row === rowNumber)
                .data.find((i) => i.columnNumber === columnNumber)[expression] = null;
        }
        //set new Rows
        setRows(tempRows);
        checkChanges();
    };

    const handleColumnOneRelativeWidthChange = (row, col, value) => {
        //const row = data.data.row - 1;
        let tempRows = [...rows];
        tempRows[row].data[col].relativeWidthId = value;
        setRows(tempRows);
        checkChanges();
    }

    const getRelativeWidth = (row, columnIndex) => {
        if (row < rows.length) //LOUSY SOLUTION, REACT MUST BE FIXED!
            return rows[row].data[columnIndex].relativeWidthId;
    }

    const handleUserGuideChange = useCallback((e) => {
        let tempPageData = pageData;
        checkChanges();
        tempPageData['userGuide'] = e.value;
        setPageData(tempPageData);
    }, [checkChanges, pageData]);

    const columnOneRender = (data) => {
        return (
            <SelectBox
                dataSource={dataSource}
                grouped={true}
                valueExpr="id"
                displayExpr="name"
                defaultValue={getDefaultValue(data, 1)}
                showClearButton={true}
                onValueChanged={handleValueChange(data, 1)}
            />
        );
    };

    const columnTwoRender = (data) => {
        return (
            <SelectBox
                dataSource={dataSource}
                grouped={true}
                showClearButton={true}
                valueExpr="id"
                displayExpr="name"
                defaultValue={data.data.data.length > 1 ? getDefaultValue(data, 2) : 0}
                onValueChanged={handleValueChange(data, 2)}
            />
        );
    };

    const columnThreeRender = (data) => {
        let columns = [];
        data.data.data.map((item) => (columns = [...columns, item.columnNumber]));

        const containsThirdColumn = columns.includes(3);

        return (
            <SelectBox
                dataSource={dataSource}
                grouped={true}
                showClearButton={true}
                valueExpr="id"
                displayExpr="name"
                defaultValue={containsThirdColumn ? getDefaultValue(data, 3) : 0}
                onValueChanged={handleValueChange(data, 3)}
            />
        );
    };

    const columnOneRelativeWidthRender = (data) => {
        return (
            <SelectBox
                dataSource={widths}
                valueExpr="id"
                displayExpr="value"
                onValueChanged={(localData) => { handleColumnOneRelativeWidthChange(data.data.row - 1, 0, localData.value); }}
                defaultValue={getRelativeWidth(data.data.row - 1, 0)}
            />
        )
    }

    const columnTwoRelativeWidthRender = (data) => {
        return (
            <SelectBox
                dataSource={widths}
                valueExpr="id"
                displayExpr="value"
                onValueChanged={(localData) => { handleColumnOneRelativeWidthChange(data.data.row - 1, 1, localData.value); }}
                defaultValue={getRelativeWidth(data.data.row - 1, 1)}
            />
        )
    }

    const columnThreeRelativeWidthRender = (data) => {
        return (
            <SelectBox
                dataSource={widths}
                valueExpr="id"
                displayExpr="value"
                onValueChanged={(localData) => { handleColumnOneRelativeWidthChange(data.data.row - 1, 2, localData.value); }}
                defaultValue={getRelativeWidth(data.data.row - 1, 2)}
            />
        )
    }

    //adds new row to datagrid
    const addField = (row, data) => {
        let tempRows = rows;
        const numberOfColumns = [1, 2, 3];
        const columns = numberOfColumns.map((i) => {
            return {
                columnNumber: i,
                controlId: null,
                controlImageId: null,
                controlPropertyId: null,
                controlRelationId: null,
                controlTabId: null,
                rowNumber: row,
                relativeWidthId: null
            };
        });

        tempRows.push({ row: row, data: columns });

        setRows(tempRows);
        if (gridRef && gridRef.current && gridRef.current.instance()) {
            gridRef.current.instance().refresh(true);
        }
    };

    useEffect(() => {
        if (!isCreate) {
            getPageData();
        } else {
            setLoading(false);
        }

        if (isCreate && !pageData.sort) {
            let tempPageData = pageData;
            tempPageData["sort"] = pages.length;
            setPageData(tempPageData);
            addField(1);
        }
    }, []);

    useEffect(() => {
        getImages();
    }, []);

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={() => addField(rows.length + 1)}
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }

    const handleDeleteColumn = (columnData) => {
        // setModal("EDITPAGE");

        // filter rows without target
        let newRows = rows.filter((t) => t.row !== columnData.key.row);

        //adjust row numbers
        newRows.map((row, index) => (row.row = index + 1));

        // set new rows
        setRows(newRows);
        checkChanges();
    };

    const deleteControl = (columnData) => {
        return (
            <div className="bl-column-controls">
                <i
                    className={`fas fa-trash bl-column-control-btn bl-column-delete-btn ${columnData.canBeDeleted && "bl-disabled-btn"}`}
                    onClick={() => handleDeleteColumn(columnData)}
                />
            </div>
        );
    };

    //Handles the save function
    const handleSaveClick = async () => {
        let tempPageObject = pageData;
        let sortedHtml = [];
        let sortedAndFilledHtml = [];
        //dismantel rows into Html
        rows.map((rowTarget) => {
            rowTarget.data.map((column) => {
                let exportedRow = {
                    columnNumber: column.columnNumber,
                    controlId: null,
                    controlImageId: column.controlImageId,
                    controlPropertyId: column.controlPropertyId,
                    controlRelationId: column.controlRelationId,
                    controlTabId: column.controlTabId,
                    powerReportId: column.powerReportId,
                    width: column.relativeWidthId,
                    rowNumber: rowTarget.row,
                };
                sortedHtml.push(exportedRow);
            });
        });

        //create controlID
        sortedHtml.map((t) => {
            let tempControlId = "";
            let pushRow = t;
            if (t.controlPropertyId) {
                tempControlId = `V${t.controlPropertyId}`;
            } else if (t.controlImageId) {
                tempControlId = `A${t.controlImageId}`;
            } else if (t.controlTabId) {
                tempControlId = `T${t.controlTabId}`;
            } else if (t.controlRelationId) {
                tempControlId = `G${t.controlRelationId}`;
            } else if (t.powerReportId) {
                tempControlId = `Q${t.controlRelationId}`;
            } else {
                tempControlId = null;
            }
            pushRow["controlId"] = tempControlId;
            sortedAndFilledHtml.push(pushRow);
        });
        tempPageObject["html"] = sortedAndFilledHtml;

        let postUrl = `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/page`;

        // check if create of update
        if (isCreate) {
            try {
                const res = await msalFetch(null, postUrl, {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(tempPageObject),
                });

                if (res.ok) {
                    toast.success("Gegevens succesvol opgeslagen...");
                    setChangeInForm(false);
                    setIsEditing(false);
                } else {
                    toast.error("Oeps er ging iets mis...");
                }
            } catch (error) {
                toast.error("Oeps er ging iets mis...");
            }
        } else if (!isCreate) {
            try {
                const res = await msalFetch(null, postUrl, {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(tempPageObject),
                });
                if (res.ok) {
                    toast.success("Gegevens succesvol opgeslagen...");
                    setChangeInForm(false);
                    setIsEditing(false);
                } else {
                    toast.error("Oeps er ging iets mis...");
                }
            } catch (error) {
                toast.error("Oeps er ging iets mis...");
            }
        }
        refreshFunction();
    };



    const handleSaveAndContinue = () => {
        handleSaveClick();
    };

    const handleDiscardAndContinue = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);


    const handleReorder = (e) => {
        let newDataSource = rows;

        newDataSource.splice(e.fromIndex, 1);
        newDataSource.splice(e.toIndex, 0, e.itemData);

        newDataSource.map((item, index) => {
            item.row = index + 1;
        });

        setRows(newDataSource);

        checkChanges();
        gridRef.current.instance().refresh();
    };
    return (

        <Popup
            title={`${isCreate ? "Toevoegen pagina" : "Wijzigen pagina"}`}
            className="bl-popup"
            showTitle={true}
            resizeEnabled={true}
            visible={true}
            showCloseButton={true}
            onHiding={(e) => {

                setIsEditing(false);

            }}
            toolbarItems={[
                {
                    toolbar: "top",
                    widget: "dxButton",
                    location: "after",

                    options:
                    {
                        icon: "fa-solid fa-floppy-disk-circle-xmark",

                        stylingMode: 'text',
                        type: 'default',

                        onClick: (e) => {
                            handleSaveClick();

                        }
                    }
                }
            ]}

        >




            <div className="">

                {loading && <div />}
                {!loading && (
                    <div >
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Naam</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <TextBox
                                        type="input"
                                        defaultValue={pageData.name}
                                        valueChangeEvent="keyup"
                                        className={isCreate && "bl-mandatory-field"}
                                        onValueChanged={(e) => {
                                            let tempPageDate = pageData;

                                            tempPageDate["name"] = e.value;
                                            setPageData(tempPageDate);
                                            checkChanges();
                                            if (e.value.trim() === "") {
                                                e.element.classList.add("bl-mandatory-field");
                                            } else {
                                                e.element.classList.remove("bl-mandatory-field");
                                            }
                                        }}
                                    />
                                </Col>
                                <Col lg="3">
                                    <Form.Label>Paginatitel komt uit kolom</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <SelectBox
                                        dataSource={columnData}
                                        defaultValue={pageData.titleColumnId}
                                        valueExpr="id"
                                        className={isCreate && "bl-mandatory-field"}
                                        displayExpr="name"
                                        onValueChanged={(e) => {
                                            let tempPageData = pageData;
                                            e.element.classList.remove("bl-mandatory-field");
                                            tempPageData["titleColumnId"] = e.value;
                                            setPageData(tempPageData);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Snel toevoegen? (Plusje rechtsboven)</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={pageData.quickCreate ?? false}
                                        onValueChanged={(e) => {
                                            let tempPageDate = pageData;

                                            tempPageDate["quickCreate"] = e.value;
                                            setPageData(tempPageDate);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                                <Col lg="3">
                                    <Form.Label>Zichtbaar als tab</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={pageData.visibleAsTab}
                                        onValueChanged={(e) => {
                                            let tempPageDate = pageData;
                                            tempPageDate["visibleAsTab"] = e.value;
                                            setPageData(tempPageDate);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>


                        <h2>Rijen</h2>
                        <DataGrid
                            dataSource={rows}
                            showBorders={true}
                            ref={gridRef}
                            showColumnLines={false}
                            rowAlternationEnabled={true}
                            className="bl-vertical-center-align"
                        >
                            <Column caption="Rij" dataField="row" width={60}></Column>
                            <Column
                                width="20%"
                                caption="Kolom 1"
                                cellRender={columnOneRender}
                            ></Column>
                            <Column lg="1"
                                width="10%"
                                cellRender={columnOneRelativeWidthRender}
                            ></Column>
                            <Column lg="3"
                                width="20%"
                                caption="Kolom 2"
                                cellRender={columnTwoRender}
                            ></Column>
                            <Column lg="1"
                                width="10%"
                                cellRender={columnTwoRelativeWidthRender}
                            ></Column>
                            <Column lg="3"
                                width="20%"
                                caption="Kolom 3"
                                cellRender={columnThreeRender}
                            ></Column>
                            <Column lg="1"
                                width="10%"
                                cellRender={columnThreeRelativeWidthRender}
                            ></Column>
                            <Column
                                width={60}
                                cellRender={deleteControl}
                                headerCellRender={addNewButton}
                            />
                            <RowDragging
                                allowReordering={true}
                                onDragEnd={handleReorder}
                                showDragIcons={true}
                            />
                        </DataGrid>

                        <header className="bl-config-section-header"></header>

                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Voldoet aan</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <TextBox
                                        type="input"
                                        defaultValue={pageData.queryWhere}
                                        placeholder="Voldoet aan..."
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempPageDate = pageData;

                                            tempPageDate["queryWhere"] = e.value;
                                            setPageData(tempPageDate);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        {isDocumentarian && (<Form.Group>
                            <Row>
                                <Col lg="12">
                                    <Form.Label>Gebruikershandleiding:</Form.Label>
                                </Col>
                                <Col lg="12">
                                    <HtmlEditor
                                        defaultValue={pageData['userGuide']}
                                        valueType="html"
                                        onValueChanged={handleUserGuideChange}
                                        className="bl-html-editor"
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar multiline={true}>
                                            <Item name="undo" />
                                            <Item name="redo" />
                                            <Item name="separator" />
                                            <Item name="size" acceptedValues={sizeValues} />
                                            <Item name="font" acceptedValues={fontValues} />
                                            <Item name="separator" />
                                            <Item name="bold" />
                                            <Item name="italic" />
                                            <Item name="strike" />
                                            <Item name="underline" />
                                            <Item name="separator" />
                                            <Item name="alignLeft" />
                                            <Item name="alignCenter" />
                                            <Item name="alignRight" />
                                            <Item name="alignJustify" />
                                            <Item name="separator" />
                                            <Item name="orderedList" />
                                            <Item name="bulletList" />
                                            <Item name="separator" />
                                            <Item name="header" acceptedValues={headerValues} />
                                            <Item name="separator" />
                                            <Item name="color" />
                                            <Item name="background" />
                                            <Item name="separator" />
                                            <Item name="link" />
                                            <Item name="image" />
                                            <Item name="separator" />
                                            <Item name="clear" />
                                            <Item name="codeBlock" />
                                            <Item name="blockquote" />
                                            <Item name="separator" />
                                            <Item name="insertTable" />
                                            <Item name="deleteTable" />
                                            <Item name="insertRowAbove" />
                                            <Item name="insertRowBelow" />
                                            <Item name="deleteRow" />
                                            <Item name="insertColumnLeft" />
                                            <Item name="insertColumnRight" />
                                            <Item name="deleteColumn" />
                                        </Toolbar>
                                    </HtmlEditor>
                                </Col>
                            </Row>
                        </Form.Group>)}
                    </div>
                )}
            </div>


            {throwNotification && (
                <UserLeave
                    handleCancel={() => setThrowNotification(false)}
                    handleSaveAndContinue={handleSaveAndContinue}
                    handleDiscardAndContinue={handleDiscardAndContinue}
                />
            )}

        </Popup>
    );
};

export default EditPage;
