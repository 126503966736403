import PowerReportPivotGrid from "./PowerReportPivotGrid.js";
import PowerReportTable from "./PowerReportTable.js";
import React, { useState, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import UpsertQueryVisual from "../../components/configurator/dashboards/upsertPowerReport.js";
import { PowerReportRepresentationType } from "../../enums/powerReport.js";
import BloomFooter from "../footer/BloomFooter.js";

const PowerReportWrapper = ({
    dataSource,
    showChart,
    horizontalScrolling,
    showFieldChooser,
    title,
    powerReportId,
    refreshPowerReport,
    isDetailviewObject,
    powerReportRepresentationTypeId,
    fields,
    tableItemId,
}) => {

    const [showPowerReportConfig, setShowPowerReportConfig] = useState();

    const onConfigurePowerReportClick = useCallback(async (e) => {
        setShowPowerReportConfig(true);
    }, [setShowPowerReportConfig]);

    const closeFunction = useCallback(async (e) => {
        setShowPowerReportConfig(false);
        refreshPowerReport();
    }, [refreshPowerReport]);

    return (
        <>
            <Helmet>
                <title>
                    {!isDetailviewObject && `${title}`}
                </title>
            </Helmet>

            <section className={`bl-powerreport ${!isDetailviewObject ? 'bl-card' : ''}`} >
                {powerReportRepresentationTypeId === PowerReportRepresentationType.PivotGrid && <PowerReportPivotGrid
                    tableItemId={tableItemId}
                    showChart={showChart}
                    showFieldChooser={showFieldChooser}
                    dataSource={dataSource}
                    title={title}
                />}
                {powerReportRepresentationTypeId === PowerReportRepresentationType.Table && <PowerReportTable
                    tableItemId={tableItemId}
                    title={title}
                    horizontalScrolling={horizontalScrolling}
                    showFieldChooser={showFieldChooser}
                    dataSource={dataSource}
                    fields={fields}
                />}
            </section>
            <footer className="bl-footer">
                <div title="Bewerk rapport" className="control-configurator-icon"><i className={"fa-regular fa-list"} onClick={onConfigurePowerReportClick} /></div>
                <BloomFooter />
            </footer>

            {showPowerReportConfig && <UpsertQueryVisual
                showPopup={closeFunction}
                editQueryVisualId={powerReportId}
            //closeFunction={closeFunction}
            />}
        </>)
};
export default PowerReportWrapper