import React, { useState, useCallback } from "react";
import { Popup } from 'devextreme-react/popup';
import PowerReportDataFetcher from "../../../components/powerReport/PowerReportDataFetcher.js";

const PopupPowerReport = ({
    powerReportId,
    closePowerReport
}) => {
    const [fullScreen, setFullScreen] = useState(false);

    const onHiding = useCallback(() => { closePowerReport() }, [closePowerReport]);

    return (<Popup
        className="bl-popup"
        showTitle={true}
        animation={false}
        hideOnOutsideClick={true}
        //title={title}
        resizeEnabled={true}
        visible={true}
        showCloseButton={true}
        onHiding={onHiding}
        fullScreen={fullScreen}
        toolbarItems={[
            {
                toolbar: "bottom",
                icon: "email",
                stylingMode: 'contained',
                widget: "dxButton",
                location: "before",
                options:
                {
                    icon: 'fullscreen',
                    text: 'Volledig scherm',
                    useSubmitBehavior: false,
                    onClick: (e) => { setFullScreen(!fullScreen) }

                }


            }]}
    >
        <PowerReportDataFetcher overrideId={powerReportId} />
    </Popup>)

}

export default PopupPowerReport;