import React, { useRef, useEffect, useCallback, useState } from "react";
import {
    PivotGrid as DynamicGrid,
    FieldChooser,
    Search,
    HeaderFilter,
    FieldPanel,
    Export,
    Scrolling,
    LoadPanel,
    PivotGridTypes,
    Paging,
    StateStoring,
} from 'devextreme-react/pivot-grid';
import Chart, {
    AdaptiveLayout,
    CommonSeriesSettings,
    Size,
    Tooltip,
    Format,
    Label,
    Legend,
} from 'devextreme-react/chart';
import saveAs from "file-saver";
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import CheckBox, { CheckBoxTypes } from 'devextreme-react/check-box';
import { ButtonGroup, Item } from 'devextreme-react/button-group';
import "../list/List.scss";
import "../list/parts.scss";

const PowerReportPivotGrid = ({
    dataSource,
    showChart,
    showFieldChooser,
    title,
    tableItemId
}) => {
    const chartRef = useRef(null);
    const powerReportRef = useRef(null);

    // Totals boolean    
    const [showColumnTotal, setShowColumnTotal] = useState(false);
    const [showColumnGT, setShowColumnGT] = useState(false);
    const [showRowTotal, setShowRowTotal] = useState(false);
    const [showRowGT, setRowGTValueChanged] = useState(true);

    const onShowColumnTotalValueChanged = useCallback((e) => {
        setShowColumnTotal(e.value);
    }, []);
    const onShowColumnGTValueChanged = useCallback((e) => {
        setShowColumnGT(e.value);
    }, []);
    const onShowRowTotalValueChanged = useCallback((e) => {
        setShowRowTotal(e.value);
    }, []);
    const onShowRowGTValueChanged = useCallback((e) => {
        setRowGTValueChanged(e.value);
    }, []);
    // end of totals boolean

    //State storing
    const storeStateValue = `stateStorage`;
    const statePrefix = 'stateStorage_';
    const localStorageKey = `${statePrefix}prg_${title}`;
    console.log("localstoragekey ", localStorageKey);
    const [storeState, setStoreState] = useState(localStorage.getItem(localStorageKey) !== null);

    const onStateStoreClick = useCallback((e) => {
        setStoreState(!storeState);
        if (storeState) {
            localStorage.removeItem(localStorageKey);

        }

    }, [storeState, localStorageKey]);

    const clearFilterClick = useCallback((e) => {
        if (storeState) {
            localStorage.setItem(localStorageKey, '');
        }
        // Devextreme supports no refresh of its pivotgrid so we chose to reload the window
        window.location.reload();
    }, [localStorageKey, storeState]

    )

    //


    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(`${title}`);
        exportPivotGrid({
            component: e.component,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${title}.xlsx`);
            });
        });
    };

    useEffect(() => {
        if (showChart) {
            powerReportRef.current.instance().bindChart(chartRef.current.instance(), {
                dataFieldsDisplayMode: 'splitPanes',
                alternateDataFields: false,
            });
            chartRef.current.instance().option({
                tooltip: {
                    enabled: true,
                    customizeTooltip: function (arg) {
                        // Access PivotGrid data source to get fields
                        const splitSeries = arg.seriesName.split('|');
                        const caption = splitSeries;
                        const pivotGridDataSource = powerReportRef.current.instance().getDataSource();
                        const fields = pivotGridDataSource.getAreaFields('data', true);


                        // Find the field corresponding to this value
                        const field = fields.find(f => f.caption === caption);
                        const backupField = fields.find(f => (f.format !== null && f.caption !== caption));

                        if (field && field.format)
                            return {
                                html: `<div class="powerreport-tooltip">${arg.seriesName}<br>${Intl.NumberFormat('nl-NL', field.format).format(arg.originalValue)}</div>`,
                            }
                        if (!field && backupField.format)
                            return {
                                html: `<div class="powerreport-tooltip">${arg.seriesName}<br>${Intl.NumberFormat('nl-NL', backupField.format).format(arg.originalValue)}</div>`,
                            }
                        else {
                            // Default behavior if no specific format is found
                            return {
                                html: `${arg.seriesName} <br><div class="currency">${Intl.NumberFormat('nl-NL', '#,##0').format(arg.originalValue)}</div>`,
                            };
                        }
                    },
                },
                label: {
                    visible: true,
                },
            });
        }
    }, [dataSource, showChart]);

    const handleCellPrepared = useCallback(async ({ cell, area, cellElement, component }) => {

        if (area === 'row' && cell.type === 'D') {
            const displayedFields = component.getDataSource().getAreaFields('row')
            const rowPathLength = cell.path.length;
            const field = displayedFields[rowPathLength - 1]
            if (field.detailviewId == null)
                return;

            const ddds = component.getDataSource().createDrillDownDataSource()
            ddds.paginate(false);
            const data = await ddds.load();
            const recordsOfClickedValue = data.filter(rec => rec[field.dataField] === cell.path[cell.path.length - 1]);
            const detailviewName = field.detailviewName;
            const recordId = recordsOfClickedValue.map(rec => rec[field.keyPropertyName?.toLowerCase()])[0];
            const innerHTML = `<a href="../detail/${field.detailviewId}/${recordId}" target="_blank" title="${detailviewName} | ${cell.text}"><span class="powerreporticon dx-icon-link" tabindex="0" role="button"/></a>  <span className="powerreportlabel">${cell.text}</span>`;
            cellElement.innerHTML = innerHTML
        }
        if (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT') {
            cellElement.innerHTML = `<strong>${cell.text}</strong>`;

        }
        else return;


    }, [])

    // const storeStateClick = useCallback(() => {

    //     const newValue = !storeState;

    //     console.log("newvalue", newValue, "storestate", storeState)
    //     const currentInternalState = powerReportRef?.current?.instance().state();
    //     console.log("current internal state: ", powerReportRef)
    //     localStorage.setItem(storeStateValue, newValue);
    //     if (newValue) {
    //         localStorage.setItem(localStorageKey, JSON.stringify(currentInternalState));
    //     } else {
    //         const itemsToRemove = Object.keys(localStorage).filter(item => item.startsWith(statePrefix));
    //         for (var c = 0; c < itemsToRemove.length; c++) {
    //             localStorage.removeItem(itemsToRemove[c]);
    //         }
    //         powerReportRef?.current?.instance().state(null);
    //     }
    //     setStoreState(newValue);
    // }, [storeState, setStoreState, localStorageKey, storeStateValue]);

    // const onRefreshClick = () => {
    //     powerReportRef?.current?.instance().updateDimensions();
    // };

    const onRefreshClick = useCallback(() => {
        // Devextreme supports no refresh of its pivotgrid so we chose to reload the window
        window.location.reload();
    }, []);


    const handleFieldChooserOptionChanged = useCallback((e) => { console.log("handleFieldChooserOptionChanged", e); }, []);

    return (<>
        <header className="bloom-list-header">
            <h3 className="listHeader">{`${title}`}</h3>
            <ButtonGroup
                selectionMode="none"
                stylingMode="outlined"
                focusStateEnabled={false}
            // onItemClick={({ event, itemElement }) => {
            //     event.preventDefault();
            // }}
            >
                <Item
                    icon="fas fa-sync-alt"
                    hint="Vernieuw data"
                    onClick={onRefreshClick}
                    type="default"
                />
                <Item
                    icon="fas fa-filter"
                    type={storeState ? "success" : "default"}
                    hint={`Filters opslaan staat ${storeState ? "aan" : "uit"}`}
                    onClick={onStateStoreClick}

                />
                <Item
                    icon="fas fa-eraser"
                    type="default"
                    hint="Wis filters"
                    onClick={clearFilterClick} />

            </ButtonGroup>
        </header>
        <section>
            {showChart &&
                <>
                    <h4 className="bloom-list-header listHeader">{`Grafiek`}</h4>
                    <Chart ref={chartRef}>
                        <Size height={"275"} />
                        <Tooltip
                            enabled={showChart}
                        />
                        <CommonSeriesSettings
                            type="bar"
                        // hoverMode="allArgumentPoints"
                        // selectionMode="allArgumentPoints"
                        />
                        {/* <Label visible={true} >
                            <Format
                                type="fixedPoint"
                                precision={0} />
                        </Label> */}
                        <Legend
                            verticalAlignment="top"
                            wordWrapEnabled={true}
                            width={200}
                            horizontalAlignment="right">

                        </Legend>
                    </Chart>
                    <h4 className="bloom-list-header listHeader">{`Draaitabel`}</h4>
                </>
            }

            <DynamicGrid
                id="dynamicgrid"
                dataSource={dataSource}
                allowSorting={true}
                allowSortingBySummary={true}
                allowFiltering={true}
                allowExpandAll={true}
                allowSearch={true}
                // height={showChart ? "50vh" : "calc(1000px-600px)"}
                height={showChart ? "calc(100vh - 700px)" : "calc(100vh - 350px)"}
                showBorders={true}
                wordWrapEnabled={false}
                showColumnTotals={showColumnTotal}
                showColumnGrandTotals={showColumnGT}
                showRowTotals={showRowTotal}
                showRowGrandTotals={showRowGT}
                onExporting={onExporting}
                ref={powerReportRef}
                onCellPrepared={handleCellPrepared}

            >
                <HeaderFilter
                    showRelevantValues={true}
                >
                    <Search enabled={true} />
                </HeaderFilter>
                <StateStoring
                    enabled={storeState}
                    type="localStorage"
                    storageKey={localStorageKey}
                />
                <LoadPanel
                    enabled={true}
                    showPane={true}
                    height={200}
                    width={300}
                    text="Data wordt samengesteld, dit kan even duren"
                />
                <Scrolling mode="virtual" />
                {showFieldChooser &&
                    <FieldChooser
                        allowSearch={true}
                        onContentReady={handleFieldChooserOptionChanged}
                    />

                }
                <FieldPanel visible={true} />
                <Export enabled={true} />
            </DynamicGrid>
            <div className="powerreportoptions">
                <h4 className="bloom-list-header listHeader">Totalen</h4>
                <div className="powerreportoption">
                    <CheckBox
                        text="Toon kolomsubtotaal"
                        value={showColumnTotal}
                        onValueChanged={onShowColumnTotalValueChanged}
                    />
                </div>
                &nbsp;
                <div className="powerreportoption">
                    <CheckBox
                        text="Toon kolomeindtotaal"
                        value={showColumnGT}
                        onValueChanged={onShowColumnGTValueChanged}
                    />
                </div>
                &nbsp;
                <div className="powerreportoption">
                    <CheckBox
                        text="Toon rijsubtotalen"
                        value={showRowTotal}
                        onValueChanged={onShowRowTotalValueChanged}
                    />
                </div>
                &nbsp;
                <div className="powerreportoption">
                    <CheckBox
                        text="Toon rij-eindtotalen"
                        value={showRowGT}
                        onValueChanged={onShowRowGTValueChanged}
                    />
                </div>
            </div>
        </section>
    </>)
};
export default PowerReportPivotGrid;