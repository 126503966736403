import { useMsal } from "@azure/msal-react";
import React, { useCallback, useEffect, useState } from "react";
import msalFetch from "../../../../../api/MsalFetch.js";
import appSettings from "../../../../../appsettings";
import Resizable from 'devextreme-react/resizable';
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import Button from 'devextreme-react/button';
import {
    CheckBox,
    DateBox,
    NumberBox,
    SelectBox,
    TextArea,
} from "devextreme-react";
import moment from "moment";
import Loading from "../../../../loading/Loading";
import { confirm } from 'devextreme/ui/dialog';

const EMail = ({ fieldValue, setFieldValue, checkChanges, isCreate }) => {
    const msal = useMsal();
    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState();
    const [reportFiles, setReportFiles] = useState();
    const [recurring, setRecurring] = useState(
        fieldValue.recurring ? fieldValue.recurring : false
    );
    const [startDate, setStartDate] = useState();
    const [displayNextLabel, setDisplayNextLabel] = useState();
    const [nextExecution, setNextExecution] = useState({
        start: "",
        interval: "",
        specification: "",
        output: "",
    });
    const reportFormats = [
        { value: "XLSX", display: "Excel" },
        { value: "PDF", display: "PDF" },
    ];

    const intervalSpecifications = [
        { v: "minute", d: "kwartier" },
        { v: "hour", d: "uur" },
        { v: "day", d: "dag" },
        { v: "week", d: "week" },
        { v: "month", d: "maand" },
    ];
    const now = new Date();
    const calculateNextDate = (startDateTime, interval, specification) => {
        let tempStartDate = new Date(startDateTime);

        let nextDate;

        let tempFieldValue = fieldValue;

        // let tempNextExecution = nextExecution;

        if (specification === "minute") {
            nextDate = new Date(tempStartDate.getTime() + interval * 15 * 60000);

            //   return tempNextExecution;
        } else if (specification === "hour") {
            nextDate = new Date(tempStartDate.getTime() + interval * 60 * 60 * 1000);
        } else if (specification === "day") {
            nextDate = new Date(
                tempStartDate.getTime() + interval * 24 * 60 * 60 * 1000
            );
        } else if (specification === "week") {
            nextDate = new Date(
                tempStartDate.getTime() + interval * 7 * 24 * 60 * 60 * 1000
            );
        } else if (specification === "month") {
            nextDate = tempStartDate;
            nextDate.setMonth(nextDate.getMonth() + interval);
        }
        updateDisplayLabel(nextDate);
    };

    const getUsers = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/user`
            );
            const data = await response.json();
            const users = data
                .filter(user => user.active)
                .map(user => {
                    return { name: `${user.firstname} ${user.name}`, id: user.id }
                })
                .sort((a, b) => { return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1; });
            const usersWithLoggedInUser = [{ name: "Ingelogde gebruiker", id: null }].concat(users);
            setUsers(usersWithLoggedInUser);

            setLoading(false);
            return usersWithLoggedInUser;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };
    const stylObj = { height: '300px' };

    useEffect(() => {
        if (fieldValue.action && fieldValue.action.nextExecution) {

            let tempString = fieldValue.action.nextExecution.replace("T", " ");
            const nextDate = new Date(tempString);
            const specification = fieldValue.action.intervalSpecification;
            const interval = fieldValue.action.interval;
            // if (!recurring) return;
            setStartDate(fieldValue.action.nextExecution);

            calculateNextDate(nextDate, interval, specification);
        }
    }, []);

    const checkReportin = (value) => {
        setReport(value);
    };

    const checkRecurring = (value) => {
        setRecurring(value);
    };
    const getReports = async () => {
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/report/all`
            );
            const data = await response.json();

            setReportFiles(data);
            return;
        } catch (error) {
            throw "Data Loading Error";
        }
    };

    const updateNextExecution = (output) => {
        setNextExecution(output);
    };


    const updateDisplayLabel = (output) => {
        const dt = moment(output).format('DD-MM-yyyy HH:mm');

        setDisplayNextLabel(
            output
        );
    };

    useEffect(() => {
        getUsers();
        getReports();
        if (fieldValue.action) {
            checkReportin(fieldValue.action.report);
            checkRecurring(fieldValue.action.recurring);
        }
    }, []);

    const handleCancelTask = useCallback(async (e) => {
        let result = await confirm("Weet u zeker dat u deze taak wilt afbreken?", "Taak afbreken?");
        if (!result)
            return;
        const response = await msalFetch(null,
            `${appSettings.api.endpoint}/api/action/cancelrunningtask/${fieldValue.action.id}`
        );
    }, [fieldValue.action.id])

    if (loading) {
        return <Loading />;
    } else {
        return (
            <>
                <header className="bl-config-section-header">
                    <h2> Queries</h2>
                </header>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Query</Form.Label>
                        </Col>
                        <Col lg="12">
                            <Resizable
                                minHeight={100}
                                style={stylObj}
                                maxHeight={600}
                                handles={"bottom"}
                                area='.widget-container .dx-field'
                            >
                                <TextArea
                                    height={"100%"}
                                    defaultValue={
                                        fieldValue.action && fieldValue.action.actionQuery
                                            ? fieldValue.action.actionQuery
                                            : ""
                                    }
                                    className={
                                        isCreate ||
                                            fieldValue.action.actionQuery === null ||
                                            fieldValue.action.actionQuery === ""
                                            ? "bl-mandatory-field"
                                            : ""
                                    }
                                    placeholder="SELECT Id, Naam FROM Table WHERE Id = [Id]"
                                    valueChangeEvent="keyup"
                                    onValueChanged={(e) => {
                                        if (
                                            e.value.trim() === "" &&
                                            !e.element.classList.contains("bl-mandatory-field")
                                        ) {
                                            e.element.classList.add("bl-mandatory-field");
                                        } else {
                                            e.element.classList.remove("bl-mandatory-field");
                                        }
                                        let tempFieldValue = fieldValue;
                                        tempFieldValue.action["actionQuery"] = e.value;
                                        setFieldValue(tempFieldValue);

                                        checkChanges();
                                    }}
                                />
                            </Resizable>
                            <footer className="bl-label-footer">
                                LET OP! Deze query moet 1 resultaat opleveren. Dit is te
                                bereiken door `WHERE Id = [Id]` toe te voegen.
                            </footer>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Query na actie</Form.Label>
                        </Col>
                        <Col lg="12">
                            <Resizable
                                minHeight={100}
                                style={stylObj}
                                maxHeight={600}
                                handles={"bottom"}
                                area='.widget-container .dx-field'
                            >
                                <TextArea
                                    height={"100%"}
                                    defaultValue={
                                        isCreate && !fieldValue.action.actionQueryNaActie
                                            ? ""
                                            : fieldValue.action.actionQueryNaActie
                                    }
                                    placeholder="UPDATE Table SET Status = 9 WHERE Id = [Id]"
                                    valueChangeEvent="keyup"
                                    onValueChanged={(e) => {
                                        let tempFieldValue = fieldValue;
                                        tempFieldValue.action["actionQueryNaActie"] = e.value;
                                        setFieldValue(tempFieldValue);

                                        checkChanges();
                                    }}
                                />
                            </Resizable>
                            <footer className="bl-label-footer">
                                LET OP! Deze query moet 1 resultaat opleveren. Dit is te
                                bereiken door `WHERE Id = [Id]` toe te voegen.
                            </footer>
                        </Col>
                    </Row>
                </Form.Group>
                <header className="bl-config-section-header">
                    <h2> Mail</h2>
                </header>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Verzenden namens</Form.Label>
                        </Col>
                        <Col lg="12">
                            <SelectBox
                                searchEnabled
                                defaultValue={
                                    fieldValue.action && fieldValue.action.mailFromUserId
                                }
                                items={users}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.action["mailFromUserId"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Verzenden naar</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                defaultValue={
                                    fieldValue.action &&
                                    fieldValue.action.mailTo &&
                                    fieldValue.action.mailTo
                                }
                                className={
                                    isCreate ||
                                        fieldValue.action.mailTo === null ||
                                        fieldValue.action.mailTo === ""
                                        ? "bl-mandatory-field"
                                        : ""
                                }
                                placeholder="[MailTo]"
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    if (
                                        e.value.trim() === "" &&
                                        !e.element.classList.contains("bl-mandatory-field")
                                    ) {
                                        e.element.classList.add("bl-mandatory-field");
                                    } else {
                                        if (e.value.includes("@") || e.value.includes("[")) {
                                            e.element.classList.remove("bl-mandatory-field");
                                        } else {
                                            e.element.classList.add("bl-mandatory-field");
                                        }
                                    }
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.action["mailTo"] = e.value;
                                    setFieldValue(tempFieldValue);

                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Verzenden CC</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                defaultValue={
                                    fieldValue.action &&
                                    fieldValue.action.mailToCC &&
                                    fieldValue.action.mailToCC
                                }
                                placeholder="[CC]"
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.action["mailToCC"] = e.value;
                                    setFieldValue(tempFieldValue);

                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Onderwerp</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                defaultValue={
                                    fieldValue.action &&
                                    fieldValue.action.mailSubject &&
                                    fieldValue.action.mailSubject
                                }
                                className={
                                    isCreate ||
                                        fieldValue.action.mailSubject === null ||
                                        fieldValue.action.mailSubject === ""
                                        ? "bl-mandatory-field"
                                        : ""
                                }
                                placeholder="Uw opdrachtbevestiging met nummer [Opdrachtnummer]"
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    if (
                                        e.value.trim() === "" &&
                                        !e.element.classList.contains("bl-mandatory-field")
                                    ) {
                                        e.element.classList.add("bl-mandatory-field");
                                    } else {
                                        e.element.classList.remove("bl-mandatory-field");
                                    }
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.action["mailSubject"] = e.value;
                                    setFieldValue(tempFieldValue);

                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Body</Form.Label>
                        </Col>
                        <Col lg="12">
                            <Resizable
                                minHeight={200}

                                style={stylObj}
                                maxHeight={900}
                                handles={"bottom"}
                            // area='.widget-container .dx-field'
                            >
                                <TextArea
                                    height={"100%"}
                                    defaultValue={
                                        fieldValue.action &&
                                        fieldValue.action.mailBody &&
                                        fieldValue.action.mailBody
                                    }
                                    className={
                                        isCreate ||
                                            fieldValue.action.mailBody === null ||
                                            fieldValue.action.mailBody === ""
                                            ? "bl-mandatory-field"
                                            : ""
                                    }
                                    placeholder="Beste [Naam],"
                                    valueChangeEvent="keyup"
                                    onValueChanged={(e) => {
                                        if (
                                            e.value.trim() === "" &&
                                            !e.element.classList.contains("bl-mandatory-field")
                                        ) {
                                            e.element.classList.add("bl-mandatory-field");
                                        } else {
                                            e.element.classList.remove("bl-mandatory-field");
                                        }
                                        let tempFieldValue = fieldValue;
                                        tempFieldValue.action["mailBody"] = e.value;
                                        setFieldValue(tempFieldValue);

                                        checkChanges();
                                    }}
                                />
                            </Resizable>
                        </Col>
                    </Row>
                </Form.Group>
                <header className="bl-config-section-header">
                    <h2> Rapport</h2>
                </header>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Report</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                defaultValue={
                                    fieldValue.action && fieldValue.action.report
                                        ? fieldValue.action.report
                                        : false
                                }
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.action["report"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    setReport(e.value);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <section
                    // key={report}
                    className={`bl-accordion ${report && "bl-accordion-expanded"}`}
                >
                    {report && (
                        <div className="bl-accordion-content">
                            <Form.Group>
                                <Row>
                                    <Col lg="12">
                                        <Form.Label>Naam</Form.Label>
                                    </Col>
                                    <Col lg="12">
                                        <SelectBox
                                            searchEnabled
                                            className={
                                                isCreate ||
                                                    (fieldValue.action.reportId === null && report)
                                                    ? "bl-mandatory-field"
                                                    : ""
                                            }
                                            defaultValue={fieldValue.action.reportId}
                                            items={reportFiles}
                                            valueExpr="reportId"
                                            displayExpr="displayName"
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue.action["reportId"] = e.value;
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                                e.element.classList.remove("bl-mandatory-field");
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col lg="12">
                                        <Form.Label>Parameters</Form.Label>
                                    </Col>
                                    <Col lg="12">
                                        <TextBox
                                            defaultValue={
                                                fieldValue.action &&
                                                fieldValue.action.reportParameters &&
                                                fieldValue.action.reportParameters
                                            }
                                            placeholder={`Parameter1=[Parameter1]&Parameter2=[Parameter2]`}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue.action["reportParameters"] = e.value;
                                                setFieldValue(tempFieldValue);

                                                checkChanges();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col lg="12">
                                        <Form.Label>Filename</Form.Label>
                                    </Col>
                                    <Col lg="12">
                                        <TextBox
                                            defaultValue={
                                                !isCreate &&
                                                fieldValue.action.reportFileName &&
                                                fieldValue.action.reportFileName
                                            }
                                            className={
                                                isCreate ||
                                                    fieldValue.action.reportFileName === null ||
                                                    fieldValue.action.reportFileName === ""
                                                    ? "bl-mandatory-field"
                                                    : ""
                                            }
                                            placeholder="[Bestandsnaame]_[Jaartal]"
                                            valueChangeEvent="keyup"
                                            onValueChanged={(e) => {
                                                if (
                                                    e.value.trim() === "" &&
                                                    !e.element.classList.contains("bl-mandatory-field")
                                                ) {
                                                    e.element.classList.add("bl-mandatory-field");
                                                } else {
                                                    e.element.classList.remove("bl-mandatory-field");
                                                }
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue.action["reportFileName"] = e.value;
                                                setFieldValue(tempFieldValue);

                                                checkChanges();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col lg="12">
                                        <Form.Label>Formaat</Form.Label>
                                    </Col>
                                    <Col lg="12">
                                        <SelectBox
                                            searchEnabled
                                            key={report}
                                            className={
                                                isCreate ||
                                                    (fieldValue.action &&
                                                        fieldValue.action.reportOutputFormat === null &&
                                                        report)
                                                    ? "bl-mandatory-field"
                                                    : ""
                                            }
                                            defaultValue={
                                                fieldValue.action &&
                                                fieldValue.action.reportOutputFormat
                                            }
                                            items={reportFormats}
                                            valueExpr="value"
                                            displayExpr="display"
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue.action["reportOutputFormat"] = e.value;
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                                e.element.classList.remove("bl-mandatory-field");
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </div>
                    )}
                </section>

                <header className="bl-config-section-header">
                    <h2> Achtergrondtaak</h2>
                </header>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Recurring</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                // className={
                                //   isCreate ||
                                //   (fieldValue.action && fieldValue.action.recurring === "")
                                //     ? "bl-mandatory-field"
                                //     : ""
                                // }
                                defaultValue={
                                    fieldValue.action && fieldValue.action.recurring
                                        ? fieldValue.action.recurring
                                        : false
                                }
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.action["recurring"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    setRecurring(e.value);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <section
                    key={recurring}
                    className={`bl-accordion ${recurring && "bl-accordion-expanded"}`}
                >
                    {recurring && (
                        <div className="bl-accordion-content">
                            {!isCreate &&
                                <Form.Group>
                                    <Row>
                                        <Col lg="12">
                                            <Form.Label>Afbreken</Form.Label>
                                        </Col>
                                        <Col lg="12">
                                            <Button
                                                stylingMode='contained'
                                                type="default"
                                                hint="Taak afbreken (indien lopend)"
                                                icon={`fas fa-rectangle-xmark`}
                                                onClick={handleCancelTask}
                                                disabled={!fieldValue.action.isRunning}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>}
                            <Form.Group>
                                <Row>
                                    <Col lg="12">
                                        <Form.Label>Massa query</Form.Label>
                                    </Col>
                                    <Col lg="12">
                                        <Resizable
                                            minHeight={100}
                                            height={300}
                                            maxHeight={600}
                                            handles={"bottom"}
                                            area='.widget-container .dx-field'
                                        >
                                            <TextArea
                                                height={"100%"}
                                                defaultValue={
                                                    !isCreate &&
                                                    fieldValue.action.actionMassaQuery &&
                                                    fieldValue.action.actionMassaQuery
                                                }
                                                className={
                                                    (isCreate &&
                                                        fieldValue.action.actionMassaQuery === null) ||
                                                        fieldValue.action.actionMassaQuery === ""
                                                        ? "bl-mandatory-field"
                                                        : ""
                                                }
                                                placeholder="SELECT Id FROM Table WHERE bl_modified > getdate()"
                                                valueChangeEvent="keyup"
                                                onValueChanged={(e) => {
                                                    if (
                                                        e.value.trim() === "" &&
                                                        !e.element.classList.contains("bl-mandatory-field")
                                                    ) {
                                                        e.element.classList.add("bl-mandatory-field");
                                                    } else {
                                                        e.element.classList.remove("bl-mandatory-field");
                                                    }
                                                    let tempFieldValue = fieldValue;
                                                    tempFieldValue.action["actionMassaQuery"] = e.value;
                                                    setFieldValue(tempFieldValue);

                                                    checkChanges();
                                                }}
                                            />
                                        </Resizable>
                                        <footer className="bl-label-footer">
                                            LET OP! Deze query levert de Id's aan voor de query bovenaan.
                                        </footer>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col lg="2">
                                        <Form.Label>Startmoment</Form.Label>
                                    </Col>
                                    <Col lg="2">
                                        <DateBox
                                            type="datetime"
                                            displayFormat="dd-MM-yyy HH:mm"
                                            min={now}
                                            className={
                                                isCreate || nextExecution === ""
                                                    ? "bl-mandatory-field"
                                                    : ""
                                            }
                                            defaultValue={
                                                fieldValue.action &&
                                                fieldValue.action.nextExecution &&
                                                fieldValue.action.nextExecution
                                            }

                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                calculateNextDate(
                                                    e.value,
                                                    fieldValue.action.interval,
                                                    fieldValue.action.intervalSpecification
                                                );
                                                e.element.classList.remove("bl-mandatory-field");
                                                tempFieldValue.action["nextExecution"] = e.value;
                                                setFieldValue(tempFieldValue);
                                                setStartDate(e.value);
                                                checkChanges();
                                            }}
                                        />
                                    </Col>
                                    <Col lg="1">
                                        <Form.Label>Volgend</Form.Label>
                                    </Col>
                                    <Col lg="2">
                                        <DateBox
                                            displayFormat="dd-MM-yyy HH:mm"
                                            value={displayNextLabel}
                                            readOnly

                                        />
                                    </Col>
                                    <Col lg="2">
                                        <Form.Label>Herhaal na</Form.Label>
                                    </Col>

                                    <Col lg="1">
                                        <NumberBox
                                            defaultValue={
                                                fieldValue.action && fieldValue.action.interval
                                            }
                                            className={
                                                isCreate ||
                                                    (recurring &&
                                                        fieldValue.action &&
                                                        fieldValue.action.interval === "")
                                                    ? "bl-mandatory-field"
                                                    : ""
                                            }
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue.action["interval"] = e.value;
                                                if (e.value > 0) {
                                                    e.element.classList.remove("bl-mandatory-field");
                                                } else {
                                                    e.element.classList.add("bl-mandatory-field");
                                                }
                                                setFieldValue(tempFieldValue);

                                                calculateNextDate(
                                                    startDate,
                                                    e.value,
                                                    tempFieldValue.action.intervalSpecification
                                                );
                                                checkChanges();
                                            }}
                                        />
                                    </Col>
                                    <Col lg="2">
                                        <SelectBox
                                            searchEnabled
                                            className={
                                                isCreate ||
                                                    (recurring &&
                                                        fieldValue.action.intervalSpecification === "")
                                                    ? "bl-mandatory-field"
                                                    : ""
                                            }
                                            items={intervalSpecifications}
                                            valueExpr="v"
                                            displayExpr="d"
                                            defaultValue={
                                                fieldValue.action &&
                                                fieldValue.action.intervalSpecification
                                            }
                                            onValueChanged={(e) => {
                                                let tempFieldValue = fieldValue;
                                                tempFieldValue.action["intervalSpecification"] =
                                                    e.value;

                                                e.element.classList.remove("bl-mandatory-field");
                                                calculateNextDate(
                                                    startDate,
                                                    tempFieldValue.action.interval,
                                                    e.value
                                                );
                                                setFieldValue(tempFieldValue);
                                                checkChanges();
                                                setNextExecution((e) => {
                                                    let temp = nextExecution;
                                                    return (temp["specification"] = e);
                                                });
                                            }}
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                        </div>
                    )}
                </section >
            </>
        );
    }
};
export default EMail;
