import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import appSettings from "../../../appsettings";
import "./configModalStyles.scss";
import { useModalContext } from "../../../context/modalContext";
import CreatorFooter from "../parts/CreatorFooter";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import msalFetch from "../../../api/MsalFetch.js";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox, RadioGroup, Lookup, Popup } from "devextreme-react";
import DataGrid, { Column, RowDragging } from "devextreme-react/data-grid";
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";
import { useParams } from "react-router-dom";
import { DropDownOptions } from "devextreme-react/lookup";
import Loading from "../../loading/Loading";
import { useGraphContext } from "../../graph/context";

import HtmlEditor, {
    Toolbar,
    MediaResizing,
    Item,
} from "devextreme-react/html-editor";

const headerValues = [false, 1, 2, 3, 4, 5];
const sizeValues = ["8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida"];

const EditList = ({
    columnData,
    allColumns,
    isCreate,
    isEditing,
    setIsEditing,
    getListData,
    dataSetId,
}) => {
    const msal = useMsal();
    const { currentUser } = useGraphContext();
    const {

        setFormInValidNotification,
        setNotificationData,

    } = useModalContext();


    const [pageData, setPageData] = useState([]);
    const [richAllColumns, setRichAllColumns] = useState([]);
    const [rerenderCOlumns, setRerenderColumns] = useState(false);

    const [throwNotification, setThrowNotification] = useState(false);
    const [loading, setLoading] = useState(true);
    const [columnLoading, setColumnLoading] = useState(true);
    //data sets

    const { id } = useParams();


    const [columnOptions, setColumnOptions] = useState([]);

    const isDocumentarian = currentUser?.documentarian === 1;

    const userColumns = [{ alias: "Niet filteren", id: null }].concat(
        allColumns.filter((t) => t.columnType && t.columnType.id === 63)
    );

    const sortIndices = [
        { v: null, d: null },
        { v: 1, d: '1' },
        { v: 2, d: '2' },
        { v: 3, d: '3' },
        { v: 4, d: '4' },
    ];

    const sortDirections = [
        { v: null, d: null },
        { v: 'asc', d: 'Oplopend' },
        { v: 'desc', d: 'Aflopend' },
    ]

    const groupIndices = [
        { v: null, d: null },
        { v: 1, d: '1' },
        { v: 2, d: '2' },
        { v: 3, d: '3' },
        { v: 4, d: '4' },
    ];

    const defaultFormData = useMemo(() => {
        return {
            NewButton: true,
            join: "LEFT JOIN",
            listColumns: [],
            preferredDetailViewId: null,
            allowExportToExcel: false,
            allowCopy: false,
            inlineEditing: false,
            autoRefresh: true,
            scrollHorizontal: false
        }
    }, []);

    const manualChangeOrderByColumns = [
        { alias: "Niet handmatig sorteren", id: null },
    ].concat(
        allColumns.filter(
            (t) =>
                t.type === "int" &&
                !t.primairyKey &&
                (t.inputTableId == null || t.inputTableId === 0) &&
                !t.name.includes("bl_")
        )
    );

    //bools
    const [pageLoading, setPageLoading] = useState(true);

    const [formValue, setFormValue] = useState(
        isCreate ?
            /*{
                NewButton: true,
                join: "LEFT JOIN",
                listColumns: [],
                preferredDetailViewId: null,

            }*/
            defaultFormData
            : columnData
    );
    const [allowSqlSort, setAllowSqlSort] = useState(formValue.manualChangeOrderByColumnId == null);

    const [manualChangeOrderByColumnId, setManualChangeOrderByColumnId] = useState(formValue.manualChangeOrderByColumnId);
    const [pageLookups, setPageLookups] = useState([]);
    const [expandDetailviews, setExpandDetailviews] = useState([
        { id: null, name: "Geen" },
    ]);

    const [changeInForm, setChangeInForm] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [listGridData, setListGridData] = useState([]);

    const dataGridRef = useRef();



    const joinOptions = [
        { joinValue: "LEFT JOIN", displayValue: "Left join" },
        { joinValue: "INNER JOIN", displayValue: "Inner join" },

    ];

    const columnFunctions = [
        { function: "", label: "" },
        { function: "SUM", label: "Som" },
        { function: "MIN", label: "Min" },
        { function: "MAX", label: "Max" },
        { function: "AVG", label: "Gemiddeld" },
        { function: "COUNT", label: "Aantal" },
    ];
    const columnWidths = [
        { width: 1, displayWidth: "1/12" },
        { width: 2, displayWidth: "2/12" },
        { width: 3, displayWidth: "3/12" },
        { width: 4, displayWidth: "4/12" },
        { width: 5, displayWidth: "5/12" },
        { width: 6, displayWidth: "6/12" },
        { width: 7, displayWidth: "7/12" },
        { width: 8, displayWidth: "8/12" },
        { width: 9, displayWidth: "9/12" },
        { width: 10, displayWidth: "10/12" },
        { width: 11, displayWidth: "11/12" },
        { width: 12, displayWidth: "12/12" },
    ];

    const getPages = async () => {
        setPageLoading(true);

        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset/`
            );
            const data = await response.json();

            setPageLoading(false);
            setLoading(false);
            return data;
        } catch (error) {
            setPageLoading(false);
            throw "Data Loading Error";
        }
    };

    const filterColumnOptions = (listColumns, columns) => {
        let tempSelection = listColumns;
        let newSelection = [];

        if (tempSelection.length > 0) {
            columns.map((item) => {
                if (tempSelection.find((t) => item.id === t.columnId)) {
                    return;
                } else {
                    newSelection.push(item);
                }
            });

            setColumnOptions(newSelection);
            setRerenderColumns(!rerenderCOlumns);
            setColumnLoading(false);
        }
    };

    const getPageData = async () => {
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset/${id}/page`
            );
            const data = await response.json();
            let tempPageLookups = ["..."];
            let tempExpandDetailview = expandDetailviews;

            setPageData(data);

            if (data.length > 0) {
                data.map((item) => {
                    tempPageLookups = [...tempPageLookups, item.name];
                    tempExpandDetailview.push(item);
                });

                setPageLookups(tempPageLookups);
                setExpandDetailviews(tempExpandDetailview);
            }

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    };

    useEffect(() => {
        let tempColumnOptions = [];

        allColumns.map((t) => {
            let tempcolumn = t;
            tempcolumn["value"] = t.id;
            tempcolumn["label"] = t.alias;
            tempColumnOptions.push(tempcolumn);
        });

        setRichAllColumns(tempColumnOptions);
        if (columnData && columnData.listColumns) {
            filterColumnOptions(columnData.listColumns, tempColumnOptions);
        } else {
            setColumnLoading(false);
            setColumnOptions(tempColumnOptions);
        }

        getPageData();
        getPages();

        //get rid of ID row and sort the array
    }, []);

    useEffect(() => {
        if (isCreate) {
            setFormValue(defaultFormData /*{
                NewButton: true,
                join: "LEFT JOIN",
                listColumns: [],

                preferredDetailViewId: null,
            }*/);
        } else {
            let tempFormValue = columnData;

            let tempListGrid = columnData.listColumns;
            tempListGrid.map((t) => (t["id"] = t.sort));

            tempFormValue.listColumns = tempListGrid;

            setFormValue(tempFormValue);
        }

        let tempListGridData = [];
        if (formValue.listColumns.length > 0) {
            formValue.listColumns.map((item) => {
                if (item.sort !== 0) {
                    return (tempListGridData = [...tempListGridData, item]);
                }
            });

            tempListGridData.sort((a, b) => {
                return a.sort - b.sort;
            });

            setListGridData(tempListGridData);
        }
    }, [refresh]);

    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const handleDetailviewChange = (e) => {
        let tempFormValue = formValue;

        let newValue = pageData.find((item) => item.name === e.value);

        if (e.value === "...") {
            if (tempFormValue.detailView) {
                tempFormValue["detailView"] = null;
            }
            tempFormValue["preferredDetailViewId"] = null;
        } else {
            if (tempFormValue.detailView) {
                tempFormValue["detailView"] = newValue;
            }

            tempFormValue["preferredDetailViewId"] = newValue.id;
        }

        checkChanges();
        setFormValue(tempFormValue);
        //   tempFormValue['detailview']
    };

    const handleCheckboxValueChange = (dataField) => (e) => {
        console.log("handleCheckboxValueChange", e.value);
        let tempFormValue = formValue;
        tempFormValue[dataField] = e.value;

        checkChanges();
        setFormValue(tempFormValue);
    };

    const handleWidthValueChange = (data) => (e) => {
        let tempFormValue = formValue;
        let tempListGridData = listGridData;

        tempListGridData.find((item) => item.sort === data.data.sort).width =
            e.value;

        tempFormValue.listColumns.find(
            (item) => item.sort === data.data.sort
        ).width = e.value;

        setFormValue(tempFormValue);
        setListGridData(tempListGridData);

        e.element.classList.remove("bl-mandatory-field");

        checkChanges();
    };

    const handleDeleteRow = (rowData) => {
        const targetId = rowData.data.sort;

        let tempFormValue = formValue;

        let tempListGridData = listGridData.filter((t) => t.sort !== targetId);

        let newSortedList = [];
        tempListGridData.map((item, index) => {
            let tempItem = item;
            tempItem.sort = index + 1;
            tempItem.id = index + 1;
            newSortedList = [...newSortedList, tempItem];
            return newSortedList;
        });

        tempFormValue.listColumns = newSortedList;

        setListGridData(newSortedList);
        setFormValue(tempFormValue);
        filterColumnOptions(tempFormValue.listColumns, richAllColumns);

        checkChanges();
    };

    const handleColumnValueChange = (data) => (e) => {
        let tempFormValue = formValue;
        let tempListGridData = listGridData;

        if (tempFormValue.listColumns.length > 0) {
            tempFormValue.listColumns.find(
                (item) => item.sort === data.data.sort
            ).columnId = e.value.id;

            tempListGridData.find((item) => item.sort === data.data.sort).columnId =
                e.value.id;
        } else {
            tempListGridData[0]["columnId"] = e.value.id;
        }
        // e.element.classList.remove("bl-mandatory-field");

        data.cellElement.children[0].childNodes[0].classList.remove(
            "bl-mandatory-field"
        );
        setFormValue(tempFormValue);
        setListGridData(tempListGridData);
        filterColumnOptions(tempFormValue.listColumns, richAllColumns);
        checkChanges();
    };

    const handleUserGuideChange = useCallback((e) => {
        let tempFormValue = formValue;
        checkChanges();
        tempFormValue['userGuide'] = e.value;
        setFormValue(tempFormValue);
    }, []);

    const columnRender = (data) => {
        if (data.data.sort !== 0) {
            return (
                <>
                    <div className="bl-select-wrapper">
                        <Lookup
                            cancelButtonText="Annuleren"
                            noDataText="Geen resultaten"
                            searchPlaceholder="Zoeken"
                            key={rerenderCOlumns}
                            displayExpr="alias"
                            className={`${richAllColumns.length > 0 &&
                                richAllColumns.find((t) => t.id === data.data.columnId)
                                ? "bl-placeholder-value"
                                : "bl-mandatory-field"
                                }  `}
                            items={columnOptions}
                            placeholder={
                                richAllColumns.find((t) => t.id === data.data.columnId)
                                    ? richAllColumns.find((t) => t.id === data.data.columnId)
                                        .alias
                                    : "Selecteer..."
                            }
                            onValueChanged={handleColumnValueChange(data)}
                        >
                            <DropDownOptions showTitle={true} title="Veld opzoeken" closeOnOutsideClick={true} />
                        </Lookup>
                    </div>
                </>
            );
        } else {
            return null;
        }
    };

    //width dropdown
    const columnWidthRender = (data) => {
        if (data.data.sort !== 0) {
            return (
                <SelectBox
                    searchEnabled
                    dataSource={columnWidths}
                    valueExpr="width"
                    displayExpr="displayWidth"
                    defaultValue={data.data.width}
                    onValueChanged={handleWidthValueChange(data)}
                //   ref={validationRef}
                />
            );
        } else {
            return null;
        }
    };

    //function select box
    const columnFunctionRender = (data) => {
        if (data.data.sort !== 0) {
            return (
                <SelectBox
                    searchEnabled
                    dataSource={columnFunctions}
                    showClearButton={false}
                    valueExpr="function"
                    displayExpr="label"
                    onValueChanged={(e) => {
                        let tempListColumns = formValue.listColumns;
                        let tempForm = formValue;
                        tempListColumns.find((t) => t.sort === data.key.sort)["overviewColumnFunction"] = (e.value === '' ? null : columnFunctions.find((t) => t.function === e.value));
                        tempForm.listColumns = tempListColumns;
                        setFormValue(tempForm);
                        setChangeInForm(true);
                    }}
                    defaultValue={
                        data.data.overviewColumnFunction != null
                            ? data.data.overviewColumnFunction.function
                            : ""
                    }
                />
            );
        } else {
            return null;
        }
    };

    const addNewRow = (e) => {
        let idArray = [];

        listGridData.map((t) => idArray.push(t.sort));

        const newId = idArray.length > 0 ? Math.max(idArray + 1) : 1;

        let tempFormValue = formValue;
        let tempListGridData = listGridData;

        tempListGridData.push({
            id: newId,

            overviewColumnFunction: null,
            // overviewId: listGridData[0].overviewId,
            sort: listGridData.length > 0 ? listGridData.length + 1 : 1,
            columnId: null,
            width: 12,
        });

        // tempFormValue.listColumns.push({
        //   columnId: null,
        //   id: listGridData.length,
        //   overviewColumnFunction: null,
        //   // overviewId: listGridData[0].overviewId,
        //   sort: listGridData.length,
        //   width: 12,
        // });

        tempFormValue.listColumns = tempListGridData;

        setListGridData(tempListGridData);
        setFormValue(tempFormValue);
        dataGridRef.current.instance().refresh();

        checkChanges();
    };

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={addNewRow}
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }

    const columnEditControl = (data) => {
        if (data.data.sort !== 0) {
            return (
                <div className="bl-column-controls">
                    <i
                        className="fas fa-trash bl-column-control-btn bl-column-delete-btn"
                        onClick={() => handleDeleteRow(data)}
                    />
                </div>
            );
        }
    };

    const handleGridReorder = (e) => {
        let tempFormValue = formValue;

        let tempListColumns = listGridData;

        tempListColumns.splice(e.fromIndex, 1);
        tempListColumns.splice(e.toIndex, 0, e.itemData);

        let newSortedList = [];
        tempListColumns.map((item, index) => {
            let tempItem = item;
            tempItem.sort = index + 1;

            newSortedList = [...newSortedList, tempItem];
            return newSortedList;
        });

        setListGridData(newSortedList);

        checkChanges();
        dataGridRef.current.instance().refresh();
    };

    const handleJoinValueChange = (e) => {
        let tempFormValue = formValue;
        tempFormValue.join = e.value;

        setFormValue(tempFormValue);
        checkChanges();
    };

    const handleSaveClick = async (letPass) => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");

        let tempFormValue = formValue;

        if (formValue.listColumns.length > 0) {
            formValue.listColumns.map((item, index) => {
                tempFormValue.listColumns.find(
                    (listItem, itemIndex) => index === itemIndex
                )["id"] = index + 1;
            });

            tempFormValue.listColumns.sort((a, b) => {
                return a.sort - b.sort;
            });
        }

        // tempFormValue.filterOnSignedUserColumnId = null;

        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }
        if (!isCreate) {
            try {
                const res = await msalFetch(msal,
                    `${appSettings.api.endpoint}/api/configurator/dataset/${formValue.tableId}/list/`,
                    {
                        method: "PUT",
                        headers: { "Content-type": "application/json" },
                        body: JSON.stringify(tempFormValue),
                    }
                );
                if (res.ok) {
                    setChangeInForm(false);
                    setIsEditing(false);
                    getListData();
                    toast.success("De gegevens zijn successvol opgeslagen...");
                } else {
                    toast.error("Oeps er is iets mis gegaan...");
                }
            } catch (error) {
                throw error;
            }
        } else {
            try {
                const res = await msalFetch(msal,
                    `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/list/`,
                    {
                        method: "POST",
                        headers: { "Content-type": "application/json" },
                        body: JSON.stringify(tempFormValue),
                    }
                );

                setFormValue(/*{
                    NewButton: true,
                    join: "LEFT JOIN",
                    listColumns: [],
                    preferredDetailViewId: null,
                }*/defaultFormData);
                if (res.ok) {
                    setChangeInForm(false);
                    setIsEditing(false);
                    getListData();
                    toast.success("De gegevens zijn successvol opgeslagen...");
                } else {
                    toast.error("Oeps er is iets mis gegaan...");
                }
            } catch (error) {
                toast.error("Oeps er is iets mis gegaan...");
                throw error;
            }
        }
    };

    const handleExitClick = () => {
        if (changeInForm) {
            setThrowNotification(true);
        } else {
            setIsEditing(false);
            getListData();
        }
    };

    const expandDetailRender = (item) => {
        return <div>{item.name}</div>;
    };

    const handleDiscardAndContinue = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const handleSaveAndContinue = () => {
        handleSaveClick();
    };

    const sortIndexSelectBox = (data) => {
        return (
            <SelectBox
                showClearButton={true}
                items={sortIndices}
                valueExpr="v"
                displayExpr="d"
                defaultValue={data.key.sortIndex}
                disabled={formValue.manualChangeOrderByColumnId != null}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.listColumns.find(item => item.columnId === data.key.columnId)["sortIndex"] = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const sortDirectionSelectBox = (data) => {
        return (
            <SelectBox
                showClearButton={true}
                items={sortDirections}
                valueExpr="v"
                displayExpr="d"
                defaultValue={data.key.sortDirection}
                disabled={formValue.manualChangeOrderByColumnId != null}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.listColumns.find(item => item.columnId === data.key.columnId)["sortDirection"] = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    const groupIndexSelectBox = (data) => {
        return (
            <SelectBox
                showClearButton={true}
                items={groupIndices}
                valueExpr="v"
                displayExpr="d"
                defaultValue={data.key.groupIndex}
                onValueChanged={(e) => {
                    let tempForm = formValue;
                    tempForm.listColumns.find(item => item.columnId === data.key.columnId)["groupIndex"] = e.value;
                    setFormValue(tempForm);
                    checkChanges();
                }}
            />
        );
    };

    return (

        <Popup
            title={`${isCreate ? "Toevoegen lijst" : "Wijzigen lijst"}`}
            className="bl-popup"
            showTitle={true}
            resizeEnabled={true}
            visible={true}
            showCloseButton={true}
            onHiding={(e) => {

                setIsEditing(false);

            }}
            toolbarItems={[
                {
                    toolbar: "top",
                    widget: "dxButton",
                    location: "after",
                    // className: "Button",
                    options:
                    {
                        icon: "fa-solid fa-floppy-disk-circle-xmark",

                        stylingMode: 'text',
                        type: 'default',

                        onClick: (e) => {
                            handleSaveClick()
                        }
                    }
                }
            ]}

        >


            <div className="">

                {/* Loading stage */}
                {!loading && (
                    <div className="">
                        {/* Name field */}
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Naam</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <TextBox
                                        type="input"
                                        defaultValue={isCreate ? "" : formValue.name}
                                        valueChangeEvent="keyup"
                                        className={`${formValue.name ? "" : "bl-mandatory-field"
                                            }`}
                                        onValueChanged={(e) => {
                                            let tempPageData = formValue;
                                            if (e.value.trim() !== "") {
                                                e.element.classList.remove("bl-mandatory-field");
                                            } else {
                                                e.element.classList.add("bl-mandatory-field");
                                            }

                                            tempPageData["name"] = e.value;
                                            setFormValue(tempPageData);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                                <Col lg="3">
                                    <Form.Label>Voorkeurspagina</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <SelectBox
                                        searchEnabled
                                        dataSource={pageLookups}
                                        dataType="string"
                                        defaultValue={
                                            formValue.detailView ? formValue.detailView.name : "..."
                                        }
                                        onValueChanged={handleDetailviewChange}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>


                        {/* // filter on user */}
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Filteren op ingelogde gebruiker</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <SelectBox
                                        searchEnabled
                                        dataSource={userColumns}
                                        valueExpr="id"
                                        displayExpr="alias"
                                        defaultValue={formValue.filterOnSignedUserColumnId}
                                        onValueChanged={(e) => {
                                            let tempFormValue = formValue;
                                            tempFormValue["filterOnSignedUserColumnId"] = e.value;

                                            setFormValue(tempFormValue);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                                <Col lg="3">
                                    <Form.Label>Handmatig sorteren</Form.Label>
                                </Col>

                                <Col lg="3">
                                    <SelectBox
                                        searchEnabled
                                        dataSource={manualChangeOrderByColumns}
                                        valueExpr="id"
                                        displayExpr="alias"
                                        //defaultValue={formValue.manualChangeOrderByColumnId }
                                        value={manualChangeOrderByColumnId}
                                        onValueChanged={(e) => {
                                            let tempFormValue = formValue;
                                            tempFormValue["manualChangeOrderByColumnId"] = e.value;
                                            if (e.value != null) {
                                                tempFormValue["queryOrderBy"] = "";
                                            }
                                            tempFormValue.listColumns.forEach(listColumn => {
                                                listColumn.sortDirection = null;
                                                listColumn.sortIndex = null;
                                            });
                                            setFormValue(tempFormValue);
                                            setManualChangeOrderByColumnId(e.value);
                                            checkChanges();
                                            setAllowSqlSort(e.value == null);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>


                        {/* Expand detailview */}
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Uitklapbare pagina in rij</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <SelectBox
                                        searchEnabled
                                        items={expandDetailviews}
                                        displayExpr="name"
                                        valueExpr="id"
                                        itemRender={expandDetailRender}
                                        defaultValue={
                                            formValue.expandDetailviewId
                                                ? formValue.expandDetailviewId
                                                : null
                                        }
                                        onValueChanged={(e) => {
                                            let tempFormValue = formValue;

                                            tempFormValue["expandDetailviewId"] = e.value;

                                            setFormValue(tempFormValue);
                                            setChangeInForm(true);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* Exporteer naar Excel checkbox */}
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Exporteer naar Excel</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={formValue.allowExportToExcel}
                                        onValueChanged={handleCheckboxValueChange("allowExportToExcel")}
                                    />
                                </Col>
                                <Col lg="3">
                                    <Form.Label>Inline bewerken</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={formValue.inlineEditing}
                                        onValueChanged={handleCheckboxValueChange(
                                            "inlineEditing"
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>



                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Kopiëren</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={formValue.allowCopy}
                                        onValueChanged={handleCheckboxValueChange(
                                            "allowCopy"
                                        )}
                                    />
                                </Col>
                                <Col lg="3">
                                    <Form.Label>Horizontaal scrollen</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={formValue.scrollHorizontal}
                                        onValueChanged={handleCheckboxValueChange(
                                            "scrollHorizontal"
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Automatisch herladen</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <CheckBox
                                        defaultValue={formValue.autoRefresh}
                                        onValueChanged={handleCheckboxValueChange("autoRefresh")}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col lg="12">
                                    <Form.Label>
                                        <h2>Kolommen</h2>
                                    </Form.Label>
                                </Col>
                                <Col lg="12">
                                    {columnLoading ? (
                                        <Loading />
                                    ) : (
                                        <DataGrid
                                            dataSource={listGridData}
                                            ref={dataGridRef}
                                            className="grid-with-overflow"

                                            showColumnLines={false}
                                            rowAlternationEnabled={true}
                                            showRowLines={true}
                                        >
                                            <RowDragging
                                                allowReordering={true}
                                                onReorder={handleGridReorder}
                                                showDragIcons={true}
                                            />
                                            <Column
                                                cellRender={columnRender}
                                                minWidth={250}
                                                caption="Naam"
                                                key={rerenderCOlumns}
                                            />
                                            <Column
                                                cellRender={columnWidthRender}
                                                caption="Breedte"
                                                width={100}
                                            />
                                            <Column
                                                cellRender={columnFunctionRender}
                                                caption="Functie"
                                                allowSorting={false}
                                                alignment="left"
                                                width={170}
                                                showClearButton={false}
                                            />
                                            <Column
                                                caption="Sorteerindex"
                                                allowSorting={false}
                                                alignment="left"
                                                cellRender={sortIndexSelectBox}
                                                width={120}
                                            />
                                            <Column
                                                caption="Sorteerrichting"
                                                allowSorting={false}
                                                alignment="left"
                                                cellRender={sortDirectionSelectBox}
                                                width={170}
                                            />
                                            <Column
                                                caption="Groepindex"
                                                allowSorting={false}
                                                alignment="left"
                                                cellRender={groupIndexSelectBox}
                                                width={120}
                                            />
                                            <Column
                                                width={60}
                                                cellRender={columnEditControl}
                                                headerCellRender={addNewButton}
                                            />
                                        </DataGrid>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>


                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Join</Form.Label>
                                </Col>
                                <Col lg="3">
                                    <RadioGroup
                                        className="bl-radio-btn"
                                        items={joinOptions}
                                        valueExpr="joinValue"
                                        displayExpr="displayValue"
                                        layout="horizontal"
                                        defaultValue={
                                            formValue.join ? formValue.join : "LEFT JOIN"
                                        }
                                        onValueChanged={handleJoinValueChange}
                                    />

                                </Col>
                            </Row>
                        </Form.Group>



                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Voldoet aan</Form.Label>
                                </Col>
                                <Col lg="9">
                                    <TextBox
                                        type="input"
                                        placeholder="(SQL)"
                                        defaultValue={formValue.queryWhere}
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempPageData = formValue;

                                            tempPageData["queryWhere"] = e.value;
                                            setFormValue(tempPageData);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Sorteren op</Form.Label>
                                </Col>
                                <Col lg="9">
                                    <TextBox
                                        type="input"
                                        placeholder="(SQL)"
                                        defaultValue={formValue.queryOrderBy}
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempPageData = formValue;
                                            tempPageData["queryOrderBy"] = e.value;
                                            checkChanges();
                                            setFormValue(tempPageData);
                                        }}
                                        disabled={!allowSqlSort}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        {isDocumentarian && false /*disabled until the popup works*/ && (<Form.Group>
                            <Row>
                                <Col lg="12">
                                    <Form.Label>Gebruikershandleiding</Form.Label>
                                </Col>
                                <Col lg="12">
                                    <HtmlEditor
                                        defaultValue={formValue['userGuide']}
                                        valueType="html"
                                        onValueChanged={handleUserGuideChange}
                                        className="bl-html-editor"
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar multiline={true}>
                                            <Item name="undo" />
                                            <Item name="redo" />
                                            <Item name="separator" />
                                            <Item name="size" acceptedValues={sizeValues} />
                                            <Item name="font" acceptedValues={fontValues} />
                                            <Item name="separator" />
                                            <Item name="bold" />
                                            <Item name="italic" />
                                            <Item name="strike" />
                                            <Item name="underline" />
                                            <Item name="separator" />
                                            <Item name="alignLeft" />
                                            <Item name="alignCenter" />
                                            <Item name="alignRight" />
                                            <Item name="alignJustify" />
                                            <Item name="separator" />
                                            <Item name="orderedList" />
                                            <Item name="bulletList" />
                                            <Item name="separator" />
                                            <Item name="header" acceptedValues={headerValues} />
                                            <Item name="separator" />
                                            <Item name="color" />
                                            <Item name="background" />
                                            <Item name="separator" />
                                            <Item name="link" />
                                            <Item name="image" />
                                            <Item name="separator" />
                                            <Item name="clear" />
                                            <Item name="codeBlock" />
                                            <Item name="blockquote" />
                                            <Item name="separator" />
                                            <Item name="insertTable" />
                                            <Item name="deleteTable" />
                                            <Item name="insertRowAbove" />
                                            <Item name="insertRowBelow" />
                                            <Item name="deleteRow" />
                                            <Item name="insertColumnLeft" />
                                            <Item name="insertColumnRight" />
                                            <Item name="deleteColumn" />
                                        </Toolbar>
                                    </HtmlEditor>
                                </Col>
                            </Row>
                        </Form.Group>)}
                        {/* <CreatorFooter
                            tableId={formValue.tableId}
                            tableItemId={formValue.id}
                        /> */}
                    </div>
                )}
            </div>

            {throwNotification && (
                <UserLeave
                    handleCancel={() => setThrowNotification(false)}
                    handleSaveAndContinue={handleSaveAndContinue}
                    handleDiscardAndContinue={handleDiscardAndContinue}
                />
            )}

        </Popup>
    );
};

export default EditList;
