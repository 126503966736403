
import { PowerReportColumnArea } from "../../enums/powerReport";
import {
    DataGrid,
    Column,
    FilterRow,
    FilterPanel,
    SearchPanel,
    Scrolling,
    LoadPanel,
    ColumnFixing,
    ColumnChooser,
    Position,
    Grouping,
    GroupPanel,
    Summary,
    TotalItem,
    GroupItem,
    HeaderFilter,
    Toolbar,
    Item,
    Paging,
    Pager,
    Sorting,
    StateStoring,
} from "devextreme-react/data-grid";
import { infoText } from '../../global/paging.js';
import ButtonGroup, { Item as ButtonGroupItem } from 'devextreme-react/button-group';
import { useState, useCallback, useRef } from "react";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';

const PowerReportTable = ({
    title,
    dataSource,
    fields,
    // width,
    horizontalScrolling,
    showFieldChooser,
    tableItemId,
}) => {

    const storeStateValue = `stateStorage`;
    const [storeState, setStoreState] = useState(localStorage.getItem(storeStateValue) === 'true');
    const statePrefix = 'stateStorage_';
    const localStorageKey = `${statePrefix}prt_${title}`;

    const exportHandler = useCallback((e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        exportDataGrid({
            component: dataGridRef.current.instance(),
            worksheet: worksheet,
            autoFilterEnabled: true,
            keepColumnWidths: true,
            loadPanel: {
                enabled: true,
                shading: true,
                text: `Excel van ${title} wordt gemaakt...`,
                shadingColor: "#808080"
            }
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${title}.xlsx`);
            });
        });
        e.cancel = true;
    }, [title]);

    const exportPDFHandler = useCallback((e) => {
        const doc = new jsPDF();
        const dataGrid = dataGridRef.current.instance();
        exportDataGridToPdf({
            jsPDFDocument: doc,
            indent: 5,
            component: dataGrid,
            loadPanel: {
                enabled: true,
                shading: true,
                text: `PDF van ${title} wordt gemaakt...`,
                shadingColor: "#808080"
            }
        }).then(() => {
            doc.save(`${title}.pdf`);
        });
        e.cancel = true;
    }, [title]);

    const dataGridRef = useRef();
    const allowedPageSizes = [50, 250, 1000];
    const clearFilterClick = useCallback(() => {
        dataGridRef?.current?.instance().state(null);
    }, []);

    const refreshGrid = useCallback(() => {
        // Pass true to repaint updated data rows; false to repaint all data rows.
        dataGridRef.current.instance().refresh(true);
    }, []);

    const refreshClick = useCallback(() => {
        refreshGrid();
    }, [refreshGrid]);

    const storeStateClick = useCallback(() => {
        const newValue = !storeState;
        const currentInternalState = dataGridRef?.current?.instance().state();
        localStorage.setItem(storeStateValue, newValue);
        if (newValue) {
            localStorage.setItem(localStorageKey, JSON.stringify(currentInternalState));
        } else {
            const itemsToRemove = Object.keys(localStorage).filter(item => item.startsWith(statePrefix));
            for (var c = 0; c < itemsToRemove.length; c++) {
                localStorage.removeItem(itemsToRemove[c]);
            }
            dataGridRef?.current?.instance().state(null);
        }

        setStoreState(newValue);
    }, [storeState, setStoreState, localStorageKey, storeStateValue]);


    const handleCellPrepared = useCallback((e) => {
        if (e.data == null || e.column.detailviewId == null || e.displayValue == null || e.data[e.column.keyPropertyName] == null)
            return;

        const recordId = e.data[e.column.keyPropertyName];
        e.cellElement.innerHTML = `<div className="powerreportlabel"><a href="../detail/${e.column.detailviewId}/${recordId}" target="_blank"> <span class="powerreporticon dx-icon-link" tabindex="0" aria-label="Show filter options for column 'undefined'" aria-haspopup="dialog" role="button"></span></a> ${e.displayValue}</div>`;
    }, []);

    let columnWidth = 0;
    const width = fields.reduce((total, item) => { return total + item.width });
    if (horizontalScrolling) {
        columnWidth = `${(width * 100)}px`;
    }
    else {
        columnWidth = `${(width * 100)}%`;
    };


    return (
        <>

            <DataGrid
                title={title}
                className={""}
                dataSource={dataSource}
                cellHintEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                showRowLines={false}
                showColumnLines={true}
                id="overviewgrid"
                ref={dataGridRef}
                height={"85vh"}
                width={"auto"}
                //focusedRowEnabled={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode={"widget"}

                noDataText="Geen resultaat"
                columnAutoWidth={false}
                wordWrapEnabled={false}
                onCellPrepared={handleCellPrepared}
            >
                <Grouping autoExpandAll={true} contextMenuEnabled allowCollapsing={true} />
                <GroupPanel visible={true} emptyPanelText="Sleep velden hier om te groeperen" />
                <FilterPanel visible={true} />
                {storeState && (
                    <StateStoring
                        enabled={storeState}
                        type="localStorage"
                        storageKey={localStorageKey} />
                )}
                <Sorting mode="multiple" />
                <Paging
                    defaultPageSize={50}
                />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode="full"
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                    infoText={infoText}
                />
                <ColumnChooser enabled={showFieldChooser}>
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                        offset="0 50"
                    />
                </ColumnChooser>
                <HeaderFilter visible={true} search={{ enabled: true }} allowSelectAll={true} />
                <ColumnFixing enabled={true} />
                <LoadPanel
                    enabled={true}
                    showPane={false}
                    text="Laden"
                />
                <SearchPanel
                    visible={true}
                    highlightCaseSensitive={false}
                    placeholder="Zoeken..."
                    width={"150"} />
                <FilterRow visible={true} />
                <Scrolling
                    mode="standard"
                    preloadEnabled="true"
                    showScrollbar="always"
                    columnRenderingMode="virtual"
                />
                <Toolbar>

                    <Item location="before">
                        <h3>{title}</h3>
                    </Item>
                    <Item location="center" name="groupPanel" />
                    <Item location="after" locateInMenu="auto">

                        <ButtonGroup
                            selectionMode="none"
                            focusStateEnabled={false}
                            stylingMode={"outlined"}
                            onItemClick={({ event, itemElement }) => {
                                event.preventDefault();
                            }}
                        >
                            <ButtonGroupItem
                                icon="fas fa-filter"
                                type={storeState ? "success" : "default"}
                                hint={`Filters ${storeState ? "bewaren" : "uit"}`}
                                onClick={storeStateClick}
                            />

                            <ButtonGroupItem
                                icon="fas fa-eraser"

                                type="default"
                                hint="Wis filters"
                                onClick={clearFilterClick}
                            />

                            <ButtonGroupItem
                                icon="fas fa-sync-alt"
                                hint={`Vernieuwen data`}
                                type="default"
                                onClick={refreshClick}
                            />

                            <ButtonGroupItem
                                icon="fas fa-file-excel"
                                type="default"
                                hint="Download excel bestand"
                                onClick={exportHandler}
                            />
                            <ButtonGroupItem
                                icon="fas fa-file-pdf"
                                type="default"
                                hint="Download PDF bestand"
                                onClick={exportPDFHandler}
                            />
                        </ButtonGroup>

                    </Item>
                    <Item name="columnChooserButton" />
                    <Item location="after" name="searchPanel" locateInMenu="auto" />
                </Toolbar>


                {fields.filter(item => item.areaId === PowerReportColumnArea.Column).map(item => {

                    let columnWidth = 0;
                    if (horizontalScrolling
                    ) {
                        columnWidth = `${(item.width * 100)}px`;
                    }
                    else {
                        columnWidth = `${(item.width) * 10}%`;
                    }
                    return (
                        <Column
                            dataField={item.dataField}
                            dataType={item.dataType}
                            format={item.format}
                            allowGrouping={true}
                            allowCollapsing={true}
                            width={columnWidth}

                            allowHeaderFiltering={true}
                            allowSearch={true}
                            detailviewId={item.detailviewId}
                            keyPropertyName={item.keyPropertyName}
                            alignment={item.alignment}
                        />
                    )
                })
                }
                <Summary>
                    {fields.filter(item => item.areaId === PowerReportColumnArea.Column && item.summaryType != null).map(item => {
                        return [
                            <GroupItem
                                column={item.dataField}
                                summaryType={item.summaryType}
                                valueFormat={item.format}
                                alignByColumn
                            />,
                            <TotalItem
                                column={item.dataField}
                                summaryType={item.summaryType}
                                valueFormat={item.format}
                            />
                        ]
                    })}
                </Summary>
            </DataGrid>
        </>)
}

export default PowerReportTable